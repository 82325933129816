import React from "react";

import singleArrowDown from "../../icons/singleArrowDown.svg";

import Styles from "./ComboBox.module.css";

interface IProps {
    state: IStateComboBox
    setState: Function
    defaultIndex: number
    options: Array<IOptionsComboBox>
    onKeyUp?: any
    onChange?: Function
}

export interface IOptionsComboBox {
    name: string
    value: string
}

interface IState {
    optionsClassName: string
    optionsElements: Array<JSX.Element>
}

export interface IStateComboBox {
    index: number
    name: string
    value: string
}

export const InitialStateComboBox = {
    index: 0,
    name: "",
    value: ""
}

class ComboBox extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        let optionsElements = this.props.options.map((value, index) => {
            return React.createElement(
                "a",
                {
                    id: index,
                    onClick: (event) => this.selectElement(event),
                    key: index
                },
                value.name
            )
        });

        this.state = {
            optionsClassName: Styles.options,
            optionsElements: optionsElements
        }

        this.props.setState({
            index: this.props.defaultIndex,
            name: this.props.options[this.props.defaultIndex].name,
            value: this.props.options[this.props.defaultIndex].value
        });

        this.selectElement = this.selectElement.bind(this);
        this.activateElement = this.activateElement.bind(this);
    }

    selectElement(event: any) {
        let inputElement: HTMLInputElement = event.target;
        let options: Array<IOptionsComboBox> = this.props.options

        let index = parseInt(inputElement.id);

        let option = options.at(index);

        if (option !== undefined) {
            this.props.setState({
                index: index,
                value: option.value,
                name: option.name
            });
        }

        if (this.props.onChange) {
            this.props.onChange(options[index].value);
        }
    }

    activateElement() {
        const optionsClassName = this.state.optionsClassName === Styles.optionsShow ? Styles.options : Styles.optionsShow;

        setTimeout(() => {
            this.setState({
                optionsClassName: optionsClassName
            });
        }, 100);
    }

    render() {
        return (
            <div className={Styles.containerCombobox}>
                <label>
                    <input type={"text"} value={this.props.state.name} onFocus={this.activateElement} onBlur={this.activateElement} readOnly />
                    <i onClick={() => this.activateElement}>
                        <img src={singleArrowDown} alt={"singleArrowDown"} />
                    </i>
                </label>
                <div className={this.state.optionsClassName}>
                    {this.state.optionsElements}
                </div>
            </div>
        );
    }
}

export default ComboBox;