interface IResponse {
    status: number
    message: string
}

interface IResponseArrayData {
    status: number
    message: string
    birthday: Array<IResponseBirthdayData_ID>
}

interface IResponseData {
    status: number
    message: string
    birthday: IResponseBirthdayData_ID
}

interface IResponseBirthdayData_ID {
    id: number
    name: string
    date: string
}

export interface IBirthdayData {
    name: string
    date: Date
}

export interface IBirthdayData_ID {
    id: number
    name: string
    date: Date
}

const oneDay = 86400000;

export function calculateAge(day: number, month: number, year: number) {
    let dateToday = new Date().setZero();

    let dateBirthday = new Date(year, (month - 1), day);

    if (dateToday.getTime() < dateBirthday.getTime()) {
        let dayUntilBirth = Math.round((dateBirthday.getTime() - dateToday.getTime()) / oneDay);

        if (dayUntilBirth === 1) {
            return "Geburt in " + dayUntilBirth + " Tag";
        } else {
            return "Geburt in " + dayUntilBirth + " Tagen";
        }
    } else {
        return new Date(dateToday.getTime() - dateBirthday.getTime()).getFullYear() - 1970;
    }
}

export async function createBirthdayData(data: IBirthdayData): Promise<IBirthdayData_ID | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = window.sessionStorage.getItem("token");

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        },
        body: JSON.stringify({
            name: data.name,
            date: data.date.toISODateString()
        }),
        credentials: 'include'
    }

    let response: IResponseData = await fetch(`${API_URL}birthday/create.php`, requestOptions).then(response => response.json());

    if (response.status !== 201) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }

    return {
        id: response.birthday.id,
        name: response.birthday.name,
        date: new Date(response.birthday.date).setZero()
    }
}

export async function getAllBirthdayData() {
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = window.sessionStorage.getItem("token");

    const requestoptions: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        },
        credentials: 'include'
    }

    try {
        let birthdayData: Array<IBirthdayData_ID> = await fetch(`${API_URL}birthday/get.php`, requestoptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return {
                        status: response.status,
                        message: response.statusText,
                        birthday: []
                    }
                }
            })
            .then((response: IResponseArrayData) => {
                if (response.status === 200) {
                    return response.birthday.map((value: IResponseBirthdayData_ID) => {
                        return {
                            id: value.id,
                            name: value.name,
                            date: new Date(value.date).setZero()
                        }
                    });
                } else if (response.status === 204) {
                    return [];
                } else {
                    console.log(`Error: ${response.message}`);
                    return [];
                }
            });

        return birthdayData.sort((a, b) => {
            if (a.date.getTime() > b.date.getTime()) {
                return 1;
            } else {
                return -1;
            }
        });
    } catch (e) {
        return [];
    }
}

export async function getBirthdayData(id?: number) {
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = window.sessionStorage.getItem("token");

    const requestoptions: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        },
        credentials: 'include'
    }

    let birthdayData: IBirthdayData_ID = await fetch(`${API_URL}birthday/get.php?id=${id}`, requestoptions)
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 404) {
                return {
                    status: 404,
                    message: "Not Found",
                    birthday: []
                }
            }
        })
        .then((response: IResponseData) => {
            if (response.status === 200) {
                return {
                    id: response.birthday.id,
                    name: response.birthday.name,
                    date: new Date(response.birthday.date).setZero()
                }
            } else {
                console.log(`Error: ${response.message}`);
                return {
                    id: -1,
                    name: "",
                    date: new Date()
                };
            }
        });

    return birthdayData;
}

export async function updateBirthdayData(data: IBirthdayData_ID): Promise<IBirthdayData_ID | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = window.sessionStorage.getItem("token");

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        },
        body: JSON.stringify({
            id: data.id,
            name: data.name,
            date: data.date.toISODateString()
        }),
        credentials: 'include'
    }

    let response: IResponseData = await fetch(`${API_URL}birthday/update.php?id=${data.id}`, requestOptions).then(response => response.json());

    if (response.status !== 200) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }

    return {
        id: response.birthday.id,
        name: response.birthday.name,
        date: new Date(response.birthday.date).setZero()
    }
}

export async function deleteBirthdayData(id: number) {
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = window.sessionStorage.getItem("token");

    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        },
        body: JSON.stringify({
            id: id
        }),
        credentials: 'include'
    }

    let response: IResponse = await fetch(`${API_URL}birthday/delete.php`, requestOptions).then(response => response.json());

    if (response.status !== 200) {
        console.log(`Error: ${response.message}`);
    }
}