import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import { updateUsername } from "../../utils/userManager";

import Styles from "./ChangeUsername.module.css";
import TextField, { IStateTextField, InitialStateTextField } from "../inputFields/TextField";

interface IState {
    username: IStateTextField
}

interface IProps {
    reload: Function
}

class ChangeUsername extends React.Component<IProps, IState> {
    private popUpRoot!: ReactDOM.Root | undefined;
    private errorBoxRoot!: ReactDOM.Root | undefined;
    private successBoxRoot!: ReactDOM.Root | undefined;

    constructor(props: IProps) {
        super(props);

        this.state = {
            username: InitialStateTextField
        }
    }

    saveUsername = async () => {
        let username = this.state.username.value;

        if (username.length !== 0) {
            let responseData = await updateUsername({
                username: username
            });

            if (responseData !== undefined && typeof responseData !== "string") {
                if (this.errorBoxRoot !== undefined) {
                    this.errorBoxRoot.unmount();
                    this.errorBoxRoot = undefined;
                }

                this.setState({ username: InitialStateTextField });

                this.props.reload(responseData);

                const successBoxContainer = document.getElementById("successBoxUsername");
                if (this.successBoxRoot === undefined) {
                    this.successBoxRoot = createRoot(successBoxContainer!);
                }

                this.successBoxRoot.render(
                    <div id={Styles.successMessageBox}>
                        <p key="successMessage">Erfolgreich geändert!</p>
                    </div>
                );

                await new Promise((resolve) => setTimeout(() => resolve({}), 1500));

                this.successBoxRoot.unmount();
                this.successBoxRoot = undefined;
            } else if (typeof responseData === "string") {
                const errorBoxContainer = document.getElementById("errorBoxUsername");
                if (this.errorBoxRoot === undefined) {
                    this.errorBoxRoot = createRoot(errorBoxContainer!);
                }

                this.errorBoxRoot.render(
                    <div id={Styles.errorMessageBox}>
                        <p key="errorMessage">Der gewählte Benutzername ist schon vergeben!</p>
                    </div>
                );
            } else {
                const popUpContainer = document.getElementById("popUp");
                if (this.popUpRoot === undefined) {
                    this.popUpRoot = createRoot(popUpContainer!);
                }

                this.popUpRoot.render(
                    <div id={Styles.popUpBackground}>
                        <div id={Styles.errorPopUp} className={Styles.popUp}>
                            <h3>Fehler</h3>
                            <hr />
                            <p>Das Speichern der Daten ist fehlgeschlagen!</p>
                            <button onClick={this.close}>Schließen</button>
                        </div>
                    </div>
                );
            }
        }
    }

    close = () => {
        if (this.popUpRoot !== undefined) {
            this.popUpRoot.unmount();
            this.popUpRoot = undefined;
        }
    }

    render() {
        return (
            <div id={Styles.changeUsername}>
                <h4>Benutzernamen ändern</h4>

                <div id="errorBoxUsername" />
                <div>
                    <h6>Neuer Benutzername:</h6>
                    <TextField state={this.state.username} setState={(username: IStateTextField) => this.setState({ username: username })} />

                    <div id="successBoxUsername" />
                    <button onClick={this.saveUsername}>Ändern</button>
                </div>
            </div>
        );
    }
}

export default ChangeUsername;