import React from "react";

import Styles from "./CurrentUserData.module.css";
import { IUserData } from "../../utils/userManager";

interface IProps {
    stateUserData: IUserData
}

class CurrentUserData extends React.Component<IProps> {
    render() {
        return (
            <div id={Styles.currentUserData}>
                <h4>Aktuelle Daten</h4>

                <div>
                    <h6>Dein Benutzername:</h6>
                    <p>{this.props.stateUserData.username}</p>
                </div>

                <div>
                    <h6>Vor- und Nachname:</h6>
                    <p>{this.props.stateUserData.firstName} {this.props.stateUserData.lastName}</p>
                </div>

                <div>
                    <h6>E-Mail Adresse:</h6>
                    <p>{this.props.stateUserData.email}</p>
                </div>
            </div>
        );
    }
}

export default CurrentUserData;