import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import { updateEmail } from "../../utils/userManager";

import Styles from "./ChangeEmail.module.css";
import EmailField, { IStateEmailField, InitialStateEmailField } from "../inputFields/EmailField";

interface IState {
    email: IStateEmailField
}

interface IProps {
    reload: Function
}

class ChangeEmail extends React.Component<IProps, IState> {
    private popUpRoot!: ReactDOM.Root | undefined;
    private errorBoxRoot!: ReactDOM.Root | undefined;
    private successBoxRoot!: ReactDOM.Root | undefined;

    constructor(props: IProps) {
        super(props);

        this.state = {
            email: InitialStateEmailField
        }
    }

    saveEmail = async () => {
        let email = this.state.email.value;

        if (email.length !== 0) {
            if (!this.state.email.required) {
                let responseData = await updateEmail({
                    email: email
                });

                if (responseData !== undefined && typeof responseData !== "string") {
                    if (this.errorBoxRoot !== undefined) {
                        this.errorBoxRoot.unmount();
                        this.errorBoxRoot = undefined;
                    }

                    this.setState({ email: InitialStateEmailField });

                    this.props.reload(responseData);

                    const successBoxContainer = document.getElementById("successBoxEmail");
                    if (this.successBoxRoot === undefined) {
                        this.successBoxRoot = createRoot(successBoxContainer!);
                    }

                    this.successBoxRoot.render(
                        <div id={Styles.successMessageBox}>
                            <p key="successMessage">Erfolgreich geändert!</p>
                        </div>
                    );

                    await new Promise((resolve) => setTimeout(() => resolve({}), 1500));

                    this.successBoxRoot.unmount();
                    this.successBoxRoot = undefined;
                } else if (typeof responseData === "string") {
                    const errorBoxContainer = document.getElementById("errorBoxEmail");
                    if (this.errorBoxRoot === undefined) {
                        this.errorBoxRoot = createRoot(errorBoxContainer!);
                    }

                    this.errorBoxRoot.render(
                        <div id={Styles.errorMessageBox}>
                            <p key="errorMessage">Die E-Mail Adresse ist bereits vergeben!</p>
                        </div>
                    );
                } else {
                    const popUpContainer = document.getElementById("popUp");
                    if (this.popUpRoot === undefined) {
                        this.popUpRoot = createRoot(popUpContainer!);
                    }

                    this.popUpRoot.render(
                        <div id={Styles.popUpBackground}>
                            <div id={Styles.errorPopUp} className={Styles.popUp}>
                                <h3>Fehler</h3>
                                <hr />
                                <p>Das Speichern der Daten ist fehlgeschlagen!</p>
                                <button onClick={this.close}>Schließen</button>
                            </div>
                        </div>
                    );
                }
            } else {
                const errorBoxContainer = document.getElementById("errorBox");
                if (this.errorBoxRoot === undefined) {
                    this.errorBoxRoot = createRoot(errorBoxContainer!);
                }

                this.errorBoxRoot.render(
                    <div id={Styles.errorMessageBox}>
                        <p key="errorMessage">Die Eingabe entspricht nicht einer E-Mail Adresse!</p>
                    </div>
                );
            }
        }
    }

    close = () => {
        if (this.popUpRoot !== undefined) {
            this.popUpRoot.unmount();
            this.popUpRoot = undefined;
        }
    }

    render() {
        return (
            <div id={Styles.changeEmail}>
                <h4>E-Mail Adresse ändern</h4>

                <div id="errorBoxEmail" />
                <div>
                    <h6>Neue E-Mail Adresse</h6>
                    <EmailField state={this.state.email} setState={(email: IStateEmailField) => this.setState({ email: email })} />

                    <div id="successBoxEmail" />
                    <button onClick={this.saveEmail}>Ändern</button>
                </div>
            </div>
        );
    }
}

export default ChangeEmail;