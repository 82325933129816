import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import calendar from "../../icons/calendar.svg";
import info from "../../icons/info.svg";
import cross from "../../icons/cross.svg";
import sortArrowNone from "../../icons/sortArrowNone.svg";
import sortArrowUp from "../../icons/sortArrowUp.svg";
import sortArrowDown from "../../icons/sortArrowDown.svg";

import Add from "./Add";
import Show from "./Show";
import Edit from "./Edit";
import { IBirthdayData_ID, calculateAge, getBirthdayData } from "../../utils/birthdayManager";
import IconButton from "../inputFields/IconButton";

import Styles from "./List.module.css";

import { IBirthdayManager } from "../../pages/BirthdayManager";

let birthdayDataRoot: ReactDOM.Root | undefined;

interface IProps {
    birthdayData: Array<IBirthdayData_ID> | undefined
    reloadBirthdayManager: Function
    stateSelectedID: number | undefined
    setStateSelectedID: Function
}

interface IState {
    list: Array<JSX.Element>
    selectedID: number | undefined
    sort: {
        name: {
            direction: string
            imgSrc: string
        }
        birthdate: {
            direction: string
            imgSrc: string
        }
        age: {
            direction: string
            imgSrc: string
        }
    }
}

class List extends React.Component<IProps, IState> {
    private birthdayManager: IBirthdayManager;

    constructor(props: IProps) {
        super(props);

        if (window.sessionStorage.getItem("birthdayManager") !== null) {
            this.birthdayManager = JSON.parse(window.sessionStorage.getItem("birthdayManager") || "");
        } else {
            this.birthdayManager = {
                view: "list",
                date: {
                    month: new Date().getMonth() + 1,
                    year: new Date().getFullYear()
                }
            }
        }

        this.state = {
            list: [],
            selectedID: undefined,
            sort: {
                name: {
                    direction: "none",
                    imgSrc: sortArrowNone
                },
                birthdate: {
                    direction: "asc",
                    imgSrc: sortArrowUp
                },
                age: {
                    direction: "none",
                    imgSrc: sortArrowNone
                }
            }
        }

        window.sessionStorage.setItem("birthdayManager", JSON.stringify(this.birthdayManager));
    }

    componentDidMount = () => {
        this.loadList();
    }

    componentDidUpdate = (prevProps: any) => {
        if (prevProps.stateSelectedID !== this.props.stateSelectedID) {
            this.loadList();
        }

        if (prevProps.birthdayData !== this.props.birthdayData) {
            this.loadList();
        }
    }

    loadList = () => {
        let birthdayData = this.props.birthdayData;
        let sort = this.state.sort;

        if (birthdayData !== undefined) {
            let list: Array<JSX.Element> = [];

            birthdayData = birthdayData.sort((a, b) => {
                if (sort.name.direction !== "none") {
                    if (sort.name.direction === "asc") {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1;
                        } else if (a.name.toLowerCase() === b.name.toLowerCase()) {
                            if (a.date.getTime() < b.date.getTime()) {
                                return -1;
                            } else if (a.date.getTime() === b.date.getTime()) {
                                return 0;
                            }
                        }
                    } else if (sort.name.direction === "desc") {
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return -1;
                        } else if (a.name.toLowerCase() === b.name.toLowerCase()) {
                            if (a.date.getTime() > b.date.getTime()) {
                                return -1;
                            } else if (a.date.getTime() === b.date.getTime()) {
                                return 0;
                            }
                        }
                    }
                } else if (sort.birthdate.direction !== "none") {
                    let aDate = new Date(new Date().getFullYear(), a.date.getMonth(), a.date.getDate());
                    let bDate = new Date(new Date().getFullYear(), b.date.getMonth(), b.date.getDate());

                    if (sort.birthdate.direction === "asc") {
                        if (aDate.getTime() < bDate.getTime()) {
                            return -1;
                        } else if (aDate.getTime() === bDate.getTime()) {
                            if (a.date.getFullYear() < b.date.getFullYear()) {
                                return -1;
                            } else if (a.date.getFullYear() === b.date.getFullYear()) {
                                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                    return -1;
                                } else if (a.name.toLowerCase() === b.name.toLowerCase()) {
                                    return 0;
                                }
                            }
                        }
                    } else if (sort.birthdate.direction === "desc") {
                        if (aDate.getTime() > bDate.getTime()) {
                            return -1;
                        } else if (aDate.getTime() === bDate.getTime()) {
                            if (a.date.getFullYear() > b.date.getFullYear()) {
                                return -1;
                            } else if (a.date.getFullYear() === b.date.getFullYear()) {
                                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                    return -1;
                                } else if (a.name.toLowerCase() === b.name.toLowerCase()) {
                                    return 0;
                                }
                            }
                        }
                    }
                } else if (sort.age.direction !== "none") {
                    let aDay = a.date.getDate();
                    let aMonth = a.date.getMonth() + 1;
                    let aYear = a.date.getFullYear();

                    let bDay = b.date.getDate();
                    let bMonth = b.date.getMonth() + 1;
                    let bYear = b.date.getFullYear();

                    if (sort.age.direction === "asc") {
                        if (calculateAge(aDay, aMonth, aYear) < calculateAge(bDay, bMonth, bYear)) {
                            return -1;
                        } else if (calculateAge(aDay, aMonth, aYear) === calculateAge(bDay, bMonth, bYear)) {
                            if (a.date.getTime() < b.date.getTime()) {
                                return -1;
                            } else if (a.date.getTime() === b.date.getTime()) {
                                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                    return -1;
                                } else if (a.name.toLowerCase() === b.name.toLowerCase()) {
                                    return 0;
                                }
                            }
                        }
                    } else if (sort.age.direction === "desc") {
                        if (calculateAge(aDay, aMonth, aYear) > calculateAge(bDay, bMonth, bYear)) {
                            return -1;
                        } else if (calculateAge(aDay, aMonth, aYear) === calculateAge(bDay, bMonth, bYear)) {
                            if (a.date.getTime() > b.date.getTime()) {
                                return -1;
                            } else if (a.date.getTime() === b.date.getTime()) {
                                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                    return -1;
                                } else if (a.name.toLowerCase() === b.name.toLowerCase()) {
                                    return 0;
                                }
                            }
                        }
                    }
                }
                return 1;
            });

            let subHeadline: string | undefined;

            birthdayData.forEach((value) => {
                let subHeadlineBefore = subHeadline;

                if (sort.name.direction !== "none") {
                    subHeadline = value.name.charAt(0).toUpperCase();
                } else if (sort.birthdate.direction !== "none") {
                    subHeadline = value.date.monthAsString();
                } else if (sort.age.direction !== "none") {
                    let day = value.date.getDate();
                    let month = value.date.getMonth() + 1;
                    let year = value.date.getFullYear();

                    subHeadline = calculateAge(day, month, year).toString();
                }

                if (subHeadline !== subHeadlineBefore) {
                    list.push(<div className={Styles.subHeadline} key={`SUBHEADLINE${subHeadline}`}>
                        <h5>{subHeadline}</h5>
                    </div>);
                }

                list.push(this.createListElement(value));
            });

            this.setState({ list });
        }
    }

    createListElement = (birthdayData: IBirthdayData_ID) => {
        let day = birthdayData.date.getDate();
        let month = birthdayData.date.getMonth() + 1;
        let year = birthdayData.date.getFullYear();

        if (birthdayData.id === this.props.stateSelectedID) {
            return <div className={`${Styles.listElement} ${Styles.selected}`} key={`BIRTHDAY${birthdayData.id}`}>
                <div>
                    <p className={Styles.name}>{birthdayData.name}</p>
                    <p className={Styles.birthdate}>{birthdayData.date.toDateString()}</p>
                    <p className={Styles.age}>{calculateAge(day, month, year)}</p>
                    <IconButton text="Schließen" imgSrc={cross} imgAlt={"close"} onClick={() => this.birthdayDataUnmount()} />
                </div>
                <hr />
            </div>
        } else {
            return <div className={Styles.listElement} key={`BIRTHDAY${birthdayData.id}`}>
                <div>
                    <p className={Styles.name}>{birthdayData.name}</p>
                    <p className={Styles.birthdate}>{birthdayData.date.toDateString()}</p>
                    <p className={Styles.age}>{calculateAge(day, month, year)}</p>
                    <IconButton text="Info" imgSrc={info} imgAlt={"info"} onClick={() => this.showBirthdayData(birthdayData.id, birthdayData)} />
                </div>
                <hr />
            </div>
        }
    }

    sortName = () => {
        let sort = this.state.sort;

        sort.birthdate.direction = "none";
        sort.age.direction = "none";

        if (this.state.sort.name.direction === "asc") {
            sort.name.direction = "desc";
        } else {
            sort.name.direction = "asc";
        }

        this.setState({ sort });
        this.reloadSortImages();
    }

    sortBirthdate = () => {
        let sort = this.state.sort;

        sort.name.direction = "none";
        sort.age.direction = "none";

        if (this.state.sort.birthdate.direction === "asc") {
            sort.birthdate.direction = "desc";
        } else {
            sort.birthdate.direction = "asc";
        }

        this.setState({ sort });
        this.reloadSortImages();
    }

    sortAge = () => {
        let sort = this.state.sort;

        sort.name.direction = "none";
        sort.birthdate.direction = "none";

        if (this.state.sort.age.direction === "asc") {
            sort.age.direction = "desc";
        } else {
            sort.age.direction = "asc";
        }

        this.setState({ sort });
        this.reloadSortImages();
    }

    reloadSortImages = () => {
        let sort = this.state.sort;

        sort.name.imgSrc = sortArrowNone;
        sort.birthdate.imgSrc = sortArrowNone;
        sort.age.imgSrc = sortArrowNone;

        if (sort.name.direction !== "none") {
            if (sort.name.direction === "asc") {
                sort.name.imgSrc = sortArrowUp;
            } else if (sort.name.direction === "desc") {
                sort.name.imgSrc = sortArrowDown;
            }
        } else if (sort.birthdate.direction !== "none") {
            if (sort.birthdate.direction === "asc") {
                sort.birthdate.imgSrc = sortArrowUp;
            } else if (sort.birthdate.direction === "desc") {
                sort.birthdate.imgSrc = sortArrowDown;
            }
        } else if (sort.age.direction !== "none") {
            if (sort.age.direction === "asc") {
                sort.age.imgSrc = sortArrowUp;
            } else if (sort.age.direction === "desc") {
                sort.age.imgSrc = sortArrowDown;
            }
        }

        this.setState({ sort });
        this.loadList();
    }

    birthdayDataUnmount = () => {
        if (birthdayDataRoot !== undefined) {
            birthdayDataRoot.unmount();
            birthdayDataRoot = undefined;
        }

        this.props.setStateSelectedID(undefined);
    }

    addBirthdayData = () => {
        this.props.setStateSelectedID(undefined);

        if (birthdayDataRoot === undefined) {
            const birthdayDataContainer = document.getElementById("birthdayData");
            birthdayDataRoot = createRoot(birthdayDataContainer!);
        }
        birthdayDataRoot.render(<div id={Styles.birthdayDataBackground}>
            <Add reload={this.props.reloadBirthdayManager} cancel={this.birthdayDataUnmount} show={this.showBirthdayData} />
        </div>);
    }

    showBirthdayData = async (id: number, data?: IBirthdayData_ID) => {
        if (id === -1) {
            this.birthdayDataUnmount();
            this.props.setStateSelectedID(undefined);
            return;
        }
        this.props.setStateSelectedID(id);

        let birthdayData = data;
        if (birthdayData === undefined) {
            birthdayData = await getBirthdayData(id);
        }

        if (birthdayDataRoot === undefined) {
            const birthdayDataContainer = document.getElementById("birthdayData");
            birthdayDataRoot = createRoot(birthdayDataContainer!);
        }
        birthdayDataRoot.render(<div id={Styles.birthdayDataBackground}>
            <Show birthdayData={birthdayData} edit={this.editBirthdayData} close={this.birthdayDataUnmount} />
        </div>);
    }

    editBirthdayData = async (id: number, data?: IBirthdayData_ID) => {
        if (id === -1) {
            this.birthdayDataUnmount();
            this.props.setStateSelectedID(undefined);
            return;
        }
        this.props.setStateSelectedID(id);

        let birthdayData = data;
        if (birthdayData === undefined) {
            birthdayData = await getBirthdayData(id);
        }

        if (birthdayDataRoot === undefined) {
            const birthdayDataContainer = document.getElementById("birthdayData");
            birthdayDataRoot = createRoot(birthdayDataContainer!);
        }
        birthdayDataRoot.render(<div id={Styles.birthdayDataBackground}>
            <Edit reload={this.props.reloadBirthdayManager} birthdayData={birthdayData} show={this.showBirthdayData} />
        </div>);
    }

    switchToCalendar = () => {
        this.birthdayManager.view = "calendar";
        window.sessionStorage.setItem("birthdayManager", JSON.stringify(this.birthdayManager));

        this.props.reloadBirthdayManager();
    }

    render() {
        return (
            <>
                <div id={Styles.headlineNavigation}>
                    <IconButton text={"Kalendar"} imgSrc={calendar} imgAlt={"calendar"} onClick={this.switchToCalendar} />
                </div>

                <div id={Styles.headlineList}>
                    <div id={Styles.headlineName}>
                        <img src={this.state.sort.name.imgSrc} alt={"nameSort"} onClick={this.sortName} />
                        <h4>Name</h4>
                    </div>
                    <div id={Styles.headlineBirthdate}>
                        <img src={this.state.sort.birthdate.imgSrc} alt={"birthdateSort"} onClick={this.sortBirthdate} />
                        <h4>Geburtsdatum</h4>
                    </div>
                    <div id={Styles.headlineAge}>
                        <img src={this.state.sort.age.imgSrc} alt={"ageSort"} onClick={this.sortAge} />
                        <h4>Alter</h4>
                    </div>
                </div>

                <div id={Styles.list}>
                    <div>
                        <hr id={Styles.topBorder} />
                        <div>
                            {this.state.list}
                        </div>
                        <hr id={Styles.bottomBorder} />
                    </div>
                </div>

                <div id={Styles.bottomButton}>
                    <button onClick={this.addBirthdayData}>
                        Hinzufügen
                    </button>
                </div>

                <div id={Styles.birthdayData}>
                    <div id={"birthdayData"} />
                </div>
            </>
        );
    }
}

export default List