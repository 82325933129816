import { useState } from "react";

import { IUserDataAdmin, updateUserData } from "../../utils/userManager";

import Styles from "./Edit.module.css";

import arrowLeft from "../../icons/arrowLeft.svg";
import save from "../../icons/save.svg";
import ComboBox, { IStateComboBox, InitialStateComboBox } from "../inputFields/ComboBox";

interface IProps {
    reloadList: Function
    userData: IUserDataAdmin
    show: Function
}

function Edit(props: IProps) {
    const [stateRight, setStateRight] = useState<IStateComboBox>(InitialStateComboBox);
    const [stateActive, setStateActive] = useState<IStateComboBox>(InitialStateComboBox);

    async function editData() {
        let rightData = stateRight.value === "true";
        let activeData = stateActive.value === "true";

        let userData = {
            id: props.userData.id,
            right: rightData,
            active: activeData
        }

        let updatedUserData = await updateUserData(userData);
        if (updatedUserData !== undefined) {
            props.show(updatedUserData.id, updatedUserData);
            props.reloadList();
        }
    }

    return (
        <div id={Styles.userDataBackground}>
            <div id={Styles.userData}>
                <div id={Styles.navigationIcons}>
                    <img id={Styles.arrowLeftIcon} title={"Abbrechen"} onClick={() => props.show(props.userData.id, props.userData)} src={arrowLeft} alt={"arrowLeft"} />
                    <img id={Styles.saveIcon} title={"Speichen"} onClick={editData} src={save} alt={"save"} />
                </div>
                <hr />
                <div id={Styles.headline}>
                    <h3>{props.userData.username}</h3>
                </div>
                <div id={Styles.data}>
                    <h6>E-Mail Adresse</h6>
                    <p>{props.userData.email}</p>

                    <h6>Vorname</h6>
                    <p>{props.userData.firstName}</p>

                    <h6>Nachname</h6>
                    <p>{props.userData.lastName}</p>

                    <h6>Rechte</h6>
                    <ComboBox state={stateRight} setState={setStateRight} defaultIndex={props.userData.right ? 0 : 1} options={[{ name: "Ja", value: "true" }, { name: "Nein", value: "false" }]} />

                    <h6>Status</h6>
                    <ComboBox state={stateActive} setState={setStateActive} defaultIndex={props.userData.active ? 0 : 1} options={[{ name: "Aktiv", value: "true" }, { name: "Inaktiv", value: "false" }]} />
                </div>
            </div>
        </div>
    );
}

export default Edit;