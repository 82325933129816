export { }

declare global {
    interface Date {
        setZero(): Date
        addDays(days: number): Date
        addMonths(months: number): Date
        isToday(): boolean
        isSameDate(date: Date): boolean
        isSameMonth(date: Date): boolean
        getWeekNumber(): number
        monthAsString(): string
        weekdayAsString(): string
        toISODateString(): string
        toDateString(): string
    }
}

/* eslint-disable no-extend-native */

Date.prototype.setZero = function (): Date {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate());
}

Date.prototype.addDays = function (days: number): Date {
    if (!days) {
        return this
    };

    let newDate = new Date(this.getTime() + (days * 86400000))

    if (newDate.getHours() === 23) {
        return new Date(newDate.getTime() + 3600000);
    } else if (newDate.getHours() === 1) {
        return new Date(newDate.getTime() - 3600000);
    }

    return newDate;
}

Date.prototype.addMonths = function (months: number): Date {
    if (!months) {
        return this
    };

    return new Date(this.getFullYear(), this.getMonth() + months, this.getDate())
}

Date.prototype.isToday = function (): boolean {
    let today = new Date();
    return this.isSameDate(today);
}

Date.prototype.isSameDate = function (date: Date): boolean {
    return date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
}

Date.prototype.isSameMonth = function (date: Date): boolean {
    return date && this.getMonth() === date.getMonth();
}

Date.prototype.getWeekNumber = function (): number {
    let date = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    var weekNumber = Math.ceil((((date.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);

    return weekNumber;
}

Date.prototype.monthAsString = function (): string {
    const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

    let monthName = monthNames.at(this.getMonth());

    if (monthName !== undefined) {
        return monthName;
    } else {
        return "";
    }
}

Date.prototype.weekdayAsString = function (): string {
    const weekdays = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];

    let weekday = weekdays.at(this.getDay());

    if (weekday !== undefined) {
        return weekday;
    } else {
        return "";
    }
}

Date.prototype.toISODateString = function (): string {
    let date = `0${this.getDate()}`.slice(-2);
    let month = `0${this.getMonth()}`.slice(-2);

    return `${this.getFullYear()}-${month}-${date}`;
}

Date.prototype.toDateString = function (): string {
    return `${this.getDate()}.${this.getMonth() + 1}.${this.getFullYear()}`;
}