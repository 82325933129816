import React from "react";

import TextField, { IStateTextField, InitialStateTextField } from "../inputFields/TextField";

import info from "../../icons/info.svg";
import sortArrowNone from "../../icons/sortArrowNone.svg";
import sortArrowUp from "../../icons/sortArrowUp.svg";
import sortArrowDown from "../../icons/sortArrowDown.svg";

import Styles from "./List.module.css";
import { IUserDataAdmin } from "../../utils/userManager";
import IconButton from "../inputFields/IconButton";

interface IProps {
    userData: Array<IUserDataAdmin>
    stateSelectedID: number | undefined
    setStateSelectedID: Function
    addUserData: Function
    show: Function
}

interface IState {
    sort: {
        username: {
            direction: string
            imgSrc: string
        }
        email: {
            direction: string
            imgSrc: string
        }
        firstName: {
            direction: string
            imgSrc: string
        }
        lastName: {
            direction: string
            imgSrc: string
        }
    }
    searchUsername: IStateTextField
    searchEmail: IStateTextField
    searchFirstName: IStateTextField
    searchLastName: IStateTextField
    userManagerList: Array<JSX.Element>
}

class List extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            sort: {
                username: {
                    direction: "asc",
                    imgSrc: sortArrowUp
                },
                email: {
                    direction: "none",
                    imgSrc: sortArrowNone
                },
                firstName: {
                    direction: "none",
                    imgSrc: sortArrowNone
                },
                lastName: {
                    direction: "none",
                    imgSrc: sortArrowNone
                }
            },
            searchUsername: InitialStateTextField,
            searchEmail: InitialStateTextField,
            searchFirstName: InitialStateTextField,
            searchLastName: InitialStateTextField,
            userManagerList: []
        };
    }

    componentDidUpdate = (prevProps: any) => {
        if (prevProps.stateSelectedID !== this.props.stateSelectedID) {
            this.loadList();
        }

        if (prevProps.userData !== this.props.userData) {
            this.loadList();
        }
    }

    loadList = () => {
        let userData = this.props.userData;
        let sort = this.state.sort;

        if (userData !== undefined) {
            let listJSX: Array<JSX.Element> = [];

            userData = userData.sort((a, b) => {
                if (sort.username.direction !== "none") {
                    if (sort.username.direction === "asc") {
                        if (a.username.toLowerCase() < b.username.toLowerCase()) {
                            return -1;
                        } else if (a.username.toLowerCase() === b.username.toLowerCase()) {
                            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                                return -1;
                            } else if (a.firstName.toLowerCase() === b.firstName.toLowerCase()) {
                                if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                                    return -1;
                                } else if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {
                                    if (a.email.toLowerCase() < b.email.toLowerCase()) {
                                        return -1;
                                    } else if (a.email.toLowerCase() === b.email.toLowerCase()) {
                                        return 0;
                                    }
                                }
                            }
                        }
                    } else if (sort.username.direction === "desc") {
                        if (a.username.toLowerCase() > b.username.toLowerCase()) {
                            return -1;
                        } else if (a.username.toLowerCase() === b.username.toLowerCase()) {
                            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                                return -1;
                            } else if (a.firstName.toLowerCase() === b.firstName.toLowerCase()) {
                                if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                                    return -1;
                                } else if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {
                                    if (a.email.toLowerCase() > b.email.toLowerCase()) {
                                        return -1;
                                    } else if (a.email.toLowerCase() === b.email.toLowerCase()) {
                                        return 0;
                                    }
                                }
                            }
                        }
                    }
                } else if (sort.email.direction !== "none") {
                    if (sort.email.direction === "asc") {
                        if (a.email.toLowerCase() < b.email.toLowerCase()) {
                            return -1;
                        } else if (a.email.toLowerCase() === b.email.toLowerCase()) {
                            if (a.username.toLowerCase() < b.username.toLowerCase()) {
                                return -1;
                            } else if (a.username.toLowerCase() === b.username.toLowerCase()) {
                                if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                                    return -1;
                                } else if (a.firstName.toLowerCase() === b.firstName.toLowerCase()) {
                                    if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                                        return -1;
                                    } else if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {
                                        return 0;
                                    }
                                }
                            }
                        }
                    } else if (sort.email.direction === "desc") {
                        if (a.email.toLowerCase() > b.email.toLowerCase()) {
                            return -1;
                        } else if (a.email.toLowerCase() === b.email.toLowerCase()) {
                            if (a.username.toLowerCase() > b.username.toLowerCase()) {
                                return -1;
                            } else if (a.username.toLowerCase() === b.username.toLowerCase()) {
                                if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                                    return -1;
                                } else if (a.firstName.toLowerCase() === b.firstName.toLowerCase()) {
                                    if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                                        return -1;
                                    } else if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {

                                        return 0;
                                    }
                                }
                            }
                        }
                    }
                } else if (sort.firstName.direction !== "none") {
                    if (sort.firstName.direction === "asc") {
                        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                            return -1;
                        } else if (a.firstName.toLowerCase() === b.firstName.toLowerCase()) {
                            if (a.username.toLowerCase() < b.username.toLowerCase()) {
                                return -1;
                            } else if (a.username.toLowerCase() === b.username.toLowerCase()) {
                                if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                                    return -1;
                                } else if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {
                                    if (a.email.toLowerCase() < b.email.toLowerCase()) {
                                        return -1;
                                    } else if (a.email.toLowerCase() === b.email.toLowerCase()) {
                                        return 0;
                                    }
                                }
                            }
                        }
                    } else if (sort.firstName.direction === "desc") {
                        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                            return -1;
                        } else if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {
                            if (a.username.toLowerCase() > b.username.toLowerCase()) {
                                return -1;
                            } else if (a.username.toLowerCase() === b.username.toLowerCase()) {
                                if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                                    return -1;
                                } else if (a.firstName.toLowerCase() === b.firstName.toLowerCase()) {
                                    if (a.email.toLowerCase() > b.email.toLowerCase()) {
                                        return -1;
                                    } else if (a.email.toLowerCase() === b.email.toLowerCase()) {
                                        return 0;
                                    }
                                }
                            }
                        }
                    }
                } else if (sort.lastName.direction !== "none") {
                    if (sort.lastName.direction === "asc") {
                        if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                            return -1;
                        } else if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {
                            if (a.username.toLowerCase() < b.username.toLowerCase()) {
                                return -1;
                            } else if (a.username.toLowerCase() === b.username.toLowerCase()) {
                                if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                                    return -1;
                                } else if (a.firstName.toLowerCase() === b.firstName.toLowerCase()) {
                                    if (a.email.toLowerCase() < b.email.toLowerCase()) {
                                        return -1;
                                    } else if (a.email.toLowerCase() === b.email.toLowerCase()) {
                                        return 0;
                                    }
                                }
                            }
                        }
                    } else if (sort.lastName.direction === "desc") {
                        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                            return -1;
                        } else if (a.lastName.toLowerCase() === b.lastName.toLowerCase()) {
                            if (a.username.toLowerCase() > b.username.toLowerCase()) {
                                return -1;
                            } else if (a.username.toLowerCase() === b.username.toLowerCase()) {
                                if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                                    return -1;
                                } else if (a.firstName.toLowerCase() === b.firstName.toLowerCase()) {
                                    if (a.email.toLowerCase() > b.email.toLowerCase()) {
                                        return -1;
                                    } else if (a.email.toLowerCase() === b.email.toLowerCase()) {
                                        return 0;
                                    }
                                }
                            }
                        }
                    }
                }
                return 1;
            });

            if (userData.length > 0) {
                userData.forEach((value) => {
                    if ((value.username.toLowerCase().includes(this.state.searchUsername.value.toLowerCase()) &&
                        value.email.toLowerCase().includes(this.state.searchEmail.value.toLowerCase()) &&
                        value.firstName.toLowerCase().includes(this.state.searchFirstName.value.toLowerCase()) &&
                        value.lastName.toLowerCase().includes(this.state.searchLastName.value.toLowerCase()))
                        || value.id === this.props.stateSelectedID) {
                        let rightText = value.right ? "Ja" : "Nein";
                        let activeText = value.active ? "Aktiv" : "Inaktiv";

                        if (value.id === this.props.stateSelectedID) {
                            listJSX.push(
                                <div className={`${Styles.listElement} ${Styles.selectedElement}`} key={`USER_DATA_ID${value.id}`} onClick={() => { }}>
                                    <div>
                                        <p className={Styles.username}>{value.username}</p>
                                        <p className={Styles.email}>{value.email}</p>
                                        <p className={Styles.firstName}>{value.firstName}</p>
                                        <p className={Styles.lastName}>{value.lastName}</p>
                                        <p className={Styles.right}>{rightText}</p>
                                        <p className={Styles.status}>{activeText}</p>
                                        <IconButton text={"Details"} imgSrc={info} imgAlt={"details"} onClick={() => this.show(-1)} />
                                    </div>
                                    <hr />
                                </div>
                            );
                        } else {
                            listJSX.push(
                                <div className={Styles.listElement} key={`USER_DATA_ID${value.id}`} onClick={() => { }}>
                                    <div>
                                        <p className={Styles.username}>{value.username}</p>
                                        <p className={Styles.email}>{value.email}</p>
                                        <p className={Styles.firstName}>{value.firstName}</p>
                                        <p className={Styles.lastName}>{value.lastName}</p>
                                        <p className={Styles.right}>{rightText}</p>
                                        <p className={Styles.status}>{activeText}</p>
                                        <IconButton text={"Details"} imgSrc={info} imgAlt={"details"} onClick={() => this.show(value.id)} />
                                    </div>
                                    <hr />
                                </div>
                            );
                        }
                    }
                });
            }

            this.setState({ userManagerList: listJSX });
        }
    }

    sortUsername = () => {
        let sort = this.state.sort;

        sort.email.direction = "none";
        sort.firstName.direction = "none";
        sort.lastName.direction = "none";

        if (this.state.sort.username.direction === "asc") {
            sort.username.direction = "desc";
        } else {
            sort.username.direction = "asc";
        }

        this.setState({ sort });
        this.reloadSortImages();
    }

    sortEmail = () => {
        let sort = this.state.sort;

        sort.username.direction = "none";
        sort.firstName.direction = "none";
        sort.lastName.direction = "none";

        if (this.state.sort.email.direction === "asc") {
            sort.email.direction = "desc";
        } else {
            sort.email.direction = "asc";
        }

        this.setState({ sort });
        this.reloadSortImages();
    }

    sortFirstName = () => {
        let sort = this.state.sort;

        sort.username.direction = "none";
        sort.email.direction = "none";
        sort.lastName.direction = "none";

        if (this.state.sort.firstName.direction === "asc") {
            sort.firstName.direction = "desc";
        } else {
            sort.firstName.direction = "asc";
        }

        this.setState({ sort });
        this.reloadSortImages();
    }

    sortLastName = () => {
        let sort = this.state.sort;

        sort.username.direction = "none";
        sort.email.direction = "none";
        sort.firstName.direction = "none";

        if (this.state.sort.lastName.direction === "asc") {
            sort.lastName.direction = "desc";
        } else {
            sort.lastName.direction = "asc";
        }

        this.setState({ sort });
        this.reloadSortImages();
    }

    reloadSortImages = () => {
        let sort = this.state.sort;

        sort.username.imgSrc = sortArrowNone;
        sort.email.imgSrc = sortArrowNone;
        sort.firstName.imgSrc = sortArrowNone;
        sort.lastName.imgSrc = sortArrowNone;

        if (sort.username.direction !== "none") {
            if (sort.username.direction === "asc") {
                sort.username.imgSrc = sortArrowUp;
            } else if (sort.username.direction === "desc") {
                sort.username.imgSrc = sortArrowDown;
            }
        } else if (sort.email.direction !== "none") {
            if (sort.email.direction === "asc") {
                sort.email.imgSrc = sortArrowUp;
            } else if (sort.email.direction === "desc") {
                sort.email.imgSrc = sortArrowDown;
            }
        } else if (sort.firstName.direction !== "none") {
            if (sort.firstName.direction === "asc") {
                sort.firstName.imgSrc = sortArrowUp;
            } else if (sort.firstName.direction === "desc") {
                sort.firstName.imgSrc = sortArrowDown;
            }
        } else if (sort.lastName.direction !== "none") {
            if (sort.lastName.direction === "asc") {
                sort.lastName.imgSrc = sortArrowUp;
            } else if (sort.lastName.direction === "desc") {
                sort.lastName.imgSrc = sortArrowDown;
            }
        }

        this.setState({ sort });
        this.loadList();
    }

    add = () => {
        this.props.setStateSelectedID(undefined);
        this.props.addUserData();
    }

    show = (id: number) => {
        this.props.setStateSelectedID(id);
        this.props.show(id);
    }

    render = () => {
        return (
            <>
                <div id={Styles.headlineList}>
                    <div className={Styles.username}>
                        <div>
                            <img src={this.state.sort.username.imgSrc} alt={"usernameSort"} onClick={this.sortUsername} />
                            <h4>Benutzername</h4>
                        </div>
                        <div className={Styles.search}>
                            <TextField state={this.state.searchUsername} setState={(stateSearchText: IStateTextField) => this.setState({ searchUsername: stateSearchText })} onKeyUp={() => this.loadList()} />
                        </div>
                    </div>
                    <div  className={Styles.email}>
                        <div>
                            <img src={this.state.sort.email.imgSrc} alt={"emailSort"} onClick={this.sortEmail} />
                            <h4>E-Mail Adresse</h4>
                        </div>
                        <div className={Styles.search}>
                            <TextField state={this.state.searchEmail} setState={(stateSearchText: IStateTextField) => this.setState({ searchEmail: stateSearchText })} onKeyUp={this.loadList} />
                        </div>
                    </div>
                    <div className={Styles.firstName}>
                        <div>
                            <img src={this.state.sort.firstName.imgSrc} alt={"firstNameSort"} onClick={this.sortFirstName} />
                            <h4>Vorname</h4>
                        </div>
                        <div className={Styles.search}>
                            <TextField state={this.state.searchFirstName} setState={(stateSearchText: IStateTextField) => this.setState({ searchFirstName: stateSearchText })} onKeyUp={this.loadList} />
                        </div>
                    </div>
                    <div className={Styles.lastName}>
                        <div>
                            <img src={this.state.sort.lastName.imgSrc} alt={"lastNameSort"} onClick={this.sortLastName} />
                            <h4>Nachname</h4>
                        </div>
                        <div className={Styles.search}>
                            <TextField state={this.state.searchLastName} setState={(stateSearchText: IStateTextField) => this.setState({ searchLastName: stateSearchText })} onKeyUp={this.loadList} />
                        </div>
                    </div>
                    <div className={Styles.right}>
                        <div>
                            <h4>Rechte</h4>
                        </div>
                    </div>
                    <div className={Styles.status}>
                        <div>
                            <h4>Status</h4>
                        </div>
                    </div>
                </div>

                <div id={Styles.list}>
                    <div>
                        <hr id={Styles.topBorder} />
                        <div>
                            {this.state.userManagerList}
                        </div>
                        <hr id={Styles.bottomBorder} />
                    </div>
                </div>

                <div id={Styles.bottomButton}>
                    <button onClick={() => this.props.addUserData()}>
                        Hinzufügen
                    </button>
                </div>
            </>
        );
    }
}

export default List;