import React from "react";

import Styles from "./ToggleButton.module.css";

interface IProps {
    state: IStateToggleButton
    setState: Function
    checked?: boolean
    onChange?: Function
}

export interface IStateToggleButton {
    checked: boolean
}

export const InitialStateToggleButton = {
    checked: false
}

class ToggleButton extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        this.props.setState({
            checked: this.props.checked
        });
    }

    handleChange = (event: any) => {
        let inputElement: HTMLInputElement = event.target;

        this.props.setState({checked: inputElement.checked});

        if(this.props.onChange !== undefined) {
            this.props.onChange(inputElement.checked);
        }
    }

    render() {
        return (
            <label id={Styles.toggleButton}>
                <input type="checkbox" checked={this.props.state.checked} onChange={this.handleChange} />
                <div id={Styles.circle} />
            </label>
        );
    }
}

export default ToggleButton;