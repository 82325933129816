import CryptoJS from "crypto-js";

interface IResponse {
    status: number
    message: string
}

interface IResponseArrayData {
    status: number
    message: string
    password: Array<IPasswordData_ID>
}

interface IResponseData {
    status: number
    message: string
    password: IPasswordData_ID
}

export interface IPasswordData {
    platform: string
    name: string
    email: string
    username: string
    password: string
    notes: string
}

export interface IPasswordData_ID {
    id: number
    platform: string
    name: string
    email: string
    username: string
    password: string
    notes: string
}

export async function createPasswordData(data: IPasswordData) {
    const API_URL = process.env.REACT_APP_API_URL;
    const AES_KEY = process.env.REACT_APP_AES_KEY;
    const PASSWORD_MANAGER_TOKEN = window.sessionStorage.getItem("passwordManagerToken");
    const PASSWORD_MANAGER_KEY = CryptoJS.AES.decrypt(window.sessionStorage.getItem("passwordManagerKey") || "", AES_KEY || "").toString(CryptoJS.enc.Utf8);

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${PASSWORD_MANAGER_TOKEN}`
        },
        body: JSON.stringify({
            platform: CryptoJS.AES.encrypt(data.platform, PASSWORD_MANAGER_KEY).toString(),
            name: CryptoJS.AES.encrypt(data.name, PASSWORD_MANAGER_KEY).toString(),
            email: CryptoJS.AES.encrypt(data.email, PASSWORD_MANAGER_KEY).toString(),
            username: CryptoJS.AES.encrypt(data.username, PASSWORD_MANAGER_KEY).toString(),
            password: CryptoJS.AES.encrypt(data.password, PASSWORD_MANAGER_KEY).toString(),
            notes: CryptoJS.AES.encrypt(data.notes, PASSWORD_MANAGER_KEY).toString()
        }),
        credentials: 'include'
    }

    let response: IResponseData = await fetch(`${API_URL}password/create.php`, requestOptions).then(response => response.json());

    if (response.status !== 201) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }

    return {
        id: response.password.id,
        platform: CryptoJS.AES.decrypt(response.password.platform, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
        name: CryptoJS.AES.decrypt(response.password.name, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
        email: CryptoJS.AES.decrypt(response.password.email, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
        username: CryptoJS.AES.decrypt(response.password.username, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
        password: CryptoJS.AES.decrypt(response.password.password, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
        notes: CryptoJS.AES.decrypt(response.password.notes, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8)
    }
}

export async function getAllPasswordData() {
    const API_URL = process.env.REACT_APP_API_URL;
    const AES_KEY = process.env.REACT_APP_AES_KEY;
    const PASSWORD_MANAGER_TOKEN = window.sessionStorage.getItem("passwordManagerToken");
    const PASSWORD_MANAGER_KEY = CryptoJS.AES.decrypt(window.sessionStorage.getItem("passwordManagerKey") || "", AES_KEY || "").toString(CryptoJS.enc.Utf8);

    const requestoptions: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${PASSWORD_MANAGER_TOKEN}`
        },
        credentials: 'include'
    }

    try {
        let passwordData: Array<IPasswordData_ID> = await fetch(`${API_URL}password/get.php`, requestoptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return {
                        status: response.status,
                        message: response.statusText,
                        password: []
                    }
                }
            })
            .then((response: IResponseArrayData) => {
                if (response.status === 200) {
                    return response.password.map((value: IPasswordData_ID) => {
                        return {
                            id: value.id,
                            platform: CryptoJS.AES.decrypt(value.platform, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
                            name: CryptoJS.AES.decrypt(value.name, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
                            email: CryptoJS.AES.decrypt(value.email, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
                            username: CryptoJS.AES.decrypt(value.username, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
                            password: CryptoJS.AES.decrypt(value.password, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
                            notes: CryptoJS.AES.decrypt(value.notes, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8)
                        }
                    });
                } else if (response.status === 204) {
                    return [];
                } else {
                    console.log(`Error: ${response.message}`);
                    return [];
                }
            });

        return passwordData.sort((a, b) => {
            if (a.platform.toLowerCase() > b.platform.toLowerCase()) {
                return 1;
            } else {
                return -1;
            }
        });
    } catch (e) {
        return [];
    }
}

export async function getPasswordData(id?: number) {
    const API_URL = process.env.REACT_APP_API_URL;
    const AES_KEY = process.env.REACT_APP_AES_KEY;
    const PASSWORD_MANAGER_TOKEN = window.sessionStorage.getItem("passwordManagerToken");
    const PASSWORD_MANAGER_KEY = CryptoJS.AES.decrypt(window.sessionStorage.getItem("passwordManagerKey") || "", AES_KEY || "").toString(CryptoJS.enc.Utf8);

    const requestoptions: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${PASSWORD_MANAGER_TOKEN}`
        },
        credentials: 'include'
    }

    let passwordData: IPasswordData_ID = await fetch(`${API_URL}password/get.php?id=${id}`, requestoptions)
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 404) {
                return {
                    status: 404,
                    message: "Not Found",
                    password: []
                }
            }
        })
        .then((response: IResponseData) => {
            if (response.status === 200) {
                return {
                    id: response.password.id,
                    platform: CryptoJS.AES.decrypt(response.password.platform, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
                    name: CryptoJS.AES.decrypt(response.password.name, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
                    email: CryptoJS.AES.decrypt(response.password.email, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
                    username: CryptoJS.AES.decrypt(response.password.username, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
                    password: CryptoJS.AES.decrypt(response.password.password, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
                    notes: CryptoJS.AES.decrypt(response.password.notes, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8)
                }
            } else {
                console.log(`Error: ${response.message}`);
                return {
                    id: -1,
                    platform: "",
                    name: "",
                    email: "",
                    username: "",
                    password: "",
                    notes: "",
                };
            }
        });

    return passwordData;
}

export async function updatePasswordData(data: IPasswordData_ID) {
    const API_URL = process.env.REACT_APP_API_URL;
    const AES_KEY = process.env.REACT_APP_AES_KEY;
    const PASSWORD_MANAGER_TOKEN = window.sessionStorage.getItem("passwordManagerToken");
    const PASSWORD_MANAGER_KEY = CryptoJS.AES.decrypt(window.sessionStorage.getItem("passwordManagerKey") || "", AES_KEY || "").toString(CryptoJS.enc.Utf8);

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${PASSWORD_MANAGER_TOKEN}`
        },
        body: JSON.stringify({
            id: data.id,
            platform: CryptoJS.AES.encrypt(data.platform, PASSWORD_MANAGER_KEY).toString(),
            name: CryptoJS.AES.encrypt(data.name, PASSWORD_MANAGER_KEY).toString(),
            email: CryptoJS.AES.encrypt(data.email, PASSWORD_MANAGER_KEY).toString(),
            username: CryptoJS.AES.encrypt(data.username, PASSWORD_MANAGER_KEY).toString(),
            password: CryptoJS.AES.encrypt(data.password, PASSWORD_MANAGER_KEY).toString(),
            notes: CryptoJS.AES.encrypt(data.notes, PASSWORD_MANAGER_KEY).toString()
        }),
        credentials: 'include'
    }

    let response: IResponseData = await fetch(`${API_URL}password/update.php?id=${data.id}`, requestOptions).then(response => response.json());

    if (response.status !== 200) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }

    return {
        id: response.password.id,
        platform: CryptoJS.AES.decrypt(response.password.platform, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
        name: CryptoJS.AES.decrypt(response.password.name, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
        email: CryptoJS.AES.decrypt(response.password.email, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
        username: CryptoJS.AES.decrypt(response.password.username, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
        password: CryptoJS.AES.decrypt(response.password.password, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8),
        notes: CryptoJS.AES.decrypt(response.password.notes, PASSWORD_MANAGER_KEY).toString(CryptoJS.enc.Utf8)
    }
}

export async function deletePasswordData(id: number) {
    const API_URL = process.env.REACT_APP_API_URL;
    const PASSWORD_MANAGER_TOKEN = window.sessionStorage.getItem("passwordManagerToken");

    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${PASSWORD_MANAGER_TOKEN}`
        },
        body: JSON.stringify({
            id: id
        }),
        credentials: 'include'
    }

    let response: IResponse = await fetch(`${API_URL}password/delete.php`, requestOptions).then(response => response.json());

    if (response.status !== 200) {
        console.log(`Error: ${response.message}`);
    }
}