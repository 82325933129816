import { useState } from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import HeadlineTextField, { IStateTextField, InitialStateTextField } from "../inputFields/HeadlineTextField";

import { createUserData } from "../../utils/userManager";

import Styles from "./Add.module.css";

import arrowLeft from "../../icons/arrowLeft.svg";
import save from "../../icons/save.svg";
import ComboBox, { IStateComboBox, InitialStateComboBox } from "../inputFields/ComboBox";

interface IProps {
    reloadList: Function
    setStateSelectedID: Function
    cancel: Function
    show: Function
}

function Add(props: IProps) {
    const [stateUsername, setStateUsername] = useState<IStateTextField>(InitialStateTextField);
    const [stateRight, setStateRight] = useState<IStateComboBox>(InitialStateComboBox);
    const [stateActive, setStateActive] = useState<IStateComboBox>(InitialStateComboBox);

    async function addData() {
        if (stateUsername.value.length !== 0) {
            let rightData = stateRight.value === "true";
            let activeData = stateActive.value === "true";

            let userData = {
                username: stateUsername.value,
                right: rightData,
                active: activeData
            }

            let updatedUserData = await createUserData(userData);
            if (typeof updatedUserData === "string") {
                popUpUsernameExist();
            } else if (updatedUserData !== undefined) {
                props.show(updatedUserData.id, updatedUserData);
                props.setStateSelectedID(updatedUserData.id);
                props.reloadList();
            }
        }
    }

    async function popUpUsernameExist() {
        let popUpRoot: ReactDOM.Root | undefined;

        const popUpContainer = document.getElementById("popUp");
        if (popUpRoot === undefined) {
            popUpRoot = createRoot(popUpContainer!);
            popUpRoot.render(
                <div id={Styles.popUpBackground}>
                    <div id={Styles.warningPopUp} className={Styles.popUp}>
                        <h3>Warnung!</h3>
                        <hr />
                        <p>Ein Benutzer mit dem Benutzernamen <span>{stateUsername.value}</span> existiert bereits!</p>
                        <button onClick={close}>Schließen</button>
                    </div>
                </div>
            );
        }

        function close() {
            if (popUpRoot !== undefined) {
                popUpRoot.unmount();
                popUpRoot = undefined;
            }
        }
    }

    return (
        <div id={Styles.userDataBackground}>
            <div id={Styles.userData}>
                <div id={Styles.navigationIcons}>
                    <img id={Styles.arrowLeftIcon} title={"Abbrechen"} onClick={() => props.cancel()} src={arrowLeft} alt={"arrowLeft"} />
                    <h2 id={Styles.headline}><b>Daten</b> hinzufügen</h2>
                    <img id={Styles.saveIcon} title={"Speichen"} onClick={addData} src={save} alt={"save"} />
                </div>
                <hr />
                <div id={Styles.headline}>
                    <HeadlineTextField state={stateUsername} setState={setStateUsername} placeholder={"Beispiel: Max Mustermann"} required={true} tooltipRequired={true} tooltip={"Dies ist ein Pflichtfeld!"} />
                </div>
                <div id={Styles.data}>
                    <h6>Rechte</h6>
                    <ComboBox state={stateRight} setState={setStateRight} defaultIndex={1} options={[{ name: "Ja", value: "true" }, { name: "Nein", value: "false" }]} />

                    <h6>Status</h6>
                    <ComboBox state={stateActive} setState={setStateActive} defaultIndex={0} options={[{ name: "Aktiv", value: "true" }, { name: "Inaktiv", value: "false" }]} />
                </div>
            </div>
        </div>
    );
}

export default Add;