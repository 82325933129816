import { useState } from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import PasswordField, { IStatePasswordField, InitialStatePasswordField } from "../../modules/inputFields/PasswordField";

import Styles from "./SetPasswordManagerKey.module.css";
import { updatePasswordManagerKey } from "../../utils/userManager";

interface IProps {
    reload: Function
}

function SetPasswordManagerKey(props: IProps) {
    let errorBoxRoot: ReactDOM.Root;

    const [statePasswordManagerKey, setStatePasswordManagerKey] = useState<IStatePasswordField>(InitialStatePasswordField);
    const [statePasswordManagerKeyRepeat, setStatePasswordManagerKeyRepeat] = useState<IStatePasswordField>(InitialStatePasswordField);

    async function save() {
        let passwordManagerKey = statePasswordManagerKey.value;
        let passwordManagerKeyRepeat = statePasswordManagerKeyRepeat.value;

        const errorBoxContainer = document.getElementById("errorBox");
        if (errorBoxRoot === undefined) {
            errorBoxRoot = createRoot(errorBoxContainer!);
        }

        if (passwordManagerKey.length !== 0) {
            if (passwordManagerKeyRepeat.length !== 0) {
                if (passwordManagerKey === passwordManagerKeyRepeat) {
                    let data = {
                        old_passwordManagerKey: null,
                        new_passwordManagerKey: passwordManagerKey
                    }

                    let result = await updatePasswordManagerKey(data);

                    if (result !== undefined) {
                        if (result === "success") {
                            props.reload();
                        } else {
                            errorBoxRoot.render(
                                <div id={Styles.errorMessageBox}>
                                    <p key="errorMessage">Der Schlüssel muss sich vom Passwort unterscheiden!</p>
                                </div>
                            );
                        }
                    } else {
                        errorBoxRoot.render(
                            <div id={Styles.errorMessageBox}>
                                <p key="errorMessage">Unbekannter Fehler!</p>
                            </div>
                        );
                    }
                } else {
                    errorBoxRoot.render(
                        <div id={Styles.errorMessageBox}>
                            <p key="errorMessage">Die Schlüssel müssen übereinstimmen!</p>
                        </div>
                    );
                }
            } else {
                errorBoxRoot.render(
                    <div id={Styles.errorMessageBox}>
                        <p key="errorMessage">Der Schlüssel muss ein zweites mal eingegeben werden!</p>
                    </div>
                );
            }
        } else {
            errorBoxRoot.render(
                <div id={Styles.errorMessageBox}>
                    <p key="errorMessage">Es muss ein Schlüssel eingegeben werden!</p>
                </div>
            );
        }
    }

    return (
        <div id={Styles.popUpBackground}>
            <div id={Styles.passwordManagerKeyPopUp} className={Styles.popUp}>
                <h3>Schlüssel (Passwortverwaltung)</h3>
                <hr />
                <p>Es wurde noch kein Schlüssel für die Passwortverwaltung vergeben. <br /> Mit diesem werden später die Passwörter gesichert.</p>
                <h4>WICHTIG!</h4>
                <p id={Styles.important}>Ohne diesen Schlüssel kommst du nicht an deine Passwörter.</p>

                <p id={Styles.info}>Dieser Schlüssel muss sich von deinem normalen Passwort unterscheiden.</p>

                <div id="errorBox" />

                <div>
                    <h6>Schlüssel</h6>
                    <PasswordField state={statePasswordManagerKey} setState={setStatePasswordManagerKey} />

                    <h6>Schlüssel wiederholen</h6>
                    <PasswordField state={statePasswordManagerKeyRepeat} setState={setStatePasswordManagerKeyRepeat} />

                    <button onClick={save}>Speichern</button>
                </div>
            </div>
        </div>
    );
}

export default SetPasswordManagerKey;