interface IResponse {
    status: number
    message: string
}

interface ICreatePasswordResetToken {
    username: string
    email: string
    requestTimestamp: number
}

interface ISetPassword {
    userID: number
    token: string
    username: string
    password: string
}

interface IResponseGetPasswordResetStatus {
    status: number
    message: string
    data: {
        requestTimestamp: string
    }
}

export interface IGetPasswordResetStatus {
    requestTime: Date
}

interface ICreateEmailVerifyToken {
    userID: number
    requestTimestamp: number
}

interface IVerifyEmail {
    userID: number
    token: string
    password: string
}

interface IResponseGetEmailVerifyStatus {
    status: number
    message: string
    data: {
        email: string
        requestTimestamp: string
    }
}

export interface IGetEmailVerifyStatus {
    email: string
    requestTime: Date
}

export async function createPasswordResetToken(data: ICreatePasswordResetToken): Promise<string | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            username: data.username,
            email: data.email,
            requestTimestamp: data.requestTimestamp
        }),
        credentials: 'include'
    }

    let response: IResponse | undefined = await fetch(`${API_URL}user/passwordReset/createToken.php`, requestOptions)
        .then(response => {
            if (response.status === 500) {
                return undefined;
            } else {
                return response.json();
            }
        });

    if (response === undefined) {
        return undefined;
    }

    if (response.status === 200) {
        return "SUCCESS";
    } else if (response.status === 437) {
        return "EMAIL_NO_USERNAME";
    }

    if (response.status === 500) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }
}

export async function getPasswordResetStatus(userID: number, token: string): Promise<IGetPasswordResetStatus | string | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;

    const requestOptions: RequestInit = {
        method: 'GET'
    }

    let response: IResponseGetPasswordResetStatus | undefined = await fetch(`${API_URL}user/passwordReset/getStatus.php?userID=${userID}&token=${token}`, requestOptions)
        .then(response => {
            if (response.status === 500) {
                return undefined;
            } else {
                return response.json();
            }
        });

    if (response === undefined) {
        return undefined;
    }

    if (response.status === 200) {
        return {
            requestTime: new Date(parseInt(response.data.requestTimestamp))
        };
    } else if (response.status === 439) {
        return "USER_NOT_EXIST";
    } else if (response.status === 438) {
        return "USER_ID_NO_TOKEN";
    }

    if (response.status === 500) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }
}

export async function setPassword(data: ISetPassword): Promise<string | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            userID: data.userID,
            token: data.token,
            username: data.username,
            password: data.password
        }),
        credentials: 'include'
    }

    let response: IResponse | undefined = await fetch(`${API_URL}user/passwordReset/setPassword.php`, requestOptions)
        .then(response => {
            if (response.status === 500) {
                return undefined;
            } else {
                return response.json();
            }
        });

    if (response === undefined) {
        return undefined;
    }

    if (response.status === 200) {
        return "SUCCESS";
    } else if (response.status === 435) {
        return "EQUALS_PMK";
    } else if (response.status === 438) {
        return "TOKEN_NOT_EXIST";
    } else if (response.status === 439) {
        return "USER_NOT_EXIST";
    }

    if (response.status === 500) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }
}

export async function createEmailVerifyToken(data: ICreateEmailVerifyToken): Promise<string | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            userID: data.userID,
            requestTimestamp: data.requestTimestamp
        }),
        credentials: 'include'
    }

    let response: IResponse | undefined = await fetch(`${API_URL}user/verifyEmail/createToken.php`, requestOptions)
        .then(response => {
            if (response.status === 500) {
                return undefined;
            } else {
                return response.json();
            }
        });

    if (response === undefined) {
        return undefined;
    }

    if (response.status === 200) {
        return "SUCCESS";
    } else if (response.status === 439) {
        return "USER_NOT_EXIST";
    }

    if (response.status === 500) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }
}

export async function getEmailVerifyStatus(userID: number, token: string): Promise<IGetEmailVerifyStatus | string | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;

    const requestOptions: RequestInit = {
        method: 'GET'
    }

    let response: IResponseGetEmailVerifyStatus | undefined = await fetch(`${API_URL}user/verifyEmail/getStatus.php?userID=${userID}&token=${token}`, requestOptions)
        .then(response => {
            if (response.status === 500) {
                return undefined;
            } else {
                return response.json();
            }
        });

    if (response === undefined) {
        return undefined;
    }

    if (response.status === 200) {
        return {
            email: response.data.email,
            requestTime: new Date(parseInt(response.data.requestTimestamp))
        };
    } else if (response.status === 439) {
        return "USER_NOT_EXIST";
    } else if (response.status === 438) {
        return "USER_ID_NO_TOKEN";
    }

    if (response.status === 500) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }
}

export async function verifyEmail(data: IVerifyEmail): Promise<string | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            userID: data.userID,
            token: data.token,
            password: data.password
        }),
        credentials: 'include'
    }

    let response: IResponse | undefined = await fetch(`${API_URL}user/verifyEmail/verifyEmail.php`, requestOptions)
        .then(response => {
            if (response.status === 500) {
                return undefined;
            } else {
                return response.json();
            }
        });

    if (response === undefined) {
        return undefined;
    }

    if (response.status === 200) {
        return "SUCCESS";
    } else if (response.status === 401) {
        return "UNAUTHORIZED";
    } else if (response.status === 438) {
        return "TOKEN_NOT_EXIST";
    } else if (response.status === 439) {
        return "USER_NOT_EXIST";
    }

    if (response.status === 500) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }
}