import Styles from "./Footer.module.css"

const Footer = () => {
    const date = new Date().getFullYear();

    return (
        <footer>
            <a id={Styles.imprint} href={"/imprint"}>Impressum</a>
            <p id={Styles.copyright}>©FTonne&nbsp;{date}</p>
        </footer>
    )
}

export default Footer