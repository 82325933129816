import Styles from '../css/SignUp.module.css'

import TextField, { IStateTextField, InitialStateTextField } from "../modules/inputFields/TextField"
import PasswordField, { IStatePasswordField, InitialStatePasswordField } from "../modules/inputFields/PasswordField"
import { useEffect, useState } from "react";
import Footer from '../modules/Footer';
import ReactDOM, { createRoot } from 'react-dom/client';
import EmailField, { IStateEmailField, InitialStateEmailField } from '../modules/inputFields/EmailField';

interface IResponse {
    status: number
    message: string
}

function SignUp() {
    const API_URL = process.env.REACT_APP_API_URL;

    const [stateFirstName, setStateFirstName] = useState<IStateTextField>(InitialStateTextField);
    const [stateLastName, setStateLastName] = useState<IStateTextField>(InitialStateTextField);
    const [stateUsername, setStateUsername] = useState<IStateTextField>(InitialStateTextField);
    const [stateEmail, setStateEmail] = useState<IStateEmailField>(InitialStateEmailField);
    const [statePassword, setStatePassword] = useState<IStatePasswordField>(InitialStatePasswordField);
    const [statePasswordRepeat, setStatePasswordRepeat] = useState<IStatePasswordField>(InitialStatePasswordField);

    let errorBoxRoot: ReactDOM.Root;

    useEffect(() => {
        document.title = "Registrierung";
    });

    function onKeyUp(event: any) {
        if (event.keyCode === 13) {
            event.preventDefault();
            signUp();
        }
    }

    async function signUp() {
        let firstName = stateFirstName.value;
        let lastName = stateLastName.value;
        let username = stateUsername.value;
        let email = stateEmail.value;
        let password = statePassword.value;
        let passwordRepeat = statePasswordRepeat.value;

        const errorBoxContainer = document.getElementById("errorBox");
        if (errorBoxRoot === undefined) {
            errorBoxRoot = createRoot(errorBoxContainer!);
        }

        if (firstName.length !== 0 && lastName.length !== 0 && username.length !== 0 && email.length !== 0 && password.length !== 0 && passwordRepeat.length !== 0) {
            if (password === passwordRepeat) {
                const tokenRequestOptions: RequestInit = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        firstName: firstName,
                        lastName: lastName,
                        username: username,
                        email: email,
                        password: password
                    }),
                    credentials: 'include'
                }

                let tokenResponse: IResponse = await fetch(`${API_URL}user/create.php`, tokenRequestOptions).then(response => response.json());

                if (tokenResponse.status === 200) {
                    document.location.href = "/signIn";
                } else if (tokenResponse.status === 433) {
                    errorBoxRoot.render(
                        <div id={Styles.errorMessageBox}>
                            <p key="errorMessage">Der Benutzername ist nicht eingetragen!</p>
                        </div>
                    );
                } else if (tokenResponse.status === 434) {
                    errorBoxRoot.render(
                        <div id={Styles.errorMessageBox}>
                            <p key="errorMessage">Dieser Benutzername ist bereits registriert!</p>
                        </div>
                    );
                } else {
                    errorBoxRoot.render(
                        <div id={Styles.errorMessageBox}>
                            <p key="errorMessage">Unbekannter Fehler!</p>
                        </div>
                    );
                }
            } else {
                errorBoxRoot.render(
                    <div id={Styles.errorMessageBox}>
                        <p key="errorMessage">Die Passwörter müssen übereinstimmen!</p>
                    </div>
                );
            }
        } else {
            errorBoxRoot.render(
                <div id={Styles.errorMessageBox}>
                    <p key="errorMessage">Es müssen alle Felder ausgefüllt sein!</p>
                </div>
            );
        }
    }

    return (
        <>
            <div id={Styles.signUp}>
                <div id={Styles.title}>
                    <h1><b>Web</b>Database</h1>
                </div>
                <div id={Styles.input}>
                    <div id="errorBox" />
                    <h6>Vorname</h6>
                    <TextField onKeyUp={onKeyUp} required={true} state={stateFirstName} setState={setStateFirstName} tooltipRequired={false} />

                    <h6>Nachname</h6>
                    <TextField onKeyUp={onKeyUp} required={true} state={stateLastName} setState={setStateLastName} tooltipRequired={false} />

                    <h6>Benutzername</h6>
                    <TextField onKeyUp={onKeyUp} required={true} state={stateUsername} setState={setStateUsername} tooltipRequired={false} />

                    <h6>E-Mail Adresse</h6>
                    <EmailField onKeyUp={onKeyUp} required={true} state={stateEmail} setState={setStateEmail} tooltipRequired={false} />

                    <h6>Passwort</h6>
                    <PasswordField onKeyUp={onKeyUp} state={statePassword} setState={setStatePassword} />

                    <h6>Passwort wiederholen</h6>
                    <PasswordField onKeyUp={onKeyUp} state={statePasswordRepeat} setState={setStatePasswordRepeat} />

                    <button onClick={signUp}>Registrieren</button>
                    <p>Hast du bereits einen Account? <a href={"/signIn"}>Anmelden</a></p>
                </div>
            </div>
            <div id={"popUp"} />
            <Footer />
        </>
    );
}

export default SignUp;