import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import Styles from '../css/EmailVerify.module.css';
import FontStyles from '../css/Font.module.css';

import PasswordField, { IStatePasswordField, InitialStatePasswordField } from "../modules/inputFields/PasswordField"
import Footer from '../modules/Footer';
import { getEmailVerifyStatus, verifyEmail } from "../utils/token";

interface IState {
    email: string
    password: IStatePasswordField
    button: JSX.Element
}

class EmailVerify extends React.Component<{}, IState> {
    private errorBoxRoot!: ReactDOM.Root | undefined;
    private successBoxRoot!: ReactDOM.Root | undefined;

    private userID!: number;
    private token!: string;

    constructor(props: {}) {
        super(props);

        this.state = {
            email: "",
            password: InitialStatePasswordField,
            button: <button onClick={this.verifyEmail}>E-Mail Adresse verifizieren</button>
        }

        document.title = "E-Mail Adresse verifizieren";

        this.userID = parseInt(window.location.search.split("&")[0].split("=")[1]);
        this.token = window.location.search.split("&")[1].split("=")[1];
    }

    componentDidMount = async () => {
        let responseEmailVerifyStatus = await getEmailVerifyStatus(this.userID, this.token);

        const errorBoxContainer = document.getElementById("errorBox");
        if (this.errorBoxRoot === undefined) {
            this.errorBoxRoot = createRoot(errorBoxContainer!);
        }

        if (responseEmailVerifyStatus !== undefined) {
            if (typeof responseEmailVerifyStatus === "string") {
                this.errorBoxRoot.render(
                    <div id={Styles.errorMessageBox}>
                        <p key="errorMessage">Der genutzte Link ist ungültig. Forder bitte einen neuen an.</p>
                    </div>
                );

                this.setState({ button: <button onClick={this.toSignIn}>Zur Anmeldung</button> });
            } else if (typeof responseEmailVerifyStatus === "object") {
                if (responseEmailVerifyStatus.requestTime.addDays(1).getTime() < new Date().getTime()) {
                    this.errorBoxRoot.render(
                        <div id={Styles.errorMessageBox}>
                            <p key="errorMessage">Der genutzte Link ist ungültig. Forder bitte einen neuen an.</p>
                        </div>
                    );

                    this.setState({ button: <button onClick={this.toSignIn}>Zur Anmeldung</button> });
                } else {
                    this.setState({ email: responseEmailVerifyStatus.email })
                }
            }
        } else {
            if (this.errorBoxRoot !== undefined) {
                this.errorBoxRoot.unmount();
            }

            this.errorBoxRoot.render(
                <div id={Styles.errorMessageBox}>
                    <p key="errorMessage">Der genutzte Link ist ungültig. Forder bitte einen neuen an.</p>
                </div>
            );

            this.setState({ button: <button onClick={this.toSignIn}>Zur Anmeldung</button> });
        }
    }

    onKeyUp = (event: any) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.verifyEmail();
        }
    }

    verifyEmail = async () => {
        let password = this.state.password.value;

        const errorBoxContainer = document.getElementById("errorBox");
        const successBoxContainer = document.getElementById("successBox");

        if (this.errorBoxRoot !== undefined) {
            this.errorBoxRoot.unmount();
            this.errorBoxRoot = undefined;
        }

        if (this.successBoxRoot !== undefined) {
            this.successBoxRoot.unmount();
            this.successBoxRoot = undefined;
        }

        if (password.length !== 0) {
            let setNewPasswordResponse = await verifyEmail({
                userID: this.userID,
                token: this.token,
                password: password
            });

            if (setNewPasswordResponse !== undefined) {
                if (setNewPasswordResponse === "SUCCESS") {
                    this.successBoxRoot = createRoot(successBoxContainer!);

                    this.successBoxRoot.render(
                        <div id={Styles.successMessageBox}>
                            <p key="successMessage">Die E-Mail Adresse wurde erfolgreich verifiziert.</p>
                        </div>
                    );

                    this.setState({ button: <button onClick={this.toSignIn}>Zur Anmeldung</button> });
                } else if (setNewPasswordResponse === "UNAUTHORIZED") {
                    this.errorBoxRoot = createRoot(errorBoxContainer!);

                    this.errorBoxRoot.render(
                        <div id={Styles.errorMessageBox}>
                            <p key="errorMessage">Passwort falsch!</p>
                        </div>
                    );

                    this.setState({ button: <button onClick={this.toSignIn}>Zur Anmeldung</button> });
                } else if (setNewPasswordResponse === "TOKEN_NOT_EXIST") {
                    this.errorBoxRoot = createRoot(errorBoxContainer!);

                    this.errorBoxRoot.render(
                        <div id={Styles.errorMessageBox}>
                            <p key="errorMessage">Der genutzte Link ist ungültig. Forder bitte einen neuen an.</p>
                        </div>
                    );

                    this.setState({ button: <button onClick={this.toSignIn}>Zur Anmeldung</button> });
                } else if (setNewPasswordResponse === "USER_NOT_EXIST") {
                    this.errorBoxRoot = createRoot(errorBoxContainer!);

                    this.errorBoxRoot.render(
                        <div id={Styles.errorMessageBox}>
                            <p key="errorMessage">Der genutzte Link gehört zu keinem Nutzer.</p>
                        </div>
                    );
                }
            } else {
                this.errorBoxRoot = createRoot(errorBoxContainer!);

                this.errorBoxRoot.render(
                    <div id={Styles.errorMessageBox}>
                        <p key="errorMessage">Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.</p>
                    </div>
                );

                this.setState({ button: <button onClick={this.toSignIn}>Zur Anmeldung</button> });
            }
        } else {
            this.errorBoxRoot = createRoot(errorBoxContainer!);

            this.errorBoxRoot.render(
                <div id={Styles.errorMessageBox}>
                    <p key="errorMessage">Es muss ein Passwort eingegeben werden!</p>
                </div>
            );
        }
    }

    toSignIn = () => {
        window.location.href = "/signIn";
    }

    render() {
        return (
            <>
                <div id={Styles.emailVerify}>
                    <div id={Styles.title}>
                        <h1><b>Web</b>Database</h1>
                        <h3 className={FontStyles.whiteColor}>E-Mail Adresse verifizieren</h3>
                    </div>
                    <div id={Styles.input}>
                        <div id="successBox" />
                        <div id="errorBox" />

                        <h6>E-Mail</h6>
                        <p>{this.state.email}</p>

                        <h6>Passwort</h6>
                        <PasswordField onKeyUp={this.onKeyUp} state={this.state.password} setState={(value: IStatePasswordField) => this.setState({ password: value })} />

                        {this.state.button}
                    </div>
                </div>
                <div id={"popUp"} />
                <Footer />
            </>
        );
    }
}

export default EmailVerify;