import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import { IUserData, updateAutoLogOut } from "../../utils/userManager";
import ToggleButton, { IStateToggleButton, InitialStateToggleButton } from "../inputFields/ToggleButton";

import Styles from "./QuickSettings.module.css";

interface IState {
    autoLogOut: IStateToggleButton
}

interface IProps {
    stateUserData: IUserData
    reload: Function
}

class QuickSettings extends React.Component<IProps, IState> {
    private popUpRoot!: ReactDOM.Root | undefined

    constructor(props: IProps) {
        super(props);

        this.state = {
            autoLogOut: InitialStateToggleButton
        }
    }

    handleAutoLogOutChange = async (checked: boolean) => {
        let responseData = await updateAutoLogOut({ autoLogOut: checked });

        if (responseData !== undefined) {
            this.props.reload(responseData);
        } else {
            const popUpContainer = document.getElementById("popUp");
            if (this.popUpRoot === undefined) {
                this.popUpRoot = createRoot(popUpContainer!);
            }
            this.popUpRoot.render(
                <div id={Styles.popUpBackground}>
                    <div id={Styles.errorPopUp} className={Styles.popUp}>
                        <h3>Fehler</h3>
                        <hr />
                        <p>Das Speichern der Daten ist fehlgeschlagen!</p>
                        <button onClick={this.close}>Schließen</button>
                    </div>
                </div>
            );
        }
    }

    close = () => {
        if (this.popUpRoot !== undefined) {
            this.popUpRoot.unmount();
            this.popUpRoot = undefined;
        }
    }

    render() {
        return (
            <div id={Styles.quickSettings}>
                <h4>Schnell Einstellungen</h4>

                <div>
                    <h6>Automatisches Abmelden:</h6>
                    <ToggleButton state={this.state.autoLogOut} setState={(autoLogOut: IStateToggleButton) => this.setState({ autoLogOut: autoLogOut })} checked={this.props.stateUserData.autoLogOut} onChange={this.handleAutoLogOutChange} />
                </div>
            </div>
        );
    }
}

export default QuickSettings;