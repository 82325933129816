import { IBirthdayData_ID, calculateAge } from "../../utils/birthdayManager";

import Styles from "./Show.module.css";

import arrowLeft from "../../icons/arrowLeft.svg";
import pen from "../../icons/pen.svg";

interface IProps {
    birthdayData: IBirthdayData_ID
    edit: (id: number, data?: IBirthdayData_ID) => Promise<void>
    close: Function
}

function Show(props: IProps) {
    function getAge() {
        let day = props.birthdayData.date.getDate();
        let month = props.birthdayData.date.getMonth() + 1;
        let year = props.birthdayData.date.getFullYear();

        return calculateAge(day, month, year);
    }

    return (
        <div id={Styles.birthdayData}>
            <div id={Styles.navigationIcons}>
                <img id={Styles.arrowLeftIcon} title={"Zurück"} onClick={() => props.close()} src={arrowLeft} alt={"arrowLeft"} />
                <img id={Styles.editIcon} title={"Bearbeiten"} onClick={() => props.edit(props.birthdayData.id, props.birthdayData)} src={pen} alt={"edit"} />
            </div>
            <hr />
            <div id={Styles.headline}>
                <h3>{props.birthdayData.name}</h3>
            </div>
            <div id={Styles.data}>
                <h6>Geburtsdatum</h6>
                <div id={Styles.birthdayDate}>
                    <p id={Styles.day}>{props.birthdayData.date.getDate()}</p>
                    <p className={Styles.dateDot}>.</p>
                    <p id={Styles.month}>{props.birthdayData.date.getMonth() + 1}</p>
                    <p className={Styles.dateDot}>.</p>
                    <p id={Styles.year}>{props.birthdayData.date.getFullYear()}</p>
                </div>

                <h6>Alter</h6>
                <p>{getAge()}</p>
            </div>
        </div>
    );
}

export default Show;