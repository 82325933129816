import React from "react";

class SignOut extends React.Component {
    constructor(props: {}) {
        super(props);
        document.title = "Abmelden...";

        window.sessionStorage.clear();
        document.location.href = "/signIn";
    }

    render() {
        return (
            <div>
                <p>Du wirst abgemeldet...</p>
            </div>
        );
    }
}

export default SignOut;