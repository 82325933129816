import { IPasswordData_ID } from "../../utils/passwordManager";

import Styles from "./Show.module.css";

import arrowLeft from "../../icons/arrowLeft.svg";
import pen from "../../icons/pen.svg";
import copy from "../../icons/copy.svg";
import { RefObject, createRef } from "react";

interface IProps {
    passwordData: IPasswordData_ID
    setStateSelectedID: Function
    edit: (id: number, data?: IPasswordData_ID) => Promise<void>
    close: Function
}

function Show(props: IProps) {
    const passwordCopiedRef: RefObject<HTMLDivElement> = createRef();

    function copyPassword() {
        navigator.clipboard.writeText(props.passwordData.password);

        passwordCopiedRef.current?.classList.add(Styles.show);
        setTimeout(() => passwordCopiedRef.current?.classList.remove(Styles.show), 1000);
    }

    function close() {
        props.setStateSelectedID(undefined);
        props.close();
    }

    return (
        <div id={Styles.passwordDataBackground}>
            <div id={Styles.passwordData}>
                <div id={Styles.navigationIcons}>
                    <img id={Styles.arrowLeftIcon} title={"Zurück"} onClick={close} src={arrowLeft} alt={"arrowLeft"} />
                    <img id={Styles.penIcon} title={"Bearbeiten"} onClick={() => props.edit(props.passwordData.id, props.passwordData)} src={pen} alt={"pen"} />
                </div>
                <hr />
                <div id={Styles.headline}>
                    <h3>{props.passwordData.platform}</h3>
                </div>
                <div id={Styles.data}>
                    <h6>Name</h6>
                    <p>{props.passwordData.name}</p>

                    <h6>E-Mail</h6>
                    <p>{props.passwordData.email}</p>

                    <h6>Benutzername</h6>
                    <p>{props.passwordData.username}</p>

                    <h6>Passwort</h6>
                    <div id={Styles.password}>
                        <p>{props.passwordData.password}</p>
                        <img id={Styles.copyIcon} title={"Kopieren"} onClick={copyPassword} src={copy} alt={"copy"} />
                        <div id={Styles.copied} ref={passwordCopiedRef}><p>Kopiert!</p></div>
                    </div>

                    <h6>Notizen</h6>
                    <p id={Styles.notes}>
                        {props.passwordData.notes.split("\n").map((lineValue) => { return <>{lineValue}<br /></>; })}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Show;