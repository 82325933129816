import Styles from '../css/SignIn.module.css'

import TextField, { IStateTextField, InitialStateTextField } from "../modules/inputFields/TextField"
import PasswordField, { IStatePasswordField, InitialStatePasswordField } from "../modules/inputFields/PasswordField"
import { useEffect, useState } from "react";
import Footer from '../modules/Footer';
import ReactDOM, { createRoot } from 'react-dom/client';
import PasswordReset from '../modules/signIn/PasswordReset';
import { getUser } from '../utils/userManager';

interface TokenAPIResponse {
    status: number
    message: string
    token: string
}

function SignIn() {
    const API_URL = process.env.REACT_APP_API_URL;

    const [stateUsername, setStateUsername] = useState<IStateTextField>(InitialStateTextField);
    const [statePassword, setStatePassword] = useState<IStatePasswordField>(InitialStatePasswordField);

    let popUpRoot: undefined | ReactDOM.Root;
    let errorBoxRoot: ReactDOM.Root;

    useEffect(() => {
        document.title = "Anmeldung";
    });

    function onKeyUp(event: any) {
        if (event.keyCode === 13) {
            event.preventDefault();
            signIn();
        }
    }

    async function signIn() {
        let username = stateUsername.value;
        let password = statePassword.value;

        const errorBoxContainer = document.getElementById("errorBox");
        if (errorBoxRoot === undefined) {
            errorBoxRoot = createRoot(errorBoxContainer!);
        }

        if (username.length !== 0) {
            if (password.length !== 0) {
                const tokenRequestOptions: RequestInit = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        username: username,
                        password: password
                    }),
                    credentials: 'include'
                }

                let tokenResponse: TokenAPIResponse = await fetch(`${API_URL}user/getToken.php`, tokenRequestOptions).then(response => response.json());

                if (tokenResponse.status === 200) {
                    window.sessionStorage.setItem("token", tokenResponse.token);

                    let userData = await getUser();

                    if (userData !== undefined) {
                        if (userData.active) {
                            window.sessionStorage.setItem("userData", JSON.stringify(userData));
                            window.sessionStorage.setItem("temp", JSON.stringify({ "hasPMK": userData.isPasswordManagerKey }));

                            document.location.href = "/home";
                        }
                    } else {
                        errorBoxRoot.render(
                            <div id={Styles.errorMessageBox}>
                                <p key="errorMessage">Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.</p>
                            </div>
                        );
                    }

                } else {
                    errorBoxRoot.render(
                        <div id={Styles.errorMessageBox}>
                            <p key="errorMessage">Benutzername/Passwort falsch!</p>
                            <button key="errorResetPassword" onClick={() => passwordReset()}>Passwort zurücksetzen?</button>
                        </div>
                    );
                }
            } else {
                errorBoxRoot.render(
                    <div id={Styles.errorMessageBox}>
                        <p key="errorMessage">Es muss ein Passwort eingegeben werden!</p>
                    </div>
                );
            }
        } else {
            errorBoxRoot.render(
                <div id={Styles.errorMessageBox}>
                    <p key="errorMessage">Es muss ein Benutzername eingegeben werden!</p>
                </div>
            );
        }
    }

    function passwordReset() {
        const popUpContainer = document.getElementById("popUp");
        if (popUpRoot === undefined) {
            popUpRoot = createRoot(popUpContainer!);
        }

        popUpRoot.render(
            <div id={Styles.popUpBackground}>
                <div className={Styles.popUp}>
                    <PasswordReset close={() => closePasswordReset()} />
                </div>
            </div>
        );
    }

    function closePasswordReset() {
        if (popUpRoot !== undefined) {
            popUpRoot.unmount();
        }
        popUpRoot = undefined;
    }

    return (
        <>
            <div id={Styles.signIn}>
                <div id={Styles.title}>
                    <h1><b>Web</b>Database</h1>
                </div>
                <div id={Styles.input}>
                    <div id="errorBox" />
                    <h6>Benutzername</h6>
                    <TextField onKeyUp={onKeyUp} required={true} state={stateUsername} setState={setStateUsername} tooltipRequired={false} />

                    <h6>Passwort</h6>
                    <PasswordField onKeyUp={onKeyUp} state={statePassword} setState={setStatePassword} />

                    <button onClick={signIn}>Anmelden</button>
                    <p>Du hast keinen Account? <a href={"/signUp"}>Registrieren</a></p>
                </div>
            </div>
            <div id={"popUp"} />
            <Footer />
        </>
    );
}

export default SignIn;
