import React from "react";

import Styles from "./SearchField.module.css";
import magnifier from "../../icons/magnifier.svg";

interface IProps {
    state: IStateSearchField
    setState: Function
    value?: string
    onKeyUp?: any
}

export interface IStateSearchField {
    value: string
}

export const InitialStateSearchField = {
    value: ""
}

class SearchField extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        this.props.setState({
            value: this.props.value || ""
        });

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        this.props.setState({ value: event.target.value });
    }

    render() {
        return (
            <label id={Styles.inputLabel}>
                <input onKeyUp={this.props.onKeyUp} autoComplete={"off"} type={"text"} placeholder={"Suche"} onChange={this.handleChange} value={this.props.state.value} />
                <i id={Styles.icon}>
                    <img src={magnifier} alt={"magnifier"} />
                </i>
            </label>
        );
    }
}

export default SearchField;