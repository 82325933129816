import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import { updatePasswordManagerKey } from "../../utils/userManager";

import Styles from "./ChangePasswordManagerKey.module.css";
import PasswordField, { IStatePasswordField, InitialStatePasswordField } from "../inputFields/PasswordField";

interface IState {
    oldPasswordManagerKey: IStatePasswordField
    newPasswordManagerKey: IStatePasswordField
    newPasswordManagerKeyRepeat: IStatePasswordField
}

class ChangePasswordManagerKey extends React.Component<{}, IState> {
    private popUpRoot!: ReactDOM.Root | undefined;
    private errorBoxRoot!: ReactDOM.Root | undefined;
    private successBoxRoot!: ReactDOM.Root | undefined;

    constructor(props: {}) {
        super(props);

        this.state = {
            oldPasswordManagerKey: InitialStatePasswordField,
            newPasswordManagerKey: InitialStatePasswordField,
            newPasswordManagerKeyRepeat: InitialStatePasswordField
        }
    }

    savePasswordManagerKey = async () => {
        let oldPasswordManagerKey = this.state.oldPasswordManagerKey.value;
        let newPasswordManagerKey = this.state.newPasswordManagerKey.value;
        let newPasswordManagerKeyRepeat = this.state.newPasswordManagerKeyRepeat.value;

        if (oldPasswordManagerKey.length !== 0 || newPasswordManagerKey.length !== 0 || newPasswordManagerKeyRepeat.length !== 0) {
            if (newPasswordManagerKey === newPasswordManagerKeyRepeat) {
                let responseData = await updatePasswordManagerKey({
                    old_passwordManagerKey: oldPasswordManagerKey,
                    new_passwordManagerKey: newPasswordManagerKey
                });

                if (responseData !== undefined) {
                    if (responseData === "success") {
                        if (this.errorBoxRoot !== undefined) {
                            this.errorBoxRoot.unmount();
                            this.errorBoxRoot = undefined;
                        }

                        this.setState({ oldPasswordManagerKey: InitialStatePasswordField, newPasswordManagerKey: InitialStatePasswordField, newPasswordManagerKeyRepeat: InitialStatePasswordField });

                        const successBoxContainer = document.getElementById("successBoxPasswordManagerKey");
                        if (this.successBoxRoot === undefined) {
                            this.successBoxRoot = createRoot(successBoxContainer!);
                        }

                        this.successBoxRoot.render(
                            <div id={Styles.successMessageBox}>
                                <p key="successMessage">Erfolgreich geändert!</p>
                            </div>
                        );

                        await new Promise((resolve) => setTimeout(() => resolve({}), 1500));

                        this.successBoxRoot.unmount();
                        this.successBoxRoot = undefined;
                    } else if (responseData === "forbidden") {
                        const errorBoxContainer = document.getElementById("errorBoxPasswordManagerKey");
                        if (this.errorBoxRoot === undefined) {
                            this.errorBoxRoot = createRoot(errorBoxContainer!);
                        }

                        this.errorBoxRoot.render(
                            <div id={Styles.errorMessageBox}>
                                <p key="errorMessage">Der alte Schlüssel für die Passwortverwaltung ist falsch!</p>
                            </div>
                        );
                    } else if (responseData === "equalsPassword") {
                        const errorBoxContainer = document.getElementById("errorBoxPasswordManagerKey");
                        if (this.errorBoxRoot === undefined) {
                            this.errorBoxRoot = createRoot(errorBoxContainer!);
                        }

                        this.errorBoxRoot.render(
                            <div id={Styles.errorMessageBox}>
                                <p key="errorMessage">Der neue Schlüssel für die Passwortverwaltung muss sich vom Passwort unterscheiden!</p>
                            </div>
                        );
                    }
                } else {
                    const popUpContainer = document.getElementById("popUp");
                    if (this.popUpRoot === undefined) {
                        this.popUpRoot = createRoot(popUpContainer!);
                    }

                    this.popUpRoot.render(
                        <div id={Styles.popUpBackground}>
                            <div id={Styles.errorPopUp} className={Styles.popUp}>
                                <h3>Fehler</h3>
                                <hr />
                                <p>Das Speichern der Daten ist fehlgeschlagen!</p>
                                <button onClick={this.close}>Schließen</button>
                            </div>
                        </div>
                    );
                }
            } else {
                const errorBoxContainer = document.getElementById("errorBoxPasswordManagerKey");
                if (this.errorBoxRoot === undefined) {
                    this.errorBoxRoot = createRoot(errorBoxContainer!);
                }

                this.errorBoxRoot.render(
                    <div id={Styles.errorMessageBox}>
                        <p key="errorMessage">Die neuen Passwörter müssen übereinstimmen!</p>
                    </div>
                );
            }
        }
    }

    close = () => {
        if (this.popUpRoot !== undefined) {
            this.popUpRoot.unmount();
            this.popUpRoot = undefined;
        }
    }

    render() {
        return (
            <div id={Styles.changePasswordManagerKey}>
                <h4>Schlüssel ändern</h4>

                <div id="errorBoxPasswordManagerKey" />
                <div>
                    <h6>Alter Schlüssel</h6>
                    <PasswordField state={this.state.oldPasswordManagerKey} setState={(oldPasswordManagerKey: IStatePasswordField) => this.setState({ oldPasswordManagerKey: oldPasswordManagerKey })} />

                    <h6>Neuer Schlüssel</h6>
                    <PasswordField state={this.state.newPasswordManagerKey} setState={(newPasswordManagerKey: IStatePasswordField) => this.setState({ newPasswordManagerKey: newPasswordManagerKey })} />

                    <h6>Neuen Schlüssel wiederholen</h6>
                    <PasswordField state={this.state.newPasswordManagerKeyRepeat} setState={(newPasswordManagerKeyRepeat: IStatePasswordField) => this.setState({ newPasswordManagerKeyRepeat: newPasswordManagerKeyRepeat })} />

                    <div id="successBoxPasswordManagerKey" />
                    <button onClick={this.savePasswordManagerKey}>Ändern</button>
                </div>
            </div>
        );
    }
}

export default ChangePasswordManagerKey;