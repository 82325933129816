import React from "react";

import NumberFieldSpinner from "./NumberFieldSpinner.module.css";
import Styles from "./DateField.module.css";
import singleArrowUp from "../../icons/singleArrowUp.svg";
import singleArrowDown from "../../icons/singleArrowDown.svg";

interface IProps {
    state: IStateDateField
    setState: Function
    day?: number
    month?: number
    year?: number
    dayEdit?: boolean
    monthEdit?: boolean
    yearEdit?: boolean
    dateMin?: Date
    dateMax?: Date
    onChange?: Function
}

export interface IStateDateField {
    day: {
        value: number,
        required: boolean
    }
    month: {
        value: number,
        required: boolean
    }
    year: {
        value: number,
        required: boolean
    }
}

export const InitialStateDateField = {
    day: {
        value: new Date().getDate(),
        required: false
    },
    month: {
        value: new Date().getMonth() + 1,
        required: false
    },
    year: {
        value: new Date().getFullYear(),
        required: false
    }
}

class DateField extends React.Component<IProps, IStateDateField> {
    private edit: {
        day: boolean,
        month: boolean,
        year: boolean
    };
    constructor(props: IProps) {
        super(props);

        this.edit = {
            day: this.props.dayEdit === undefined ? true : this.props.dayEdit,
            month: this.props.monthEdit === undefined ? true : this.props.monthEdit,
            year: this.props.yearEdit === undefined ? true : this.props.yearEdit
        }

        this.props.setState({
            day: {
                value: this.props.day ? this.props.day : new Date().getDate(),
                required: false
            },
            month: {
                value: this.props.month ? this.props.month : new Date().getMonth() + 1,
                required: false
            },
            year: {
                value: this.props.year ? this.props.year : new Date().getFullYear(),
                required: false
            }
        });

        this.handleChangeDay = this.handleChangeDay.bind(this);
        this.valueUpDay = this.valueUpDay.bind(this);
        this.valueDownDay = this.valueDownDay.bind(this);

        this.handleChangeMonth = this.handleChangeMonth.bind(this);
        this.valueUpMonth = this.valueUpMonth.bind(this);
        this.valueDownMonth = this.valueDownMonth.bind(this);

        this.handleChangeYear = this.handleChangeYear.bind(this);
        this.valueUpYear = this.valueUpYear.bind(this);
        this.valueDownYear = this.valueDownYear.bind(this);
    }

    handleChangeDay(event: any) {
        const inputElement: HTMLInputElement = event.target;
        const min = 1;
        const max = 31;

        if (inputElement.validity.badInput) {
            this.props.setState({
                day: {
                    value: min,
                    required: false
                },
                month: this.props.state.month,
                year: this.props.state.year
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: {
                        value: min
                    },
                    month: this.props.state.month,
                    year: this.props.state.year
                });
            }
        } else if (inputElement.value.length === 0) {
            this.props.setState({
                day: {
                    value: inputElement.valueAsNumber,
                    required: true
                },
                month: this.props.state.month,
                year: this.props.state.year
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: {
                        value: inputElement.valueAsNumber
                    },
                    month: this.props.state.month,
                    year: this.props.state.year
                });
            }
        } else {
            let date = new Date(this.props.state.year.value, this.props.state.month.value - 1, inputElement.valueAsNumber);

            let maxDateCorrect = true;
            if (this.props.dateMax) {
                maxDateCorrect = date.getTime() <= this.props.dateMax.getTime();
            }

            let minDateCorrect = true;
            if (this.props.dateMin) {
                minDateCorrect = date.getTime() >= this.props.dateMin.getTime();
            }

            if ((inputElement.valueAsNumber <= max && inputElement.valueAsNumber >= min) && (minDateCorrect && maxDateCorrect)) {
                this.props.setState({
                    day: {
                        value: inputElement.valueAsNumber,
                        required: false
                    },
                    month: this.props.state.month,
                    year: this.props.state.year
                });
            } else {
                this.props.setState({
                    day: {
                        value: inputElement.valueAsNumber,
                        required: true
                    },
                    month: this.props.state.month,
                    year: this.props.state.year
                });
            }

            if (this.props.onChange) {
                this.props.onChange({
                    day: {
                        value: inputElement.valueAsNumber
                    },
                    month: this.props.state.month,
                    year: this.props.state.year
                });
            }
        }
    }

    valueUpDay() {
        const max = 31;
        const step = 1;

        let date = new Date(this.props.state.year.value, this.props.state.month.value - 1, this.props.state.day.value + step);

        let maxDateCorrect = true;
        if (this.props.dateMax) {
            maxDateCorrect = date.getTime() <= this.props.dateMax.getTime();
        }

        if (this.props.state.day.value + step <= max && maxDateCorrect) {
            this.props.setState({
                day: { value: this.props.state.day.value + step },
                month: this.props.state.month,
                year: this.props.state.year
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: {
                        value: this.props.state.day.value + step
                    },
                    month: this.props.state.month,
                    year: this.props.state.year
                });
            }
        }
    }

    valueDownDay() {
        const min = 1;
        const step = 1;

        let date = new Date(this.props.state.year.value, this.props.state.month.value - 1, this.props.state.day.value - step);

        let minDateCorrect = true;
        if (this.props.dateMin) {
            minDateCorrect = date.getTime() >= this.props.dateMin.getTime();
        }

        if (this.props.state.day.value - step >= min && minDateCorrect) {
            this.props.setState({
                day: { value: this.props.state.day.value - step },
                month: this.props.state.month,
                year: this.props.state.year
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: {
                        value: this.props.state.day.value - step
                    },
                    month: this.props.state.month,
                    year: this.props.state.year
                });
            }
        }
    }

    handleChangeMonth(event: any) {
        const inputElement: HTMLInputElement = event.target;
        const min = 1;
        const max = 12;

        if (inputElement.validity.badInput) {
            this.props.setState({
                day: this.props.state.day,
                month: {
                    value: min,
                    required: false
                },
                year: this.props.state.year
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: this.props.state.day,
                    month: {
                        value: min
                    },
                    year: this.props.state.year
                });
            }
        } else if (inputElement.value.length === 0) {
            this.props.setState({
                day: this.props.state.day,
                month: {
                    value: inputElement.valueAsNumber
                },
                year: this.props.state.year
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: this.props.state.day,
                    month: {
                        value: inputElement.valueAsNumber
                    },
                    year: this.props.state.year
                });
            }
        } else {
            let date = new Date(this.props.state.year.value, this.props.state.month.value - 1, inputElement.valueAsNumber);

            let maxDateCorrect = true;
            if (this.props.dateMax) {
                maxDateCorrect = date.getTime() <= this.props.dateMax.getTime();
            }

            let minDateCorrect = true;
            if (this.props.dateMin) {
                minDateCorrect = date.getTime() >= this.props.dateMin.getTime();
            }

            if ((inputElement.valueAsNumber <= max && inputElement.valueAsNumber >= min) && (minDateCorrect && maxDateCorrect)) {
                this.props.setState({
                    day: this.props.state.day,
                    month: {
                        value: inputElement.valueAsNumber
                    },
                    year: this.props.state.year
                });
            } else {
                this.props.setState({
                    day: this.props.state.day,
                    month: {
                        value: inputElement.valueAsNumber
                    },
                    year: this.props.state.year
                });
            }

            if (this.props.onChange) {
                this.props.onChange({
                    day: this.props.state.day,
                    month: {
                        value: inputElement.valueAsNumber
                    },
                    year: this.props.state.year
                });
            }
        }
    }

    valueUpMonth() {
        const max = 12;
        const step = 1;

        let date = new Date(this.props.state.year.value, this.props.state.month.value + step - 1, this.props.state.day.value);

        let maxDateCorrect = true;
        if (this.props.dateMax) {
            maxDateCorrect = date.getTime() <= this.props.dateMax.getTime();
        }

        if (this.props.state.month.value + step <= max && maxDateCorrect) {
            this.props.setState({
                day: this.props.state.day,
                month: { value: this.props.state.month.value + step },
                year: this.props.state.year
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: this.props.state.day,
                    month: {
                        value: this.props.state.month.value + step
                    },
                    year: this.props.state.year
                });
            }
        }
    }

    valueDownMonth() {
        const min = 1;
        const step = 1;

        let date = new Date(this.props.state.year.value, this.props.state.month.value - step - 1, this.props.state.day.value);

        let minDateCorrect = true;
        if (this.props.dateMin) {
            minDateCorrect = date.getTime() >= this.props.dateMin.getTime();
        }

        if (this.props.state.month.value - step >= min && minDateCorrect) {
            this.props.setState({
                day: this.props.state.day,
                month: { value: this.props.state.month.value - step },
                year: this.props.state.year
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: this.props.state.day,
                    month: {
                        value: this.props.state.month.value - step
                    },
                    year: this.props.state.year
                });
            }
        }
    }

    handleChangeYear(event: any) {
        const inputElement: HTMLInputElement = event.target;

        let year = new Date().getFullYear();
        if (this.props.year) {
            year = this.props.year;
        }

        if (inputElement.validity.badInput) {
            this.props.setState({
                day: this.props.state.day,
                month: this.props.state.month,
                year: {
                    value: year,
                    required: false
                }
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: this.props.state.day,
                    month: this.props.state.month,
                    year: {
                        value: year
                    }
                });
            }
        } else if (inputElement.value.length === 0) {
            this.props.setState({
                day: this.props.state.day,
                month: this.props.state.month,
                year: {
                    value: inputElement.valueAsNumber,
                    required: true
                }
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: this.props.state.day,
                    month: this.props.state.month,
                    year: {
                        value: inputElement.valueAsNumber
                    }
                });
            }
        } else {
            let date = new Date(this.props.state.year.value, this.props.state.month.value - 1, inputElement.valueAsNumber);

            let maxDateCorrect = true;
            if (this.props.dateMax) {
                maxDateCorrect = date.getTime() <= this.props.dateMax.getTime();
            }

            let minDateCorrect = true;
            if (this.props.dateMin) {
                minDateCorrect = date.getTime() >= this.props.dateMin.getTime();
            }

            if (minDateCorrect && maxDateCorrect) {
                this.props.setState({
                    day: this.props.state.day,
                    month: this.props.state.month,
                    year: {
                        value: inputElement.valueAsNumber,
                        required: false
                    }
                });
            } else {
                this.props.setState({
                    day: this.props.state.day,
                    month: this.props.state.month,
                    year: {
                        value: inputElement.valueAsNumber,
                        required: true
                    }
                });
            }

            if (this.props.onChange) {
                this.props.onChange({
                    day: this.props.state.day,
                    month: this.props.state.month,
                    year: {
                        value: inputElement.valueAsNumber
                    }
                });
            }
        }
    }

    valueUpYear() {
        const step = 1;

        let date = new Date(this.props.state.year.value + step, this.props.state.month.value - 1, this.props.state.day.value);

        let maxDateCorrect = true;
        if (this.props.dateMax) {
            maxDateCorrect = date.getTime() <= this.props.dateMax.getTime();
        }

        if (maxDateCorrect) {
            this.props.setState({
                day: this.props.state.day,
                month: this.props.state.month,
                year: { value: this.props.state.year.value + step }
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: this.props.state.day,
                    month: this.props.state.month,
                    year: {
                        value: this.props.state.year.value + step
                    }
                });
            }
        }
    }

    valueDownYear() {
        const step = 1;

        let date = new Date(this.props.state.year.value - step, this.props.state.month.value - 1, this.props.state.day.value);

        let minDateCorrect = true;
        if (this.props.dateMin) {
            minDateCorrect = date.getTime() >= this.props.dateMin.getTime();
        }

        if (minDateCorrect) {
            this.props.setState({
                day: this.props.state.day,
                month: this.props.state.month,
                year: { value: this.props.state.year.value - step }
            });

            if (this.props.onChange) {
                this.props.onChange({
                    day: this.props.state.day,
                    month: this.props.state.month,
                    year: {
                        value: this.props.state.year.value - step
                    }
                });
            }
        }
    }

    render() {
        let dayElement: JSX.Element;
        let monthElement: JSX.Element;
        let yearElement: JSX.Element;

        if (this.edit.day) {
            dayElement =
                <div id={Styles.dayInput}>
                    <label id={NumberFieldSpinner.inputLabel}>
                        <input autoComplete={"off"} type={"number"} size={40} onChange={this.handleChangeDay} value={this.props.state.day.value} required={this.props.state.day.required} />
                        <i>
                            <div onClick={this.valueUpDay}>
                                <img src={singleArrowUp} alt={"singleArrowUp"} />
                            </div>
                            <div onClick={this.valueDownDay}>
                                <img src={singleArrowDown} alt={"singleArrowDown"} />
                            </div>
                        </i>
                    </label>
                </div>
        } else {
            dayElement = <p id={Styles.day}>{this.props.state.day.value}</p>
        }

        if (this.edit.month) {
            monthElement =
                <div id={Styles.monthInput}>
                    <label id={NumberFieldSpinner.inputLabel}>
                        <input autoComplete={"off"} type={"number"} size={40} onChange={this.handleChangeMonth} value={this.props.state.month.value} required={this.props.state.month.required} />
                        <i>
                            <div onClick={this.valueUpMonth}>
                                <img src={singleArrowUp} alt={"singleArrowUp"} />
                            </div>
                            <div onClick={this.valueDownMonth}>
                                <img src={singleArrowDown} alt={"singleArrowDown"} />
                            </div>
                        </i>
                    </label>
                </div>
        } else {
            monthElement = <p id={Styles.month}>{this.props.state.month.value}</p>
        }

        if (this.edit.year) {
            yearElement =
                <div id={Styles.yearInput}>
                    <label id={NumberFieldSpinner.inputLabel}>
                        <input autoComplete={"off"} type={"number"} size={40} onChange={this.handleChangeYear} value={this.props.state.year.value} required={this.props.state.year.required} />
                        <i>
                            <div onClick={this.valueUpYear}>
                                <img src={singleArrowUp} alt={"singleArrowUp"} />
                            </div>
                            <div onClick={this.valueDownYear}>
                                <img src={singleArrowDown} alt={"singleArrowDown"} />
                            </div>
                        </i>
                    </label>
                </div>
        } else {
            yearElement = <p id={Styles.year}>{this.props.state.year.value}</p>
        }

        return (
            <div id={Styles.dateInput}>
                {dayElement}
                <p className={Styles.dateDot}>.</p>
                {monthElement}
                <p className={Styles.dateDot}>.</p>
                {yearElement}
            </div>
        )
    }
}

export default DateField
