import CryptoJS from "crypto-js";
import { IPasswordData_ID, createPasswordData } from "./passwordManager"

interface IAPIResponseData {
    status: number
    message: string
}

interface IAPIResponseArrayDataAdmin {
    status: number
    message: string
    userData: Array<IResponseUserDataAdmin>
}

interface IAPIResponseUserDataAdmin {
    status: number
    message: string
    userData: IResponseUserDataAdmin
}

interface IResponseUserDataAdmin {
    id: number
    username: string
    email: string | null
    firstName: string | null
    lastName: string | null
    right: boolean
    autoLogOut: boolean
    emailVerified: boolean
    active: boolean
}

export interface IUserDataAdmin {
    id: number
    username: string
    email: string
    firstName: string
    lastName: string
    right: boolean
    autoLogOut: boolean
    emailVerified: boolean
    active: boolean
}

interface IAPIResponseUserData {
    status: number
    message: string
    userData: IResponseUserData
}

interface IResponseUserData {
    id: number
    username: string
    email: string
    firstName: string
    lastName: string
    passwordManagerKey: string | null
    right: boolean
    autoLogOut: boolean
    emailVerified: boolean
    active: boolean
}

export interface IUserData {
    id: number
    username: string
    email: string
    firstName: string
    lastName: string
    isPasswordManagerKey: boolean
    right: boolean
    autoLogOut: boolean
    emailVerified: boolean
    active: boolean
}

export interface IUserCreateData {
    username: string
    right: boolean
    active: boolean
}

export interface IUserUpdateData {
    id: number
    right: boolean
    active: boolean
}

export interface IErrorMessage {
    message: string
}

export async function createUserData(data: IUserCreateData): Promise<IUserDataAdmin | string | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;
    const USER_MANAGER_TOKEN = window.sessionStorage.getItem("userManagerToken");

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${USER_MANAGER_TOKEN}`
        },
        body: JSON.stringify({
            username: data.username,
            right: data.right,
            active: data.active
        }),
        credentials: 'include'
    }

    let response: IAPIResponseUserDataAdmin = await fetch(`${API_URL}userAdmin/create.php`, requestOptions).then(response => response.json());

    if (response.status === 432) {
        return "usernameExist";
    } else if (response.status !== 201) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }

    return {
        id: response.userData.id,
        username: response.userData.username,
        email: response.userData.email ?? "",
        firstName: response.userData.firstName ?? "",
        lastName: response.userData.lastName ?? "",
        right: response.userData.right,
        autoLogOut: response.userData.autoLogOut,
        emailVerified: response.userData.emailVerified,
        active: response.userData.active
    };
}

export async function getAllUserData(): Promise<Array<IUserDataAdmin>> {
    const API_URL = process.env.REACT_APP_API_URL;
    const USER_MANAGER_TOKEN = window.sessionStorage.getItem("userManagerToken");

    const requestoptions: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${USER_MANAGER_TOKEN}`
        },
        credentials: 'include'
    }

    try {
        let userData: Array<IUserDataAdmin> = await fetch(`${API_URL}userAdmin/get.php`, requestoptions)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return {
                        status: response.status,
                        message: response.statusText,
                        user: []
                    }
                }
            })
            .then((response: IAPIResponseArrayDataAdmin) => {
                if (response.status === 200) {
                    return response.userData.map(value => {
                        return {
                            id: value.id,
                            username: value.username,
                            email: value.email ?? "",
                            firstName: value.firstName ?? "",
                            lastName: value.lastName ?? "",
                            right: value.right,
                            autoLogOut: value.autoLogOut,
                            emailVerified: value.emailVerified,
                            active: value.active
                        };
                    });
                } else if (response.status === 204) {
                    return [];
                } else {
                    console.log(`Error: ${response.message}`);
                    return [];
                }
            });

        return userData;
    } catch (e) {
        return [];
    }
}

export async function getUserData(id?: number): Promise<IUserDataAdmin> {
    const API_URL = process.env.REACT_APP_API_URL;
    const USER_MANAGER_TOKEN = window.sessionStorage.getItem("userManagerToken");

    const requestoptions: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${USER_MANAGER_TOKEN}`
        },
        credentials: 'include'
    }

    let userData: IUserDataAdmin = await fetch(`${API_URL}userAdmin/get.php?id=${id}`, requestoptions)
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 404) {
                return {
                    status: 404,
                    message: "Not Found",
                    user: []
                }
            }
        })
        .then((response: IAPIResponseUserDataAdmin) => {
            if (response.status === 200) {
                return {
                    id: response.userData.id,
                    username: response.userData.username,
                    email: response.userData.email ?? "",
                    firstName: response.userData.firstName ?? "",
                    lastName: response.userData.lastName ?? "",
                    right: response.userData.right,
                    autoLogOut: response.userData.autoLogOut,
                    emailVerified: response.userData.emailVerified,
                    active: response.userData.active
                };
            } else {
                console.log(`Error: ${response.message}`);
                return {
                    id: -1,
                    username: "",
                    email: "",
                    firstName: "",
                    lastName: "",
                    right: false,
                    autoLogOut: true,
                    emailVerified: false,
                    active: false
                };
            }
        });

    return userData;
}

export async function updateUserData(data: IUserUpdateData): Promise<IUserDataAdmin | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;
    const USER_MANAGER_TOKEN = window.sessionStorage.getItem("userManagerToken");

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${USER_MANAGER_TOKEN}`
        },
        body: JSON.stringify({
            id: data.id,
            right: data.right,
            active: data.active
        }),
        credentials: 'include'
    }

    let response: IAPIResponseUserDataAdmin = await fetch(`${API_URL}userAdmin/update.php?id=${data.id}`, requestOptions).then(response => response.json());

    if (response.status !== 200) {
        console.log(`Error: ${response.message}`);
        return undefined;
    }

    return {
        id: response.userData.id,
        username: response.userData.username,
        email: response.userData.email ?? "",
        firstName: response.userData.firstName ?? "",
        lastName: response.userData.lastName ?? "",
        right: response.userData.right,
        autoLogOut: response.userData.autoLogOut,
        emailVerified: response.userData.emailVerified,
        active: response.userData.active
    };
}

export async function getUser(): Promise<IUserData | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;
    const USER_MANAGER_TOKEN = window.sessionStorage.getItem("token");

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${USER_MANAGER_TOKEN}` },
        credentials: 'include'
    }

    let response: IAPIResponseUserData = await fetch(`${API_URL}user/get.php`, requestOptions).then(response => response.json());
    if (response.status === 200) {
        return {
            id: response.userData.id,
            username: response.userData.username,
            email: response.userData.email,
            firstName: response.userData.firstName,
            lastName: response.userData.lastName,
            isPasswordManagerKey: response.userData.passwordManagerKey !== null,
            right: response.userData.right,
            autoLogOut: response.userData.autoLogOut,
            emailVerified: response.userData.emailVerified,
            active: response.userData.active
        };
    } else {
        return undefined;
    }
}

interface IUserUpdatePassword {
    old_password: string
    new_password: string
}

export async function updatePassword(data: IUserUpdatePassword): Promise<string | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = window.sessionStorage.getItem("token");

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        },
        body: JSON.stringify({
            old_password: data.old_password,
            new_password: data.new_password
        }),
        credentials: 'include'
    }

    let response: IAPIResponseData = await fetch(`${API_URL}user/changePassword.php`, requestOptions).then(response => response.json());

    if (response.status === 200) {
        return "success";
    } else if (response.status === 401) {
        return "forbidden";
    } else if (response.status === 435) {
        return "equalsPasswordManagerKey";
    } else {
        console.log(`Error: ${response.message}`);
        return undefined;
    }
}

interface IUserUpdatePasswordManagerKey {
    old_passwordManagerKey: string | null
    new_passwordManagerKey: string
}

interface TokenAPIResponse {
    status: number
    message: string
    passwordManagerToken: string
}

interface ITokenUpdateResponseData {
    status: number
    message: string
    passwordData: Array<IPasswordData_ID>
}

export async function updatePasswordManagerKey(data: IUserUpdatePasswordManagerKey): Promise<string | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = window.sessionStorage.getItem("token");

    const tokenRequestOptions: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        },
        body: JSON.stringify({
            passwordManagerKey: data.old_passwordManagerKey
        }),
        credentials: 'include'
    }

    let tokenResponse: TokenAPIResponse = await fetch(`${API_URL}user/getPasswordManagerToken.php`, tokenRequestOptions).then(response => response.json());

    if (tokenResponse.status !== 401) {
        const PASSWORD_MANAGER_TOKEN = tokenResponse.passwordManagerToken;
        const AES_KEY = process.env.REACT_APP_AES_KEY;

        const requestOptions: RequestInit = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify({
                old_passwordManagerKey: data.old_passwordManagerKey,
                new_passwordManagerKey: data.new_passwordManagerKey
            }),
            credentials: 'include'
        }

        let response: ITokenUpdateResponseData = await fetch(`${API_URL}user/changePasswordManagerKey.php`, requestOptions).then(response => response.json());

        if (response.status === 200) {
            if (data.old_passwordManagerKey !== null) {
                updatePasswordData(PASSWORD_MANAGER_TOKEN, data.old_passwordManagerKey, data.new_passwordManagerKey);
            } else {
                const tokenRequestOptions: RequestInit = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${TOKEN}`
                    },
                    body: JSON.stringify({
                        passwordManagerKey: data.new_passwordManagerKey
                    }),
                    credentials: 'include'
                }

                let tokenResponse: TokenAPIResponse = await fetch(`${API_URL}user/getPasswordManagerToken.php`, tokenRequestOptions).then(response => response.json());

                window.sessionStorage.setItem("passwordManagerToken", tokenResponse.passwordManagerToken);
                window.sessionStorage.setItem("passwordManagerKey", CryptoJS.AES.encrypt(data.new_passwordManagerKey, AES_KEY || "").toString());

                response.passwordData.forEach(async (value: IPasswordData_ID) => {
                    let passwordData = {
                        platform: value.platform,
                        name: value.name,
                        email: value.email,
                        username: value.username,
                        password: value.password,
                        notes: value.notes
                    }

                    await createPasswordData(passwordData);
                });

                window.sessionStorage.removeItem("passwordManagerToken");
            }

            return "success";
        } else if (response.status === 401) {
            return "forbidden";
        } else if (response.status === 435) {
            return "equalsPassword";
        } else {
            console.log(`Error: ${response.message}`);
            return undefined;
        }
    } else {
        console.log(`Error: ${tokenResponse.message}`);
        return undefined;
    }
}

interface IResponseArrayPasswordData {
    status: number
    message: string
    password: Array<IPasswordData_ID>
}

async function updatePasswordData(PASSWORD_MANAGER_TOKEN: string, old_passwordManagerKey: string, new_passwordManagerKey: string) {
    const API_URL = process.env.REACT_APP_API_URL;

    const requestoptions: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${PASSWORD_MANAGER_TOKEN}`
        },
        credentials: 'include'
    }

    let passwordData: Array<IPasswordData_ID> = await fetch(`${API_URL}password/get.php`, requestoptions)
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return {
                    status: response.status,
                    message: response.statusText,
                    password: []
                }
            }
        })
        .then((response: IResponseArrayPasswordData) => {
            if (response.status === 200) {
                return response.password.map((value: IPasswordData_ID) => {
                    return {
                        id: value.id,
                        platform: CryptoJS.AES.decrypt(value.platform, old_passwordManagerKey).toString(CryptoJS.enc.Utf8),
                        name: CryptoJS.AES.decrypt(value.name, old_passwordManagerKey).toString(CryptoJS.enc.Utf8),
                        email: CryptoJS.AES.decrypt(value.email, old_passwordManagerKey).toString(CryptoJS.enc.Utf8),
                        username: CryptoJS.AES.decrypt(value.username, old_passwordManagerKey).toString(CryptoJS.enc.Utf8),
                        password: CryptoJS.AES.decrypt(value.password, old_passwordManagerKey).toString(CryptoJS.enc.Utf8),
                        notes: CryptoJS.AES.decrypt(value.notes, old_passwordManagerKey).toString(CryptoJS.enc.Utf8)
                    }
                });
            } else if (response.status === 204) {
                return [];
            } else {
                console.log(`Error: ${response.message}`);
                return [];
            }
        });

    passwordData.forEach(async (value) => {
        const requestOptions: RequestInit = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${PASSWORD_MANAGER_TOKEN}`
            },
            body: JSON.stringify({
                id: value.id,
                platform: CryptoJS.AES.encrypt(value.platform, new_passwordManagerKey).toString(),
                name: CryptoJS.AES.encrypt(value.name, new_passwordManagerKey).toString(),
                email: CryptoJS.AES.encrypt(value.email, new_passwordManagerKey).toString(),
                username: CryptoJS.AES.encrypt(value.username, new_passwordManagerKey).toString(),
                password: CryptoJS.AES.encrypt(value.password, new_passwordManagerKey).toString(),
                notes: CryptoJS.AES.encrypt(value.notes, new_passwordManagerKey).toString()
            }),
            credentials: 'include'
        }

        await fetch(`${API_URL}password/update.php?id=${value.id}`, requestOptions).then(response => response.json());
    });
}

interface IUserUpdateAutoLogOut {
    autoLogOut: boolean
}

export async function updateAutoLogOut(data: IUserUpdateAutoLogOut): Promise<IUserData | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = window.sessionStorage.getItem("token");

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        },
        body: JSON.stringify({
            autoLogOut: data.autoLogOut
        }),
        credentials: 'include'
    }

    let response: IAPIResponseUserData = await fetch(`${API_URL}user/update.php`, requestOptions).then(response => response.json());

    if (response.status === 200) {
        return {
            id: response.userData.id,
            username: response.userData.username,
            email: response.userData.email,
            firstName: response.userData.firstName,
            lastName: response.userData.lastName,
            isPasswordManagerKey: response.userData.passwordManagerKey !== null,
            right: response.userData.right,
            autoLogOut: response.userData.autoLogOut,
            emailVerified: response.userData.emailVerified,
            active: response.userData.active
        }
    } else {
        console.log(`Error: ${response.message}`);
        return undefined;
    }
}

interface IUserUpdateName {
    firstName: string
    lastName: string
}

export async function updateName(data: IUserUpdateName): Promise<IUserData | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = window.sessionStorage.getItem("token");

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        },
        body: JSON.stringify({
            firstName: data.firstName,
            lastName: data.lastName
        }),
        credentials: 'include'
    }

    let response: IAPIResponseUserData = await fetch(`${API_URL}user/update.php`, requestOptions).then(response => response.json());

    if (response.status === 200) {
        return {
            id: response.userData.id,
            username: response.userData.username,
            email: response.userData.email,
            firstName: response.userData.firstName,
            lastName: response.userData.lastName,
            isPasswordManagerKey: response.userData.passwordManagerKey !== null,
            right: response.userData.right,
            autoLogOut: response.userData.autoLogOut,
            emailVerified: response.userData.emailVerified,
            active: response.userData.active
        }
    } else {
        console.log(`Error: ${response.message}`);
        return undefined;
    }
}

interface IUserUpdateEmail {
    email: string
}

export async function updateEmail(data: IUserUpdateEmail): Promise<IUserData | string | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = window.sessionStorage.getItem("token");

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        },
        body: JSON.stringify({
            email: data.email
        }),
        credentials: 'include'
    }

    let response: IAPIResponseUserData = await fetch(`${API_URL}user/changeEmail.php`, requestOptions).then(response => response.json());

    if (response.status === 200) {
        return {
            id: response.userData.id,
            username: response.userData.username,
            email: response.userData.email,
            firstName: response.userData.firstName,
            lastName: response.userData.lastName,
            isPasswordManagerKey: response.userData.passwordManagerKey !== null,
            right: response.userData.right,
            autoLogOut: response.userData.autoLogOut,
            emailVerified: response.userData.emailVerified,
            active: response.userData.active
        }
    } else if (response.status === 436) {
        return "alreadyTaken";
    } else {
        console.log(`Error: ${response.message}`);
        return undefined;
    }
}

interface IUserUpdateUsername {
    username: string
}

export async function updateUsername(data: IUserUpdateUsername): Promise<IUserData | string | undefined> {
    const API_URL = process.env.REACT_APP_API_URL;
    const TOKEN = window.sessionStorage.getItem("token");

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN}`
        },
        body: JSON.stringify({
            username: data.username
        }),
        credentials: 'include'
    }

    let response: IAPIResponseUserData = await fetch(`${API_URL}user/changeUsername.php`, requestOptions).then(response => response.json());

    if (response.status === 200) {
        return {
            id: response.userData.id,
            username: response.userData.username,
            email: response.userData.email,
            firstName: response.userData.firstName,
            lastName: response.userData.lastName,
            isPasswordManagerKey: response.userData.passwordManagerKey !== null,
            right: response.userData.right,
            autoLogOut: response.userData.autoLogOut,
            emailVerified: response.userData.emailVerified,
            active: response.userData.active
        }
    } if (response.status === 436) {
        return "alreadyTaken";
    } else {
        console.log(`Error: ${response.message}`);
        return undefined;
    }
}