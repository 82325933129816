import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import Styles from "./PasswordReset.module.css";
import EmailField, { IStateEmailField, InitialStateEmailField } from "../inputFields/EmailField";
import TextField, { IStateTextField, InitialStateTextField } from "../inputFields/TextField";
import { createPasswordResetToken } from "../../utils/token";

import cross from "../../icons/crossWhite.svg";

interface IProps {
    close: Function
}

interface IState {
    username: IStateTextField
    email: IStateEmailField
    sendMail: JSX.Element
}

class PasswordReset extends React.Component<IProps, IState> {
    private errorBoxPasswordResetRoot!: ReactDOM.Root | undefined;
    private successBoxPasswordResetRoot!: ReactDOM.Root | undefined;

    constructor(props: IProps) {
        super(props);

        this.state = {
            username: InitialStateTextField,
            email: InitialStateEmailField,
            sendMail: <button onClick={() => this.requestPasswordReset()}>E-Mail senden</button>
        }
    }

    requestPasswordReset = async () => {
        let response = await createPasswordResetToken({
            username: this.state.username.value,
            email: this.state.email.value,
            requestTimestamp: new Date().getTime()
        });

        const errorBoxPasswordResetContainer = document.getElementById("errorBoxPasswordReset");
        if (this.errorBoxPasswordResetRoot === undefined) {
            this.errorBoxPasswordResetRoot = createRoot(errorBoxPasswordResetContainer!);
        }

        const successBoxPasswordResetContainer = document.getElementById("successBoxPasswordReset");
        if (this.successBoxPasswordResetRoot === undefined) {
            this.successBoxPasswordResetRoot = createRoot(successBoxPasswordResetContainer!);
        }

        if (response !== undefined) {
            if (response === "SUCCESS") {
                if (this.errorBoxPasswordResetRoot !== undefined) {
                    this.errorBoxPasswordResetRoot.unmount();
                }

                this.successBoxPasswordResetRoot.render(
                    <div id={Styles.successMessageBox}>
                        <p key="successMessage">Die E-Mail zum Passwort zurücksetzen ist versendet!</p>
                    </div>
                );

                this.setState({ sendMail: <button onClick={() => this.props.close()}>Zur Anmeldung</button> });
            } else if (response === "EMAIL_NO_USERNAME") {
                if (this.errorBoxPasswordResetRoot === undefined) {
                    this.errorBoxPasswordResetRoot = createRoot(errorBoxPasswordResetContainer!);
                }

                this.errorBoxPasswordResetRoot.render(
                    <div id={Styles.errorMessageBox}>
                        <p key="errorMessage">Benutzername und E-Mail Adresse gehören nicht zusammen!</p>
                    </div>
                );
            }
        }
    }

    render() {
        return (
            <div id={Styles.passwordResetPopUp}>
                <div id={Styles.headline}>
                    <h3>Passwort zurücksetzen?</h3>
                    <div onClick={() => this.props.close()}>
                        <img src={cross} alt={"cross"} />
                    </div>
                </div>
                <div id="errorBoxPasswordReset" />
                <div id="successBoxPasswordReset" />
                <div id={Styles.input}>
                    <h6>Benutzername</h6>
                    <TextField required={true} state={this.state.username} setState={(value: IStateTextField) => this.setState({ username: value })} tooltipRequired={false} />
                    <h6>E-Mail</h6>
                    <EmailField state={this.state.email} setState={(value: IStateEmailField) => this.setState({ email: value })} />
                </div>
                {this.state.sendMail}
            </div>
        );
    }
}

export default PasswordReset;