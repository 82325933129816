import React from 'react';
import ReactDOM, { createRoot } from "react-dom/client";

import Navigation from "../modules/Navigation"

import { IUserData, IUserDataAdmin, getAllUserData, getUserData } from '../utils/userManager';

import Styles from "../css/UserManager.module.css"
import List from '../modules/userManager/List';
import Add from '../modules/userManager/Add';
import Edit from '../modules/userManager/Edit';
import Show from '../modules/userManager/Show';

let userDataRoot: ReactDOM.Root | undefined;

export interface IAutoLogOut {
    active: boolean
    endTimestamp: number
}

interface IState {
    selectedID: number | undefined
    autoLogOutText: string
    userData: Array<IUserDataAdmin>
}

class UserManager extends React.Component<{}, IState> {
    private userData!: IUserData;

    constructor(props: {}) {
        super(props);

        document.title = "Benutzerverwaltung";
        this.userData = JSON.parse(window.sessionStorage.getItem("userData") || "");

        if (this.userData === null) {
            window.sessionStorage.clear();
            document.location.href = "/error";
        }

        this.state = {
            selectedID: undefined,
            autoLogOutText: "",
            userData: []
        }
    }

    componentDidMount = () => {
        this.startAutoLogOut();
    }

    loadList = () => {
        getAllUserData()
            .then(userData => this.setState({ userData }));
    }

    setSelectedID = (id: number) => {
        this.setState({ selectedID: id });
    }

    userDataUnmount = () => {
        if (userDataRoot !== undefined) {
            userDataRoot.unmount();
            userDataRoot = undefined;
        }
    }

    addUserData = () => {
        if (userDataRoot === undefined) {
            const userDataContainer = document.getElementById("userData");
            userDataRoot = createRoot(userDataContainer!);
        }
        userDataRoot.render(<Add reloadList={this.loadList} setStateSelectedID={this.setSelectedID} cancel={this.userDataUnmount} show={() => { }} />);
    }

    showUserData = async (id: number, data?: IUserDataAdmin) => {
        if (id === -1) {
            this.userDataUnmount();
            return;
        }

        let userData = data;
        if (userData === undefined) {
            userData = await getUserData(id);
        }

        if (userDataRoot === undefined) {
            const userDataContainer = document.getElementById("userData");
            userDataRoot = createRoot(userDataContainer!);
        }
        userDataRoot.render(<Show userData={userData} setStateSelectedID={this.setSelectedID} edit={this.editUserData} close={this.userDataUnmount} />);
    }

    editUserData = async (id: number, data?: IUserDataAdmin) => {
        let userData = data;

        if (userData === undefined) {
            userData = await getUserData(id);
        }

        if (userDataRoot === undefined) {
            const userDataContainer = document.getElementById("userData");
            userDataRoot = createRoot(userDataContainer!);
        }
        userDataRoot.render(<Edit reloadList={this.loadList} userData={userData} show={this.showUserData} />);
    }

    startAutoLogOut = () => {
        let autoLogOut: IAutoLogOut | undefined = undefined;

        let autoLogOutSessionStorage = window.sessionStorage.getItem("userAutoLogOut");

        if (autoLogOutSessionStorage !== null) {
            autoLogOut = JSON.parse(autoLogOutSessionStorage);
        } else {
            window.sessionStorage.removeItem("userManagerToken");
            window.sessionStorage.removeItem("userAutoLogOut");

            this.signIn();
        }

        if (autoLogOut !== undefined) {
            this.loadList();

            if (autoLogOut.active) {
                let endTimestamp = autoLogOut.endTimestamp;

                let interval = setInterval(() => {
                    let timeLeft = new Date(new Date(endTimestamp).getTime() - new Date().getTime());

                    let secondsShow = "0" + timeLeft.getSeconds();
                    let minutesShow = "0" + timeLeft.getMinutes();

                    this.setState({ autoLogOutText: `Automatische Abmeldung: ${minutesShow.slice(-2)}:${secondsShow.slice(-2)}` });

                    if (timeLeft.getTime() <= 0) {
                        window.sessionStorage.removeItem("userManagerToken");
                        window.sessionStorage.removeItem("userAutoLogOut");

                        clearInterval(interval);
                        this.signIn();
                    }
                }, 500);
            } else {
                this.setState({ autoLogOutText: "Automatische Abmeldung: Deaktiviert" });
            }
        } else {
            window.sessionStorage.removeItem("userManagerToken");
            window.sessionStorage.removeItem("userAutoLogOut");

            this.signIn();
        }
    }

    signIn = () => {
        document.location = "/userManagerSignIn";
    }

    render = () => {
        if (window.sessionStorage.getItem("userManagerToken") === null) {
            this.signIn();
        } else {
            return (
                <>
                    <Navigation />
                    <div id={Styles.main}>
                        <div id={Styles.autoLogOut}>
                            <p>{this.state.autoLogOutText}</p>
                        </div>
                        <h2 id={Styles.headline}><b>Benutzer</b>verwaltung</h2>

                        <List userData={this.state.userData}
                            stateSelectedID={this.state.selectedID}
                            setStateSelectedID={this.setSelectedID}
                            addUserData={this.addUserData}
                            show={this.showUserData} />

                        <div id={Styles.userData}>
                            <div id={"userData"} />
                        </div>
                    </div>
                    <div id={"popUp"} />
                </>
            );
        }
    }
}

export default UserManager;