import { BrowserRouter, Route, Routes } from "react-router-dom";

import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Home from "./pages/Home";
import PasswordManagerSignIn from "./pages/PasswordManagerSignIn";
import PasswordManager from "./pages/PasswordManager";
import BirthdayManager from "./pages/BirthdayManager";
import UserManagerSignIn from "./pages/UserManagerSignIn";
import UserManager from "./pages/UserManager";
import Settings from "./pages/Settings";
import SignOut from "./pages/SignOut";
import Imprint from "./pages/Imprint";

import PasswordReset from "./pages/PasswordReset";
import EmailVerify from "./pages/EmailVerify";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/home" element={<Home />} />
        <Route path="/passwordManagerSignIn" element={<PasswordManagerSignIn />} />
        <Route path="/passwordManager" element={<PasswordManager />} />
        <Route path="/birthdayManager" element={<BirthdayManager />} />
        <Route path="/userManagerSignIn" element={<UserManagerSignIn />} />
        <Route path="/userManager" element={<UserManager />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/signOut" element={<SignOut />} />
        <Route path="/imprint" element={<Imprint />} />

        <Route path="/passwordReset" element={<PasswordReset />} />
        <Route path="/emailVerify" element={<EmailVerify />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
