import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import singleArrowUp from "../../icons/singleArrowUp.svg";
import singleArrowDown from "../../icons/singleArrowDown.svg";
import calendarToday from "../../icons/calendarToday.svg";
import list from "../../icons/list.svg";

import Add from "./Add";
import Show from "./Show";
import Edit from "./Edit";
import { IBirthdayData_ID, getBirthdayData } from "../../utils/birthdayManager";
import IconButton from "../inputFields/IconButton";

import Styles from "./Calendar.module.css";

import { IBirthdayManager } from "../../pages/BirthdayManager";

const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
let birthdayDataRoot: ReactDOM.Root | undefined;

interface IProps {
    birthdayData: Array<IBirthdayData_ID> | undefined
    reloadBirthdayManager: Function
    stateSelectedID: number | undefined
    setStateSelectedID: Function
}

interface IState {
    date: {
        month: number
        year: number
    }
    calendar: Array<JSX.Element>
}

class Calendar extends React.Component<IProps, IState> {
    private birthdayManager: IBirthdayManager;

    constructor(props: IProps) {
        super(props);

        if (window.sessionStorage.getItem("birthdayManager") !== null) {
            this.birthdayManager = JSON.parse(window.sessionStorage.getItem("birthdayManager") || "");
        } else {
            this.birthdayManager = {
                view: "calendar",
                date: {
                    month: new Date().getMonth() + 1,
                    year: new Date().getFullYear()
                }
            }
        }

        this.state = {
            date: this.birthdayManager.date,
            calendar: []
        }

        window.sessionStorage.setItem("birthdayManager", JSON.stringify(this.birthdayManager));
    }

    componentDidMount = () => {
        this.loadCalendar();
    }

    componentDidUpdate = (prevProps: any) => {
        if (prevProps.stateSelectedID !== this.props.stateSelectedID) {
            this.loadCalendar();
        }

        if (prevProps.birthdayData !== this.props.birthdayData) {
            this.loadCalendar();
        }
    }

    calendarBackward = (month: number) => {
        this.birthdayManager.date.month -= month

        if (this.birthdayManager.date.month < 1) {
            this.birthdayManager.date.month += 12
            this.birthdayManager.date.year -= 1
        }

        this.setState({ date: this.birthdayManager.date });
        window.sessionStorage.setItem("birthdayManager", JSON.stringify(this.birthdayManager));
        this.loadCalendar();
    }

    calendarForward = (month: number) => {
        this.birthdayManager.date.month += month

        if (this.birthdayManager.date.month > 12) {
            this.birthdayManager.date.month -= 12
            this.birthdayManager.date.year += 1
        }

        this.setState({ date: this.birthdayManager.date });
        window.sessionStorage.setItem("birthdayManager", JSON.stringify(this.birthdayManager));
        this.loadCalendar();
    }

    resetDate = () => {
        this.birthdayManager.date = {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear()
        }

        this.setState({ date: this.birthdayManager.date });
        window.sessionStorage.setItem("birthdayManager", JSON.stringify(this.birthdayManager));
        this.loadCalendar();
    }

    loadCalendar = () => {
        let calendarDate = new Date(this.birthdayManager.date.year, this.birthdayManager.date.month - 1, 1);

        let firstDayOfMonth = calendarDate.getDay();
        if (firstDayOfMonth === 0) {
            firstDayOfMonth = 6;
        } else {
            firstDayOfMonth -= 1;
        }

        let calendarDay = calendarDate.addDays(firstDayOfMonth * -1).addDays(-7);
        let weekNumber: number = calendarDay.getWeekNumber();

        let calendarTable: Array<JSX.Element> = [];

        let calendarWeek: Array<JSX.Element> = [
            <div className={Styles.calendarWeekNumber} key={"CALENDARWEEK"}>
                <p>KW</p>
            </div>
        ];

        while (weekNumber === calendarDay.getWeekNumber()) {
            let weekday = calendarDay.weekdayAsString();
            let weekdayShort = weekday.substring(0, 2);

            calendarWeek.push(
                <div className={Styles.weekday} key={`WEEKDAY${weekday}`}>
                    <p>
                        <span className={Styles.weekdayShort}>{weekdayShort}</span>
                        <span className={Styles.weekdayLong}>{weekday}</span>
                    </p>
                </div>
            );

            calendarDay = calendarDay.addDays(1);
        }

        let calendarWeekClassName = Styles.calendarWeekdays;

        while (calendarDay.isSameMonth(calendarDate.addMonths(-1)) || calendarDay.isSameMonth(calendarDate)) {
            if (calendarWeek.length === 8) {
                let key = calendarWeek.map(value => value.key?.toString().substring(11, 21)).join(",");
                calendarTable.push(<div className={calendarWeekClassName} key={key}>
                    {calendarWeek}
                </div>);

                calendarWeekClassName = Styles.calendarWeek;
                calendarWeek = [];
            }

            if (weekNumber !== calendarDay.getWeekNumber()) {
                weekNumber = calendarDay.getWeekNumber();
                calendarWeek.push(
                    <div className={Styles.calendarWeekNumber} key={`CALENDARWEEKNUMBER${weekNumber}`}>
                        <p>{weekNumber}</p>
                    </div>
                );
            }

            let placeholder = calendarDay.getMonth() !== calendarDate.getMonth();
            calendarWeek.push(this.createCalendarItem(calendarDay, placeholder));

            calendarDay = calendarDay.addDays(1);
        }

        if (calendarDay.getDay() <= 6 && calendarDay.getDay() !== 1) {
            while (calendarDay.getDay() !== 1) {
                calendarWeek.push(this.createCalendarItem(calendarDay, true));

                calendarDay = calendarDay.addDays(1);
            }
        }

        if (calendarWeek.length === 8) {
            let key = calendarWeek.map(value => value.key?.toString().substring(11, 21)).join(",");
            calendarTable.push(<div className={Styles.calendarWeek} key={key}>
                {calendarWeek}
            </div>);

            calendarWeek = [];
        }

        this.setState({ calendar: calendarTable });
    }

    createCalendarItem = (dateOfDay: Date, placeholder: boolean) => {
        let className = Styles.calendarItem;

        if (new Date().isSameDate(dateOfDay)) {
            className = `${className} ${Styles.today}`
        }

        if (placeholder) {
            className = `${className} ${Styles.placeholder}`
        }

        let birthday: Array<IBirthdayData_ID> = [];

        if (this.props.birthdayData !== undefined) {
            birthday = this.props.birthdayData.filter((value) => {
                return value.date.getDate() === dateOfDay.getDate() && value.date.getMonth() === dateOfDay.getMonth();
            });
        }

        let birthdayJSX: Array<JSX.Element> = birthday.map((value) => {
            let className = "";
            if (value.id === this.props.stateSelectedID) {
                className = Styles.selected;
            }

            return <div className={className} onClick={() => this.showBirthdayData(value.id, value)} key={`BIRTHDAY${value.id}`}>
                <p>{value.name}</p>
            </div>
        });

        return <div className={className} key={`CALENDARDAY${dateOfDay.toISOString()}`}>
            <p className={Styles.calendarDay}>{dateOfDay.getDate().toString()}</p>
            <div>
                {birthdayJSX}
            </div>
        </div>;
    }

    birthdayDataUnmount = () => {
        if (birthdayDataRoot !== undefined) {
            birthdayDataRoot.unmount();
            birthdayDataRoot = undefined;
        }

        this.props.setStateSelectedID(undefined);
    }

    addBirthdayData = () => {
        this.props.setStateSelectedID(undefined);

        if (birthdayDataRoot === undefined) {
            const birthdayDataContainer = document.getElementById("birthdayData");
            birthdayDataRoot = createRoot(birthdayDataContainer!);
        }
        birthdayDataRoot.render(<div id={Styles.birthdayDataBackground}>
            <Add reload={this.props.reloadBirthdayManager} cancel={this.birthdayDataUnmount} show={this.showBirthdayData} />
        </div>);
    }

    showBirthdayData = async (id: number, data?: IBirthdayData_ID) => {
        if (id === -1) {
            this.birthdayDataUnmount();
            this.props.setStateSelectedID(undefined);
            return;
        }
        this.props.setStateSelectedID(id);

        let birthdayData = data;
        if (birthdayData === undefined) {
            birthdayData = await getBirthdayData(id);
        }

        if (birthdayDataRoot === undefined) {
            const birthdayDataContainer = document.getElementById("birthdayData");
            birthdayDataRoot = createRoot(birthdayDataContainer!);
        }
        birthdayDataRoot.render(<div id={Styles.birthdayDataBackground}>
            <Show birthdayData={birthdayData} edit={this.editBirthdayData} close={this.birthdayDataUnmount} />
        </div>);
    }

    editBirthdayData = async (id: number, data?: IBirthdayData_ID) => {
        if (id === -1) {
            this.birthdayDataUnmount();
            this.props.setStateSelectedID(undefined);
            return;
        }
        this.props.setStateSelectedID(id);

        let birthdayData = data;
        if (birthdayData === undefined) {
            birthdayData = await getBirthdayData(id);
        }

        if (birthdayDataRoot === undefined) {
            const birthdayDataContainer = document.getElementById("birthdayData");
            birthdayDataRoot = createRoot(birthdayDataContainer!);
        }
        birthdayDataRoot.render(<div id={Styles.birthdayDataBackground}>
            <Edit reload={this.props.reloadBirthdayManager} birthdayData={birthdayData} show={this.showBirthdayData} />
        </div>);
    }

    switchToList = () => {
        this.birthdayManager.view = "list";
        window.sessionStorage.setItem("birthdayManager", JSON.stringify(this.birthdayManager));

        this.props.reloadBirthdayManager();
    }

    render() {
        return (
            <>
                <div id={Styles.headlineNavigation}>
                    <div id={Styles.buttons}>
                        <IconButton text={"Liste"} imgSrc={list} imgAlt={"list"} onClick={this.switchToList} />
                        <div />
                        <IconButton text={"Heute"} imgSrc={calendarToday} imgAlt={"calendarToday"} onClick={this.resetDate} />
                    </div>

                    <div id={Styles.month}>
                        <div className={Styles.arrows} onClick={() => this.calendarForward(1)}>
                            <img src={singleArrowUp} alt={"singleArrowUp"} />
                        </div>
                        <h3 className={Styles.headlineCalendar}>{months[this.state.date.month - 1]}</h3>
                        <div className={Styles.arrows} onClick={() => this.calendarBackward(1)}>
                            <img src={singleArrowDown} alt={"singleArrowDown"} />
                        </div>
                    </div>

                    <div id={Styles.year}>
                        <div className={Styles.arrows} onClick={() => this.calendarForward(12)}>
                            <img src={singleArrowUp} alt={"singleArrowUp"} />
                        </div>
                        <h3 className={Styles.headlineCalendar}>{this.state.date.year}</h3>
                        <div className={Styles.arrows} onClick={() => this.calendarBackward(12)}>
                            <img src={singleArrowDown} alt={"singleArrowDown"} />
                        </div>
                    </div>
                </div>

                <div id={Styles.calendar}>
                    {this.state.calendar}
                </div>

                <div id={Styles.bottomButton}>
                    <button onClick={this.addBirthdayData}>
                        Hinzufügen
                    </button>
                </div>

                <div id={Styles.birthdayData}>
                    <div id={"birthdayData"} />
                </div>
            </>
        );
    }
}

export default Calendar