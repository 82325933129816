import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import Navigation from "../modules/Navigation"

import Styles from "../css/Home.module.css";
import FontStyles from "../css/Font.module.css";
import SetPasswordManagerKey from "../modules/home/SetPasswordManagerKey";
import { IUserData } from "../utils/userManager";
import EmailNotVerifiedBanner from "../modules/home/EmailNotVerifiedBanner";

class Home extends React.Component {
    private userData: IUserData = JSON.parse(window.sessionStorage.getItem("userData") || "");
    private popUpRoot: ReactDOM.Root | undefined;
    private emailNotVerifiedBannerRoot: ReactDOM.Root | undefined;

    constructor(props: {}) {
        super(props);
        document.title = "Startseite";

        let token = window.sessionStorage.getItem("token");

        if (token === null) {
            window.sessionStorage.clear();
            document.location.href = "/error";
        }
    }

    componentDidMount = () => {
        if (window.sessionStorage.getItem("temp") !== null) {
            let hasPMK: { hasPMK: boolean } = JSON.parse(window.sessionStorage.getItem("temp") || "");

            if (!hasPMK.hasPMK) {
                this.needToSetPasswordManagerKey();
            } else {
                window.sessionStorage.removeItem("temp");
            }
        }

        const emailNotVerifiedBannerContainer = document.getElementById("emailNotVerifiedBanner");
        if (!this.userData.emailVerified) {
            if (this.emailNotVerifiedBannerRoot === undefined) {
                this.emailNotVerifiedBannerRoot = createRoot(emailNotVerifiedBannerContainer!);
                this.emailNotVerifiedBannerRoot.render(<EmailNotVerifiedBanner userID={this.userData.id} emailVerified={this.userData.emailVerified} reload={this.refreshEmailNotVerifiedBanner} />);
            }
        }
    }

    needToSetPasswordManagerKey = () => {
        const popUpContainer = document.getElementById("popUp");
        if (this.popUpRoot === undefined) {
            this.popUpRoot = createRoot(popUpContainer!);
            this.popUpRoot.render(<SetPasswordManagerKey reload={this.removeSetPasswordManagerKey} />);
        }
    }

    removeSetPasswordManagerKey = () => {
        window.sessionStorage.removeItem("temp");

        if (this.popUpRoot !== undefined) {
            this.popUpRoot.render(<></>);
        }
    }

    refreshEmailNotVerifiedBanner = (emailVerified: boolean) => {
        if (emailVerified) {
            if (this.emailNotVerifiedBannerRoot !== undefined) {
                this.emailNotVerifiedBannerRoot.unmount();
            }
        }
    }

    render() {
        return (
            <>
                <Navigation />
                <div id={Styles.main}>
                    <div>
                        <h1 id={Styles.headline}><b>Web</b>Database</h1>
                        <h3 className={FontStyles.whiteColor}>Willkommen {this.userData.firstName} {this.userData.lastName}</h3>
                    </div>
                    <div id={"emailNotVerifiedBanner"} />
                </div>
                <div id={"popUp"} />
            </>
        );
    }
}

export default Home;