import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import { updatePassword } from "../../utils/userManager";

import Styles from "./ChangePassword.module.css";
import PasswordField, { IStatePasswordField, InitialStatePasswordField } from "../inputFields/PasswordField";

interface IState {
    oldPassword: IStatePasswordField
    newPassword: IStatePasswordField
    newPasswordRepeat: IStatePasswordField
}

class ChangePassword extends React.Component<{}, IState> {
    private popUpRoot!: ReactDOM.Root | undefined;
    private errorBoxRoot!: ReactDOM.Root | undefined;
    private successBoxRoot!: ReactDOM.Root | undefined;

    constructor(props: {}) {
        super(props);

        this.state = {
            oldPassword: InitialStatePasswordField,
            newPassword: InitialStatePasswordField,
            newPasswordRepeat: InitialStatePasswordField
        }
    }

    savePassword = async () => {
        let oldPassword = this.state.oldPassword.value;
        let newPassword = this.state.newPassword.value;
        let newPasswordRepeat = this.state.newPasswordRepeat.value;

        if (oldPassword.length !== 0 || newPassword.length !== 0 || newPasswordRepeat.length !== 0) {
            if (newPassword === newPasswordRepeat) {
                let responseData = await updatePassword({
                    old_password: oldPassword,
                    new_password: newPassword
                });

                if (responseData !== undefined) {
                    if (responseData === "success") {
                        if (this.errorBoxRoot !== undefined) {
                            this.errorBoxRoot.unmount();
                            this.errorBoxRoot = undefined;
                        }

                        this.setState({ oldPassword: InitialStatePasswordField, newPassword: InitialStatePasswordField, newPasswordRepeat: InitialStatePasswordField });

                        const successBoxContainer = document.getElementById("successBoxPassword");
                        if (this.successBoxRoot === undefined) {
                            this.successBoxRoot = createRoot(successBoxContainer!);
                        }

                        this.successBoxRoot.render(
                            <div id={Styles.successMessageBox}>
                                <p key="successMessage">Erfolgreich geändert!</p>
                            </div>
                        );

                        await new Promise((resolve) => setTimeout(() => resolve({}), 1500));

                        this.successBoxRoot.unmount();
                        this.successBoxRoot = undefined;
                    } else if (responseData === "forbidden") {
                        const errorBoxContainer = document.getElementById("errorBoxPassword");
                        if (this.errorBoxRoot === undefined) {
                            this.errorBoxRoot = createRoot(errorBoxContainer!);
                        }

                        this.errorBoxRoot.render(
                            <div id={Styles.errorMessageBox}>
                                <p key="errorMessage">Das alte Passwort ist falsch!</p>
                            </div>
                        );
                    } else if (responseData === "equalsPasswordManagerKey") {
                        const errorBoxContainer = document.getElementById("errorBoxPassword");
                        if (this.errorBoxRoot === undefined) {
                            this.errorBoxRoot = createRoot(errorBoxContainer!);
                        }

                        this.errorBoxRoot.render(
                            <div id={Styles.errorMessageBox}>
                                <p key="errorMessage">Das neue Passwort muss sich vom Schlüssel für die Passwortverwaltung unterscheiden!</p>
                            </div>
                        );
                    }
                } else {
                    const popUpContainer = document.getElementById("popUp");
                    if (this.popUpRoot === undefined) {
                        this.popUpRoot = createRoot(popUpContainer!);
                    }

                    this.popUpRoot.render(
                        <div id={Styles.popUpBackground}>
                            <div id={Styles.errorPopUp} className={Styles.popUp}>
                                <h3>Fehler</h3>
                                <hr />
                                <p>Das Speichern der Daten ist fehlgeschlagen!</p>
                                <button onClick={this.close}>Schließen</button>
                            </div>
                        </div>
                    );
                }
            } else {
                const errorBoxContainer = document.getElementById("errorBoxPassword");
                if (this.errorBoxRoot === undefined) {
                    this.errorBoxRoot = createRoot(errorBoxContainer!);
                }

                this.errorBoxRoot.render(
                    <div id={Styles.errorMessageBox}>
                        <p key="errorMessage">Die neuen Passwörter müssen übereinstimmen!</p>
                    </div>
                );
            }
        }
    }

    close = () => {
        if (this.popUpRoot !== undefined) {
            this.popUpRoot.unmount();
            this.popUpRoot = undefined;
        }
    }

    render() {
        return (
            <div id={Styles.changePassword}>
                <h4>Passwort ändern</h4>

                <div id="errorBoxPassword" />
                <div>
                    <h6>Altes Passwort</h6>
                    <PasswordField state={this.state.oldPassword} setState={(oldPassword: IStatePasswordField) => this.setState({ oldPassword: oldPassword })} />

                    <h6>Neues Passwort</h6>
                    <PasswordField state={this.state.newPassword} setState={(newPassword: IStatePasswordField) => this.setState({ newPassword: newPassword })} />

                    <h6>Neues Passwort wiederholen</h6>
                    <PasswordField state={this.state.newPasswordRepeat} setState={(newPasswordRepeat: IStatePasswordField) => this.setState({ newPasswordRepeat: newPasswordRepeat })} />

                    <div id="successBoxPassword" />
                    <button onClick={this.savePassword}>Ändern</button>
                </div>
            </div>
        );
    }
}

export default ChangePassword;