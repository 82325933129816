import React from "react";

import Styles from "./TextArea.module.css";

interface IProps {
    state: IStateTextArea
    setState: Function
    maxLength: number
    value?: string
    required?: boolean
    tooltipRequired?: boolean
    tooltip?: string
    onKeyUp?: any
    onChange?: Function
}

export interface IStateTextArea {
    value: string
    required: boolean
    tooltip: string
    className: string
}

export const InitialStateTextArea = {
    value: "",
    required: false,
    tooltip: "",
    className: ""
}

class TextArea extends React.Component<IProps> {
    private readonly textInput: React.RefObject<HTMLTextAreaElement>;
    private readonly text: React.RefObject<HTMLParagraphElement>;

    static defaultProps: Partial<IProps> = {
        value: "",
        required: false,
        tooltipRequired: false,
        tooltip: ""
    };

    constructor(props: IProps) {
        super(props);

        this.props.setState({
            value: this.props.value,
            required: false,
            tooltip: this.props.tooltip,
            className: ""
        });

        this.textInput = React.createRef();
        this.text = React.createRef();

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        let inputElement: HTMLTextAreaElement = event.target;

        if (inputElement.scrollHeight > inputElement.clientHeight) {
            this.props.setState({
                value: this.props.state.value
            });
        } else {
            if (this.props.required && this.props.tooltipRequired) {
                if (inputElement.value.length === 0) {
                    this.props.setState({
                        value: inputElement.value,
                        required: true,
                        tooltip: this.props.tooltip,
                        className: Styles.tooltipRequired
                    });
                } else {
                    this.props.setState({
                        value: inputElement.value,
                        required: false,
                        className: ""
                    });
                }
            } else if (this.props.required) {
                if (inputElement.value.length === 0) {
                    this.props.setState({
                        value: inputElement.value,
                        required: true
                    });
                } else {
                    this.props.setState({
                        value: inputElement.value,
                        required: false,
                        className: ""
                    });
                }
            } else {
                this.props.setState({
                    value: inputElement.value,
                    required: false,
                    className: ""
                });
            }

            if (this.props.onChange) {
                this.props.onChange(inputElement.value);
            }
        }
    }

    render() {

        return (
            <label id={Styles.inputLabel} className={this.props.state.className} data-tip={this.props.state.tooltip}>
                <textarea autoComplete={"off"} maxLength={this.props.maxLength} rows={5} wrap="hard" onChange={this.handleChange} onKeyUp={this.props.onKeyUp} value={this.props.state.value} required={this.props.state.required} ref={this.textInput} />
            </label>
        );
    }
}

export default TextArea;