import { IUserDataAdmin } from "../../utils/userManager";

import Styles from "./Show.module.css";

import arrowLeft from "../../icons/arrowLeft.svg";
import pen from "../../icons/pen.svg";

interface IProps {
    userData: IUserDataAdmin
    setStateSelectedID: Function
    edit: Function
    close: Function
}

function Show(props: IProps) {
    return (
        <div id={Styles.userDataBackground}>
            <div id={Styles.userData}>
                <div id={Styles.navigationIcons}>
                    <img id={Styles.arrowLeftIcon} title={"Schließen"} onClick={() => props.close()} src={arrowLeft} alt={"arrowLeft"} />
                    <img id={Styles.editIcon} title={"Bearbeiten"} onClick={() => props.edit(props.userData.id, props.userData)} src={pen} alt={"edit"} />
                </div>
                <hr />
                <div id={Styles.headline}>
                    <h3>{props.userData.username}</h3>
                </div>
                <div id={Styles.data}>
                    <h6>E-Mail Adresse</h6>
                    <p>{props.userData.email}</p>

                    <h6>Vorname</h6>
                    <p>{props.userData.firstName}</p>

                    <h6>Nachname</h6>
                    <p>{props.userData.lastName}</p>

                    <h6>Rechte</h6>
                    <p className={Styles.editable}>{props.userData.right ? "Ja" : "Nein"}</p>

                    <h6>Status</h6>
                    <p className={Styles.editable}>{props.userData.active ? "Aktiv" : "Inaktiv"}</p>
                </div>
            </div>
        </div>
    );
}

export default Show;