import { useState } from "react";

import HeadlineTextField, { IStateTextField, InitialStateTextField } from "../inputFields/HeadlineTextField";

import { calculateAge, createBirthdayData } from "../../utils/birthdayManager";

import Styles from "./Add.module.css";

import arrowLeft from "../../icons/arrowLeft.svg";
import save from "../../icons/save.svg";
import DateField, { IStateDateField, InitialStateDateField } from "../inputFields/DateField";

interface IProps {
    reload: Function
    cancel: Function
    show: Function
}

function Add(props: IProps) {
    const [stateName, setStateName] = useState<IStateTextField>(InitialStateTextField);
    const [stateDate, setStateDate] = useState<IStateDateField>(InitialStateDateField);
    const [stateAge, setStateAge] = useState<any>(0);

    async function addData() {
        if (stateName.value.length !== 0) {
            let birthdayData = {
                name: stateName.value,
                date: new Date(stateDate.year.value, stateDate.month.value, stateDate.day.value)
            }

            let updatedBirthdayData = await createBirthdayData(birthdayData);
            if (updatedBirthdayData !== undefined) {
                props.show(updatedBirthdayData.id, updatedBirthdayData);
                props.reload();
            }
        }
    }

    function changeAge(date: IStateDateField) {
        let day = date.day.value;
        let month = date.month.value;
        let year = date.year.value;

        setStateAge(calculateAge(day, month, year));
    }

    return (
        <div id={Styles.birthdayData}>
            <div id={Styles.navigationIcons}>
                <img id={Styles.arrowLeftIcon} title={"Abbrechen"} onClick={() => props.cancel()} src={arrowLeft} alt={"arrowLeft"} />
                <h2 id={Styles.headline}><b>Daten</b> hinzufügen</h2>
                <img id={Styles.saveIcon} title={"Speichen"} onClick={addData} src={save} alt={"save"} />
            </div>
            <hr />
            <div id={Styles.headline}>
                <HeadlineTextField state={stateName} setState={setStateName} placeholder={"Beispiel: Max Mustermann"} required={true} tooltipRequired={true} tooltip={"Dies ist ein Pflichtfeld!"} />
            </div>
            <div id={Styles.data}>
                <h6>Geburtsdatum</h6>
                <DateField state={stateDate} setState={setStateDate} onChange={changeAge} />

                <h6>Alter</h6>
                <p>{stateAge}</p>
            </div>
        </div>
    );
}

export default Add;