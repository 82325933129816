import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import { updateName } from "../../utils/userManager";

import Styles from "./ChangeName.module.css";
import TextField, { IStateTextField, InitialStateTextField } from "../inputFields/TextField";

interface IState {
    firstName: IStateTextField
    lastName: IStateTextField
}

interface IProps {
    reload: Function
}

class ChangeName extends React.Component<IProps, IState> {
    private popUpRoot!: ReactDOM.Root | undefined;
    private successBoxRoot!: ReactDOM.Root | undefined;

    constructor(props: IProps) {
        super(props);

        this.state = {
            firstName: InitialStateTextField,
            lastName: InitialStateTextField
        }
    }

    saveName = async () => {
        let firstName = this.state.firstName.value;
        let lastName = this.state.lastName.value;

        if (firstName.length !== 0 || lastName.length !== 0) {
            let responseData = await updateName({
                firstName: firstName,
                lastName: lastName
            });

            if (responseData !== undefined) {
                this.setState({ firstName: InitialStateTextField, lastName: InitialStateTextField });

                this.props.reload(responseData);

                const successBoxContainer = document.getElementById("successBoxName");
                if (this.successBoxRoot === undefined) {
                    this.successBoxRoot = createRoot(successBoxContainer!);
                }

                this.successBoxRoot.render(
                    <div id={Styles.successMessageBox}>
                        <p key="successMessage">Erfolgreich geändert!</p>
                    </div>
                );

                await new Promise((resolve) => setTimeout(() => resolve({}), 1500));

                this.successBoxRoot.unmount();
                this.successBoxRoot = undefined;
            } else {
                const popUpContainer = document.getElementById("popUp");
                if (this.popUpRoot === undefined) {
                    this.popUpRoot = createRoot(popUpContainer!);
                }

                this.popUpRoot.render(
                    <div id={Styles.popUpBackground}>
                        <div id={Styles.errorPopUp} className={Styles.popUp}>
                            <h3>Fehler</h3>
                            <hr />
                            <p>Das Speichern der Daten ist fehlgeschlagen!</p>
                            <button onClick={this.close}>Schließen</button>
                        </div>
                    </div>
                );
            }
        }
    }

    close = () => {
        if (this.popUpRoot !== undefined) {
            this.popUpRoot.unmount();
            this.popUpRoot = undefined;
        }
    }

    render() {
        return (
            <div id={Styles.changeName}>
                <h4>Vor- und Nachnamen ändern</h4>

                <div>
                    <h6>Neuer Vorname:</h6>
                    <TextField state={this.state.firstName} setState={(firstName: IStateTextField) => this.setState({ firstName: firstName })} />

                    <h6>Neuer Nachname:</h6>
                    <TextField state={this.state.lastName} setState={(lastName: IStateTextField) => this.setState({ lastName: lastName })} />

                    <div id="successBoxName" />
                    <button onClick={this.saveName}>Ändern</button>
                </div>
            </div>
        );
    }
}

export default ChangeName;