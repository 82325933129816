import React from 'react';

import Navigation from "../modules/Navigation"
import Calendar from '../modules/birthdayManager/Calendar';
import List from '../modules/birthdayManager/List';

import { IUserData } from '../utils/userManager';

import Styles from "../css/BirthdayManager.module.css"
import { IBirthdayData_ID, getAllBirthdayData } from '../utils/birthdayManager';

interface IState {
    birthdayData: Array<IBirthdayData_ID>
    selectedID: number | undefined
    view: JSX.Element
}

export interface IBirthdayManager {
    view: string
    date: {
        month: number
        year: number
    }
}

class BirthdayManager extends React.Component<{}, IState> {
    private userData!: IUserData;
    private birthdayManager: IBirthdayManager;

    constructor(props: {}) {
        super(props);

        document.title = "Geburtstagskalender";
        this.userData = JSON.parse(window.sessionStorage.getItem("userData") || "");

        if (this.userData === null) {
            window.sessionStorage.clear();
            document.location.href = "/error";
        }

        if (window.sessionStorage.getItem("birthdayManager") !== null) {
            this.birthdayManager = JSON.parse(window.sessionStorage.getItem("birthdayManager") || "");
        } else {
            this.birthdayManager = {
                view: "calendar",
                date: {
                    month: new Date().getMonth() + 1,
                    year: new Date().getFullYear()
                }
            }
        }

        this.state = {
            birthdayData: [],
            selectedID: undefined,
            view: <></>
        }

        window.sessionStorage.setItem("birthdayManager", JSON.stringify(this.birthdayManager));
    }

    componentDidMount = async () => {
        this.loadData();
    }

    setSelectedID = (id: number) => {
        this.setState({ selectedID: id });
        this.loadView(this.state.birthdayData, id);
    }

    loadData = async () => {
        let birthdayData = await getAllBirthdayData();

        this.setState({ birthdayData });
        this.loadView(birthdayData, this.state.selectedID);
    }

    loadView = async (birthdayData: Array<IBirthdayData_ID>, selectedID: number | undefined) => {
        this.birthdayManager = JSON.parse(window.sessionStorage.getItem("birthdayManager") || "");

        if (this.birthdayManager.view === "calendar") {
            this.setState({ view: <Calendar birthdayData={birthdayData} stateSelectedID={selectedID} setStateSelectedID={this.setSelectedID} reloadBirthdayManager={this.loadData} /> });
        } else if (this.birthdayManager.view === "list") {
            this.setState({ view: <List birthdayData={birthdayData} stateSelectedID={selectedID} setStateSelectedID={this.setSelectedID} reloadBirthdayManager={this.loadData} /> });
        } else {
            this.setState({ view: <></> });
        }
    }

    render = () => {
        return (
            <>
                <Navigation />
                <div id={Styles.main}>
                    <h2 id={Styles.headline}><b>Geburtstags</b>kalender</h2>
                    {this.state.view}
                </div>

                <div id={"popUp"} />
            </>
        );
    }
}

export default BirthdayManager;