import React from "react";

import Styles from "./PasswordField.module.css";
import iconEye from "../../icons/eye.svg";
import iconEyeSlash from "../../icons/eyeSlash.svg";

interface IProps {
    state: IStatePasswordField
    setState: Function
    onKeyUp?: any
}

interface IState {
    type: string
    className: string
}

export interface IStatePasswordField {
    value: string
    required: boolean
    className: string
}

export const InitialStatePasswordField = {
    value: "",
    required: false,
    className: ""
}

class PasswordField extends React.Component<IProps, IState> {
    private readonly passwordInput: React.RefObject<HTMLInputElement>;
    private readonly showIndicator: React.RefObject<HTMLElement>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            type: "password",
            className: Styles.eye
        }

        this.props.setState(InitialStatePasswordField);

        this.passwordInput = React.createRef();
        this.showIndicator = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.togglePasswordShow = this.togglePasswordShow.bind(this);
    }

    handleChange(event: any) {
        let inputElement: HTMLInputElement = event.target;

        if (inputElement.value.length === 0) {
            this.props.setState({
                value: inputElement.value,
                required: true,
                className: Styles.tooltipRequired
            });
        } else {
            this.props.setState({
                value: inputElement.value,
                required: false,
                className: ""
            });
        }
    }

    togglePasswordShow() {
        const type = this.state.type === "password" ? "text" : "password";
        const className = this.state.className === Styles.eye ? Styles.eyeSlash : Styles.eye;

        this.setState({
            type: type,
            className: className
        });
    }

    render() {
        return (
            <label id={Styles.inputLabel} className={this.props.state.className}>
                <input autoComplete="off" type={this.state.type} size={40} maxLength={250} onChange={this.handleChange} onKeyUp={this.props.onKeyUp} value={this.props.state.value} required={this.props.state.required} ref={this.passwordInput} />
                <i id={Styles.togglePasswordShow} className={this.state.className} onClick={this.togglePasswordShow} ref={this.showIndicator}>
                    <img className={Styles.eye} src={iconEye} alt="eye" />
                    <img className={Styles.eyeSlash} src={iconEyeSlash} alt="eyeSlash" />
                </i>
            </label>
        );
    }
}

export default PasswordField;
