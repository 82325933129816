import { useState } from "react";

import HeadlineTextField from "../inputFields/HeadlineTextField";
import TextField, { IStateTextField, InitialStateTextField } from "../../modules/inputFields/TextField";
import EmailField from "../inputFields/EmailField";

import { createPasswordData } from "../../utils/passwordManager";

import Styles from "./Add.module.css";

import arrowLeft from "../../icons/arrowLeft.svg";
import save from "../../icons/save.svg";
import TextArea, { InitialStateTextArea } from "../inputFields/TextArea";

interface IProps {
    reloadList: Function
    setStateSelectedID: Function
    cancel: Function
    show: Function
}

function Add(props: IProps) {
    const [statePlatform, setStatePlatform] = useState<IStateTextField>(InitialStateTextField);
    const [stateName, setStateName] = useState<IStateTextField>(InitialStateTextField);
    const [stateEmail, setStateEmail] = useState<IStateTextField>(InitialStateTextField);
    const [stateUsername, setStateUsername] = useState<IStateTextField>(InitialStateTextField);
    const [statePassword, setStatePassword] = useState<IStateTextField>(InitialStateTextField);
    const [stateNotes, setStateNotes] = useState<IStateTextField>(InitialStateTextArea);

    async function addData() {
        if (statePlatform.value.length !== 0) {
            let passwordData = {
                platform: statePlatform.value,
                name: stateName.value,
                email: stateEmail.value,
                username: stateUsername.value,
                password: statePassword.value,
                notes: stateNotes.value
            }

            let updatedPasswordData = await createPasswordData(passwordData);
            if (updatedPasswordData !== undefined) {
                props.show(updatedPasswordData.id, updatedPasswordData);
                props.setStateSelectedID(updatedPasswordData.id);
                props.reloadList();
            }
        }
    }

    return (
        <div id={Styles.passwordDataBackground}>
            <div id={Styles.passwordData}>
                <div id={Styles.navigationIcons}>
                    <img id={Styles.arrowLeftIcon} title={"Abbrechen"} onClick={() => props.cancel()} src={arrowLeft} alt={"arrowLeft"} />
                    <h2 id={Styles.headline}><b>Daten</b> hinzufügen</h2>
                    <img id={Styles.saveIcon} title={"Speichen"} onClick={addData} src={save} alt={"save"} />
                </div>
                <hr />
                <div id={Styles.headline}>
                    <HeadlineTextField state={statePlatform} setState={setStatePlatform} placeholder={"Beispiel: Google Konto"} required={true} tooltipRequired={true} tooltip={"Dies ist ein Pflichtfeld!"} />
                </div>
                <div id={Styles.data}>
                    <h6>Name</h6>
                    <TextField state={stateName} setState={setStateName} />

                    <h6>E-Mail</h6>
                    <EmailField state={stateEmail} setState={setStateEmail} />

                    <h6>Benutzername</h6>
                    <TextField state={stateUsername} setState={setStateUsername} />

                    <h6>Passwort</h6>
                    <TextField state={statePassword} setState={setStatePassword} />

                    <h6 id={Styles.notes}>Notizen</h6>
                        <TextArea state={stateNotes} setState={setStateNotes} maxLength={127}/>
                </div>
            </div>
        </div>
    );
}

export default Add;