import React from "react";

import SearchField, { IStateSearchField, InitialStateSearchField } from "../inputFields/SearchField";
import { IPasswordData_ID } from "../../utils/passwordManager";

import Styles from "./List.module.css";

import arrowRight from "../../icons/singleArrowRight.svg";

interface IProps {
    id: string
    passwordData: Array<IPasswordData_ID> | undefined
    stateSelectedID: number | undefined
    setStateSelectedID: Function
    addPasswordData: Function
    show: Function
}

interface IState {
    searchText: IStateSearchField
    passwordManagerList: Array<JSX.Element>
}

class List extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            searchText: InitialStateSearchField,
            passwordManagerList: []
        };
    }

    componentDidUpdate = (prevProps: any) => {
        if (prevProps.stateSelectedID !== this.props.stateSelectedID) {
            this.loadList();
        }

        if (prevProps.passwordData !== this.props.passwordData) {
            this.loadList();
        }
    }

    loadList = () => {
        let passwordData = this.props.passwordData;

        if (passwordData !== undefined) {
            let listJSX: Array<JSX.Element> = [];
            if (passwordData.length > 0) {
                passwordData.forEach((value) => {
                    if (value.platform.toLowerCase().includes(this.state.searchText.value.toLowerCase()) || value.id === this.props.stateSelectedID) {
                        if (value.id === this.props.stateSelectedID) {
                            listJSX.push(
                                <div className={`${Styles.listElement} ${Styles.selectedElement}`} key={`PASSWORD_DATA_ID${value.id}`} onClick={() => this.deselectElement()}>
                                    <img src={arrowRight} alt={"arrowRight"} />
                                    <p>{value.platform}</p>
                                    <hr />
                                </div>
                            );
                        } else {
                            listJSX.push(
                                <div className={Styles.listElement} key={`PASSWORD_DATA_ID${value.id}`} onClick={() => this.selectElement(value.id)}>
                                    <img src={arrowRight} alt={"arrowRight"} />
                                    <p>{value.platform}</p>
                                    <hr />
                                </div>
                            );
                        }
                    }
                });
            }

            this.setState({ passwordManagerList: listJSX });
        }
    }

    selectElement = (id: number) => {
        this.props.setStateSelectedID(id);
        this.props.show(id);
    }

    deselectElement = () => {
        this.props.setStateSelectedID(undefined);
        this.props.show(-1);
    }

    add = () => {
        this.props.setStateSelectedID(undefined);
        this.props.addPasswordData();
    }

    render = () => {
        return (<div id={this.props.id}>
            <SearchField state={this.state.searchText} setState={(stateSearchText: IStateSearchField) => this.setState({ searchText: stateSearchText })} onKeyUp={this.loadList} />
            <div id={Styles.passwordManagerList}>
                <div>
                    <hr id={Styles.topBorder} />
                    <div>
                        {this.state.passwordManagerList}
                    </div>
                    <hr id={Styles.bottomBorder} />
                </div>
            </div>
            <button onClick={() => this.add()}>Hinzufügen</button>
        </div>
        );
    }
}

export default List;