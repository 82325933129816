import React from "react";

import Styles from "./HeadlineTextField.module.css";

interface IProps {
    state: IStateTextField
    setState: Function
    value?: string
    placeholder?: string
    required?: boolean
    tooltipRequired?: boolean
    tooltip?: string
    onKeyUp?: any
    onChange?: Function
}

export interface IStateTextField {
    value: string
    required: boolean
    tooltip: string
    className: string
}

export const InitialStateTextField = {
    value: "",
    required: false,
    tooltip: "",
    className: ""
}

class HeadlineTextField extends React.Component<IProps> {
    private readonly textInput: React.RefObject<HTMLInputElement>;

    static defaultProps: Partial<IProps> = {
        value: "",
        placeholder: "",
        required: false,
        tooltipRequired: false,
        tooltip: ""
    };

    constructor(props: IProps) {
        super(props);

        this.props.setState({
            value: this.props.value,
            required: false,
            tooltip: this.props.tooltip,
            className: ""
        });

        this.textInput = React.createRef();

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        let inputElement: HTMLInputElement = event.target;

        if (this.props.required && this.props.tooltipRequired) {
            if (inputElement.value.length === 0) {
                this.props.setState({
                    value: inputElement.value,
                    required: true,
                    tooltip: this.props.tooltip,
                    className: Styles.tooltipRequired
                });
            } else {
                this.props.setState({
                    value: inputElement.value,
                    required: false,
                    className: ""
                });
            }
        } else if (this.props.required) {
            if (inputElement.value.length === 0) {
                this.props.setState({
                    value: inputElement.value,
                    required: true
                });
            } else {
                this.props.setState({
                    value: inputElement.value,
                    required: false,
                    className: ""
                });
            }
        } else {
            this.props.setState({
                value: inputElement.value,
                required: false,
                className: ""
            });
        }

        if (this.props.onChange) {
            this.props.onChange(inputElement.value);
        }
    }

    render() {
        return (
            <label id={Styles.inputLabel} className={this.props.state.className} data-tip={this.props.state.tooltip}>
                <input autoComplete={"off"} type={"text"} size={40} maxLength={250} onChange={this.handleChange} onKeyUp={this.props.onKeyUp} placeholder={this.props.placeholder} value={this.props.state.value} required={this.props.state.required} ref={this.textInput}/>
            </label>
        );
    }
}

export default HeadlineTextField;