import React, { RefObject } from "react";
import PageFooter from "./Footer";

import Styles from "./Navigation.module.css";
import { IUserData } from "../utils/userManager";

class Navigation extends React.Component {
    private userData!: IUserData;
    private navigation!: RefObject<HTMLDivElement>;
    private navigationButton!: RefObject<HTMLDivElement>;

    constructor(props: {}) {
        super(props);

        this.userData = JSON.parse(window.sessionStorage.getItem("userData") || "");
        this.navigation = React.createRef<HTMLDivElement>();
        this.navigationButton = React.createRef<HTMLDivElement>();
    }

    async componentDidMount() {
        const pathname = window.location.pathname;

        if (pathname !== "/passwordManager") {
            window.sessionStorage.removeItem("passwordManagerKey");
            window.sessionStorage.removeItem("passwordManagerToken");
            window.sessionStorage.removeItem("passwordAutoLogOut");
        }

        if (pathname !== "/userManager") {
            window.sessionStorage.removeItem("userManagerToken");
            window.sessionStorage.removeItem("userAutoLogOut");
        }
    }

    showNavigation = () => {
        this.navigation.current?.classList.toggle(Styles.open);
        this.navigationButton.current?.classList.toggle(Styles.open);
    }

    render = () => {
        return (
            <>
                <div id={Styles.sideBar}>
                    <div id={Styles.showNavigation} onClick={() => this.showNavigation()} ref={this.navigationButton}>
                        <div className={Styles.burgerMenuBtn} />
                    </div>
                </div>
                <div id={Styles.navigation} ref={this.navigation}>
                    <div id={Styles.topNavigation}>
                        <h3 id={Styles.logo}><b>Web</b>Database</h3>
                        <nav>
                            <ul>
                                <hr id={Styles.seperator} />
                                <NavigationListItem title={"Startseite"} pathList={["/home"]} isShow={true} id={""} />
                                <NavigationListItem title={"Passwortverwaltung"} pathList={["/passwordManager", "/passwordManagerSignIn"]} isShow={true} id={""} />
                                <NavigationListItem title={"Geburtstagskalender"} pathList={["/birthdayManager"]} isShow={true} id={""} />
                                <NavigationListItem title={"Benutzerverwaltung"} pathList={["/userManager", "/userManagerSignIn"]} isShow={this.userData.right} id={""} />
                                <NavigationListItem title={"Einstellungen"} pathList={["/settings"]} isShow={true} id={""} />
                                <NavigationListItem id={Styles.signOut} title={"Abmelden"} pathList={["/signOut"]} isShow={true} />
                            </ul>
                        </nav>
                    </div>
                    <PageFooter />
                </div>
            </>
        );
    }
}

const NavigationListItem = ({ id, title, pathList, isShow }: { id: string, title: string, pathList: Array<string>, isShow: boolean }) => {
    if (isShow) {
        const pathname = window.location.pathname;

        let isActive: boolean = false;
        pathList.forEach((path) => {
            if (!isActive) {
                isActive = pathname === path;
            }
        });

        return (
            <>
                <li id={id} title={title} className={isActive ? Styles.glow : ""} key={title}>
                    <a className={Styles.navigation} href={pathList[0]}>
                        <p>
                            {title}
                        </p>
                        <span />
                    </a>
                </li>
                <hr id={Styles.seperator} />
            </>
        );
    } else {
        return <></>;
    }
}

export default Navigation