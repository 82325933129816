import React from "react";

import Styles from "./CheckBox.module.css";
import check from "../../icons/check.svg";

interface IProps {
    state: IStateCheckBox
    setState: Function
    checked: boolean
    labelText: string
}

export interface IStateCheckBox {
    checked: boolean
}

export const InitialStateCheckBox = {
    checked: false
}

class CheckBox extends React.Component<IProps, IStateCheckBox> {
    constructor(props: IProps) {
        super(props);

        this.props.setState({ checked: this.props.checked });

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.props.setState({ checked: !this.props.state.checked });
    }

    render() {
        return (
            <label className={Styles.checkBox}>
                <input type={"checkbox"} checked={this.props.state.checked} onChange={this.handleChange} />
                <span>
                    <img src={check} alt={"Check"} />
                </span>
                <p>{this.props.labelText}</p>
            </label>
        )
    }
}

export default CheckBox;
