import React from 'react';
import ReactDOM, { createRoot } from "react-dom/client";

import Navigation from "../modules/Navigation"
import List from "../modules/passwordManager/List";
import Add from '../modules/passwordManager/Add';
import Show from '../modules/passwordManager/Show';
import Edit from '../modules/passwordManager/Edit';

import { IPasswordData_ID, getAllPasswordData, getPasswordData } from '../utils/passwordManager';
import { IUserData } from '../utils/userManager';

import Styles from "../css/PasswordManager.module.css"

let passwordDataRoot: ReactDOM.Root | undefined;

export interface IAutoLogOut {
    active: boolean
    endTimestamp: number
}

interface IState {
    selectedID: number | undefined
    autoLogOutText: string
    passwordData: Array<IPasswordData_ID>
}

class PasswordManager extends React.Component<{}, IState> {
    private userData!: IUserData;

    constructor(props: {}) {
        super(props);

        document.title = "Passwortverwaltung";
        this.userData = JSON.parse(window.sessionStorage.getItem("userData") || "");

        if (this.userData === null) {
            window.sessionStorage.clear();
            document.location.href = "/error";
        }

        this.state = {
            selectedID: undefined,
            autoLogOutText: "",
            passwordData: []
        }
    }

    componentDidMount = () => {
        this.startAutoLogOut();
    }

    loadList = () => {
        getAllPasswordData()
            .then(passwordData => this.setState({ passwordData }));
    }

    setSelectedID = (id: number) => {
        this.setState({ selectedID: id });
    }

    passwordDataUnmount = () => {
        if (passwordDataRoot !== undefined) {
            passwordDataRoot.unmount();
            passwordDataRoot = undefined;
        }
    }

    addPasswordData = () => {
        if (passwordDataRoot === undefined) {
            const passwordDataContainer = document.getElementById("passwordData");
            passwordDataRoot = createRoot(passwordDataContainer!);
        }
        passwordDataRoot.render(<Add reloadList={this.loadList} setStateSelectedID={this.setSelectedID} cancel={this.passwordDataUnmount} show={this.showPasswordData} />);
    }

    showPasswordData = async (id: number, data?: IPasswordData_ID) => {
        if (id === -1) {
            this.passwordDataUnmount();
            return;
        }

        let passwordData = data;
        if (passwordData === undefined) {
            passwordData = await getPasswordData(id);
        }

        if (passwordDataRoot === undefined) {
            const passwordDataContainer = document.getElementById("passwordData");
            passwordDataRoot = createRoot(passwordDataContainer!);
        }
        passwordDataRoot.render(<Show passwordData={passwordData} setStateSelectedID={this.setSelectedID} edit={this.editPasswordData} close={this.passwordDataUnmount} />);
    }

    editPasswordData = async (id: number, data?: IPasswordData_ID) => {
        let passwordData = data;

        if (passwordData === undefined) {
            passwordData = await getPasswordData(id);
        }

        if (passwordDataRoot === undefined) {
            const passwordDataContainer = document.getElementById("passwordData");
            passwordDataRoot = createRoot(passwordDataContainer!);
        }
        passwordDataRoot.render(<Edit reloadList={this.loadList} passwordData={passwordData} show={this.showPasswordData} />);
    }

    startAutoLogOut = () => {
        let autoLogOut: IAutoLogOut | undefined = undefined;

        let autoLogOutSessionStorage = window.sessionStorage.getItem("passwordAutoLogOut");

        if (autoLogOutSessionStorage !== null) {
            autoLogOut = JSON.parse(autoLogOutSessionStorage);
        } else {
            window.sessionStorage.removeItem("passwordManagerKey");
            window.sessionStorage.removeItem("passwordManagerToken");
            window.sessionStorage.removeItem("passwordAutoLogOut");

            this.signIn();
        }

        if (autoLogOut !== undefined) {
            this.loadList();

            if (autoLogOut.active) {
                let endTimestamp = autoLogOut.endTimestamp;

                let interval = setInterval(() => {
                    let timeLeft = new Date(new Date(endTimestamp).getTime() - new Date().getTime());

                    let secondsShow = "0" + timeLeft.getSeconds();
                    let minutesShow = "0" + timeLeft.getMinutes();

                    this.setState({ autoLogOutText: `Automatische Abmeldung: ${minutesShow.slice(-2)}:${secondsShow.slice(-2)}` });

                    if (timeLeft.getTime() <= 0) {
                        window.sessionStorage.removeItem("passwordManagerKey");
                        window.sessionStorage.removeItem("passwordManagerToken");
                        window.sessionStorage.removeItem("passwordAutoLogOut");

                        clearInterval(interval);
                        this.signIn();
                    }
                }, 500);
            } else {
                this.setState({ autoLogOutText: "Automatische Abmeldung: Deaktiviert" });
            }
        } else {
            window.sessionStorage.removeItem("passwordManagerKey");
            window.sessionStorage.removeItem("passwordManagerToken");
            window.sessionStorage.removeItem("passwordAutoLogOut");

            this.signIn();
        }
    }

    signIn = () => {
        document.location = "/passwordManagerSignIn";
    }

    render = () => {
        if (window.sessionStorage.getItem("passwordManagerKey") === null || window.sessionStorage.getItem("passwordManagerToken") === null) {
            this.signIn();
        } else {
            return (
                <>
                    <Navigation />
                    <div id={Styles.main}>
                        <div id={Styles.autoLogOut}>
                            <p>{this.state.autoLogOutText}</p>
                        </div>
                        <h2 id={Styles.headline}><b>Passwort</b>verwaltung</h2>

                        <List id={Styles.passwordManagerList}
                            passwordData={this.state.passwordData}
                            stateSelectedID={this.state.selectedID}
                            setStateSelectedID={this.setSelectedID}
                            addPasswordData={this.addPasswordData}
                            show={this.showPasswordData}
                        />

                        <div id={Styles.passwordData}>
                            <div id={"passwordData"} />
                        </div>
                    </div>
                    <div id={"popUp"} />
                </>
            );
        }
    }
}

export default PasswordManager;