import React from "react";

import Styles from "./IconButton.module.css";

interface IProps {
    text: string
    imgSrc: string
    imgAlt: string
    onClick: Function
}

class IconButton extends React.Component<IProps> {
    render() {
        return (
            <button id={Styles.button} onClick={() => this.props.onClick()}>
                <label id={Styles.icon}>
                    <img src={this.props.imgSrc} alt={this.props.imgAlt} />
                </label>
                <label id={Styles.text}>
                    {this.props.text}
                </label>
            </button>
        );
    }
}

export default IconButton;