import { useState } from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import HeadlineTextField from "../inputFields/HeadlineTextField";
import TextField, { IStateTextField, InitialStateTextField } from "../../modules/inputFields/TextField";
import EmailField from "../inputFields/EmailField";

import { updatePasswordData, IPasswordData_ID, deletePasswordData } from "../../utils/passwordManager";

import Styles from "./Edit.module.css";

import arrowLeft from "../../icons/arrowLeft.svg";
import save from "../../icons/save.svg";
import trashCan from "../../icons/trashCan.svg";
import TextArea, { InitialStateTextArea } from "../inputFields/TextArea";

interface IProps {
    reloadList: Function
    passwordData: IPasswordData_ID
    show: Function
}

function Edit(props: IProps) {
    const [statePlatform, setStatePlatform] = useState<IStateTextField>(InitialStateTextField);
    const [stateName, setStateName] = useState<IStateTextField>(InitialStateTextField);
    const [stateEmail, setStateEmail] = useState<IStateTextField>(InitialStateTextField);
    const [stateUsername, setStateUsername] = useState<IStateTextField>(InitialStateTextField);
    const [statePassword, setStatePassword] = useState<IStateTextField>(InitialStateTextField);
    const [stateNotes, setStateNotes] = useState<IStateTextField>(InitialStateTextArea);

    async function saveData() {
        if (statePlatform.value.length !== 0) {
            let passwordData = {
                id: props.passwordData.id,
                platform: statePlatform.value,
                name: stateName.value,
                email: stateEmail.value,
                username: stateUsername.value,
                password: statePassword.value,
                notes: stateNotes.value
            }

            let updatedPasswordData = await updatePasswordData(passwordData);
            if (updatedPasswordData !== undefined) {
                props.show(updatedPasswordData.id, updatedPasswordData);
                props.reloadList();
            }
        }
    }

    async function deleteData() {
        let popUpRoot: ReactDOM.Root | undefined;

        const popUpContainer = document.getElementById("popUp");
        if (popUpRoot === undefined) {
            popUpRoot = createRoot(popUpContainer!);
            popUpRoot.render(
                <div id={Styles.popUpBackground}>
                    <div id={Styles.deletePopUp} className={Styles.popUp}>
                        <h3>Löschen?</h3>
                        <hr />
                        <p>Willst du die Plattform <span>{props.passwordData.platform}</span> unwiderruflich löschen?</p>
                        <button onClick={cancel}>Abbrechen</button>
                        <button onClick={remove}>Löschen</button>
                    </div>
                </div>
            );
        }

        function cancel() {
            if (popUpRoot !== undefined) {
                popUpRoot.unmount();
                popUpRoot = undefined;
            }
        }

        function remove() {
            if (popUpRoot !== undefined) {
                popUpRoot.unmount();
                popUpRoot = undefined;
            }

            deletePasswordData(props.passwordData.id).then(() => {
                props.reloadList();
                props.show(-1);
            });
        }
    }

    return (
        <div id={Styles.passwordDataBackground}>
            <div id={Styles.passwordData}>
                <div id={Styles.navigationIcons}>
                    <img id={Styles.arrowLeftIcon} title={"Abbrechen"} onClick={() => props.show(props.passwordData.id, props.passwordData)} src={arrowLeft} alt={"arrowLeft"} />
                    <img id={Styles.deleteIcon} title={"Löschen"} onClick={deleteData} src={trashCan} alt={"trashCan"} />
                    <img id={Styles.saveIcon} title={"Speichen"} onClick={saveData} src={save} alt={"save"} />
                </div>
                <hr />
                <div id={Styles.headline}>
                    <HeadlineTextField state={statePlatform} setState={setStatePlatform} value={props.passwordData.platform} placeholder={"Beispiel: Google Konto"} required={true} tooltipRequired={true} tooltip={"Dies ist ein Pflichtfeld!"} />
                </div>
                <div id={Styles.data}>
                    <h6>Name</h6>
                    <TextField state={stateName} setState={setStateName} value={props.passwordData.name} />

                    <h6>E-Mail</h6>
                    <EmailField state={stateEmail} setState={setStateEmail} value={props.passwordData.email} />

                    <h6>Benutzername</h6>
                    <TextField state={stateUsername} setState={setStateUsername} value={props.passwordData.username} />

                    <h6>Passwort</h6>
                    <TextField state={statePassword} setState={setStatePassword} value={props.passwordData.password} />

                    <h6>Notizen</h6>
                    <TextArea state={stateNotes} setState={setStateNotes} value={props.passwordData.notes} maxLength={127} />
                </div>
            </div>
        </div>
    );
}

export default Edit;