import { useState } from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import HeadlineTextField, { IStateTextField, InitialStateTextField } from "../inputFields/HeadlineTextField";

import { IBirthdayData_ID, calculateAge, deleteBirthdayData, updateBirthdayData } from "../../utils/birthdayManager";

import Styles from "./Edit.module.css";

import arrowLeft from "../../icons/arrowLeft.svg";
import save from "../../icons/save.svg";
import trashCan from "../../icons/trashCan.svg";
import DateField, { IStateDateField, InitialStateDateField } from "../inputFields/DateField";

interface IProps {
    reload: Function
    birthdayData: IBirthdayData_ID
    show: Function
}

function Edit(props: IProps) {
    const [stateName, setStateName] = useState<IStateTextField>(InitialStateTextField);
    const [stateDate, setStateDate] = useState<IStateDateField>(InitialStateDateField);
    const [stateAge, setStateAge] = useState<any>(calculateAge(props.birthdayData.date.getDate(), props.birthdayData.date.getMonth() + 1, props.birthdayData.date.getFullYear()));

    async function saveData() {
        if (stateName.value.length !== 0) {
            let birthdayData = {
                id: props.birthdayData.id,
                name: stateName.value,
                date: new Date(stateDate.year.value, stateDate.month.value, stateDate.day.value)
            }

            let updatedBirthdayData = await updateBirthdayData(birthdayData);
            if (updatedBirthdayData !== undefined) {
                props.show(updatedBirthdayData.id, updatedBirthdayData);
                props.reload();
            }
        }
    }

    async function deleteData() {
        let popUpRoot: ReactDOM.Root | undefined;

        const popUpContainer = document.getElementById("popUp");
        if (popUpRoot === undefined) {
            popUpRoot = createRoot(popUpContainer!);
            popUpRoot.render(
                <div id={Styles.popUpBackground}>
                    <div id={Styles.deletePopUp} className={Styles.popUp}>
                        <h3>Löschen?</h3>
                        <hr />
                        <p>Willst du den Geburtstag von <span>{props.birthdayData.name}</span> unwiderruflich löschen?</p>
                        <button onClick={cancel}>Abbrechen</button>
                        <button onClick={remove}>Löschen</button>
                    </div>
                </div>
            );
        }

        function cancel() {
            if (popUpRoot !== undefined) {
                popUpRoot.unmount();
                popUpRoot = undefined;
            }
        }

        function remove() {
            if (popUpRoot !== undefined) {
                popUpRoot.unmount();
                popUpRoot = undefined;
            }

            deleteBirthdayData(props.birthdayData.id).then(() => {
                props.reload();
                props.show(-1);
            });
        }
    }

    function changeAge(date: IStateDateField) {
        let day = date.day.value;
        let month = date.month.value;
        let year = date.year.value;

        setStateAge(calculateAge(day, month, year));
    }

    return (
        <div id={Styles.birthdayData}>
            <div id={Styles.navigationIcons}>
                <img id={Styles.arrowLeftIcon} title={"Abbrechen"} onClick={() => props.show(props.birthdayData.id, props.birthdayData)} src={arrowLeft} alt={"arrowLeft"} />
                <img id={Styles.deleteIcon} title={"Löschen"} onClick={deleteData} src={trashCan} alt={"trashCan"} />
                <img id={Styles.saveIcon} title={"Speichen"} onClick={saveData} src={save} alt={"save"} />
            </div>
            <hr />
            <div id={Styles.headline}>
                <HeadlineTextField state={stateName} setState={setStateName} value={props.birthdayData.name} placeholder={"Beispiel: Max Mustermann"} required={true} tooltipRequired={true} tooltip={"Dies ist ein Pflichtfeld!"} />
            </div>
            <div id={Styles.data}>
                <h6>Geburtsdatum</h6>
                <DateField state={stateDate} setState={setStateDate} onChange={changeAge} day={props.birthdayData.date.getDate()} month={props.birthdayData.date.getMonth() + 1} year={props.birthdayData.date.getFullYear()} />

                <h6>Alter</h6>
                <p>{stateAge}</p>
            </div>
        </div>
    );
}

export default Edit;