import React from "react";
import Navigation from "../modules/Navigation"

import Styles from "../css/Settings.module.css";
import QuickSettings from "../modules/settings/QuickSettings";
import { IUserData } from "../utils/userManager";
import CurrentUserData from "../modules/settings/CurrentUserData";
import ChangeName from "../modules/settings/ChangeName";
import ChangeEmail from "../modules/settings/ChangeEmail";
import ChangeUsername from "../modules/settings/ChangeUsername";
import ChangePassword from "../modules/settings/ChangePassword";
import ChangePasswordManagerKey from "../modules/settings/ChangePasswordManagerKey";

interface IState {
    userData: IUserData
}

class Settings extends React.Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        document.title = "Einstellungen";

        let token = window.sessionStorage.getItem("token");

        if (token === null) {
            window.sessionStorage.clear();
            document.location.href = "/error";
        }

        this.state = {
            userData: JSON.parse(window.sessionStorage.getItem("userData") || "")
        }
    }

    reloadCurrentData = (userData: IUserData) => {
        this.setState({ userData: userData });
        window.sessionStorage.setItem("userData", JSON.stringify(userData));
    }

    render() {
        return (
            <>
                <Navigation />
                <div id={Styles.main}>
                    <h2 id={Styles.headline}>Einstellungen</h2>

                    <div id={Styles.settings}>
                        <CurrentUserData stateUserData={this.state.userData} />
                        <QuickSettings stateUserData={this.state.userData} reload={this.reloadCurrentData} />
                        <ChangeName reload={this.reloadCurrentData} />
                        <ChangeEmail reload={this.reloadCurrentData} />
                        <ChangeUsername reload={this.reloadCurrentData} />
                        <ChangePassword />
                        <ChangePasswordManagerKey />
                    </div>
                </div>
                <div id={"popUp"} />
            </>
        );
    }
}

export default Settings;