import { createEmailVerifyToken } from "../../utils/token";
import { getUser } from "../../utils/userManager";
import refresh from "../../icons/refresh.svg";

import Styles from "./EmailNotVerifiedBanner.module.css";

import React from "react";

interface IProps {
    userID: number
    emailVerified: boolean
    reload: Function
}

interface IState {
    emailSendText: string
}

class EmailNotVerifiedBanner extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            emailSendText: ""
        }
    }

    refresh = async () => {
        let refreshIcon = document.getElementById(Styles.refresh);

        if (refreshIcon !== null) {
            refreshIcon.classList.toggle(Styles.load);
            setTimeout(() => {
                if (refreshIcon !== null) {
                    refreshIcon.classList.toggle(Styles.load);
                }
            }, 1000);
        }

        let userData = await getUser();
        if (userData === undefined) {
            return;
        }

        window.sessionStorage.setItem("userData", JSON.stringify(userData));
        this.props.reload(userData.emailVerified);
    }

    sendMail = async () => {
        let sendMailResponse = await createEmailVerifyToken({
            userID: this.props.userID,
            requestTimestamp: new Date().getTime()
        });

        if (sendMailResponse !== undefined) {
            if (sendMailResponse === "SUCCESS") {
                this.setState({ emailSendText: "E-Mail wurde versendet!" });
            } else if (sendMailResponse === "USER_NOT_EXIST") {
                this.setState({ emailSendText: "Fehler beim Versenden der E-Mail!" });
            }
        } else {
            this.setState({ emailSendText: "Fehler beim Versenden der E-Mail!" });
        }
    }

    render = () => {
        return (
            <div id={Styles.emailNotVerifiedBanner}>
                <div id={Styles.headline}>
                    <h4>Ihre E-Mail Adresse ist nicht verifiziert!</h4>
                    <div id={Styles.refresh} onClick={this.refresh}>
                        <img src={refresh} alt={"refresh"} />
                    </div>
                </div>
                <p>Bitte verifizieren sie ihre E-Mail Adresse.</p>

                <div id={Styles.footer}>
                    <p>{this.state.emailSendText}</p>
                    <button onClick={this.sendMail}>E-Mail Adresse verifizieren</button>
                </div>
            </div>
        );
    }
}

export default EmailNotVerifiedBanner;