import { useState } from "react";
import ReactDOM, { createRoot } from 'react-dom/client';
import CryptoJS from "crypto-js";
import PasswordField, { IStatePasswordField, InitialStatePasswordField } from "../modules/inputFields/PasswordField";
import CheckBox, { IStateCheckBox, InitialStateCheckBox } from "../modules/inputFields/CheckBox";

import Styles from "../css/PasswordManagerSignIn.module.css";
import Navigation from "../modules/Navigation";
import { IAutoLogOut } from "./PasswordManager";
import { IUserData } from "../utils/userManager";

interface TokenAPIResponse {
    status: number
    message: string
    passwordManagerToken: string
}

function SignIn() {
    const API_URL = process.env.REACT_APP_API_URL;
    const AES_KEY = process.env.REACT_APP_AES_KEY || "";
    const API_TOKEN = window.sessionStorage.getItem("token");

    const userData: IUserData = JSON.parse(window.sessionStorage.getItem("userData") || "");

    const [statePasswordManagerKey, setStatePasswordManagerKey] = useState<IStatePasswordField>(InitialStatePasswordField);
    const [stateAutoLogOut, setStateAutoLogOut] = useState<IStateCheckBox>(InitialStateCheckBox);

    let errorBoxRoot: ReactDOM.Root;

    function onKeyUp(event: any) {
        if (event.keyCode === 13) {
            event.preventDefault()
            signIn()
        }
    }

    async function signIn() {
        let passwordManagerKey = statePasswordManagerKey.value;

        const errorBoxContainer = document.getElementById("errorBox");
        if (errorBoxRoot === undefined) {
            errorBoxRoot = createRoot(errorBoxContainer!);
        }

        if (passwordManagerKey.length !== 0) {
            const tokenRequestOptions: RequestInit = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${API_TOKEN}`
                },
                body: JSON.stringify({
                    passwordManagerKey: passwordManagerKey
                }),
                credentials: 'include'
            }

            let tokenResponse: TokenAPIResponse = await fetch(`${API_URL}user/getPasswordManagerToken.php`, tokenRequestOptions).then(response => response.json());

            if (tokenResponse.status === 200) {
                let autoLogOut: IAutoLogOut | undefined = undefined;

                if (!stateAutoLogOut.checked) {
                    autoLogOut = {
                        active: false,
                        endTimestamp: 0
                    };

                    window.sessionStorage.setItem("passwordAutoLogOut", JSON.stringify(autoLogOut));
                } else {
                    autoLogOut = {
                        active: true,
                        endTimestamp: new Date(new Date().getTime() + 120000).getTime()
                    };

                    window.sessionStorage.setItem("passwordAutoLogOut", JSON.stringify(autoLogOut));
                }

                window.sessionStorage.setItem("passwordAutoLogOut", JSON.stringify(autoLogOut));
                window.sessionStorage.setItem("passwordManagerToken", tokenResponse.passwordManagerToken);
                window.sessionStorage.setItem("passwordManagerKey", CryptoJS.AES.encrypt(passwordManagerKey, AES_KEY).toString());

                document.location = "/passwordManager";
            } else if (tokenResponse.status === 401) {
                errorBoxRoot.render(
                    <div id={Styles.errorMessageBox}>
                        <p key="errorMessage">Schlüssel falsch!</p>
                    </div>
                );
            }
        } else {
            errorBoxRoot.render(
                <div id={Styles.errorMessageBox}>
                    <p key="errorMessage">Es muss ein Schlüssel eingegeben werden!</p>
                </div>
            );
        }
    }

    return (
        <>
            <Navigation />
            <div id={Styles.signIn}>
                <h2 id={Styles.headlineLogin}><b>Passwort</b>verwaltung</h2>
                <div id={Styles.input}>
                    <div id="errorBox" />
                    <h6>Schlüssel</h6>
                    <PasswordField onKeyUp={onKeyUp} state={statePasswordManagerKey} setState={setStatePasswordManagerKey} />
                    <div id={Styles.checkboxContainer}>
                        <CheckBox checked={userData.autoLogOut} state={stateAutoLogOut} setState={setStateAutoLogOut} labelText={"Automatische Abmeldung"} />
                    </div>
                    <button onClick={signIn}>Entsperren</button>
                </div>
            </div>
        </>
    );
}

export default SignIn